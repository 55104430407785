import React, { useEffect, useState } from 'react'
import { Dialog } from '@headlessui/react'
import Switch from 'components/atoms/Switch'
import Button from 'components/atoms/Button'
import { find, some, split } from 'lodash'
import { IoClose } from 'react-icons/io5'

const NormalModeDialog = ({ isOpen, onClose, onSave, timings, onTimeChange, values, setFieldValue }) => {
  const handleSave = () => {
    const timetable = Object.entries(timings).map(([day, value]) => ({
      day,
      active: value.enabled,
      startTime: value.startTime + ':00',
      endTime: value.endTime + ':00',
    }))

    const existingNormalMode = find(values?.timings, { mode: 'normal' }) || {}

    onSave([
      {
        mode: 'normal',
        isActive: existingNormalMode.isActive ?? false,
        timetable: timetable,
      },
    ])

    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="bg-white rounded-lg p-6 w-full max-w-xl">
          <div className="flex justify-between items-center mb-6">
            <Dialog.Title className="text-xl font-semibold">TIMINGS</Dialog.Title>
            <button onClick={onClose} className="p-1">
              <IoClose />
            </button>
          </div>

          <div className="mb-6">
            <div className="flex items-center justify-between">
              <h3 className="text-lg mb-2">Normal Working Mode</h3>
              <Switch
                value={some(values.timings, { mode: 'normal', isActive: true })}
                onChange={e => {
                  setFieldValue('timings', [...values.timings.filter(t => t.mode !== 'normal'), { mode: 'normal', isActive: e }])
                }}
              />
            </div>
            <p className="text-gray-600 text-sm mb-4">The office works on official working hours</p>
          </div>

          <div className="space-y-4">
            {Object.entries(timings).map(([day, value]) => (
              <div key={day} className="flex items-center justify-between">
                <span className="text-gray-700">{day}</span>
                <div className="flex items-center gap-4">
                  <Switch value={value.enabled} onChange={checked => onTimeChange(day, 'enabled', checked)} />

                  <input
                    type="time"
                    value={split(value.startTime, ' ')[0]}
                    onChange={e => onTimeChange(day, 'startTime', e.target.value)}
                    className="border border-gray-300 rounded px-2 py-1 text-sm"
                    disabled={!value.enabled}
                  />
                  <span>-</span>
                  <input
                    type="time"
                    value={split(value.endTime, ' ')[0]}
                    onChange={e => onTimeChange(day, 'endTime', e.target.value)}
                    className="border border-gray-300 rounded px-2 py-1 text-sm"
                    disabled={!value.enabled}
                  />
                </div>
              </div>
            ))}
          </div>

          <div className="mt-8 flex justify-end gap-3">
            <Button onClick={onClose} extraClassName="bg-gray-500 hover:bg-gray-600">
              Cancel
            </Button>
            <Button onClick={handleSave}>Save Changes</Button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

export default NormalModeDialog
