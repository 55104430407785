import { useState } from 'react'
import { IoFileTray } from 'react-icons/io5'
import { size, map, isArray } from 'lodash'
import { DownloadIcon } from '@heroicons/react/outline'

const CsvDropzone = ({ onDrop, setFile, setErrorMessages, errorMessages }) => {
  const [fileName, setFileName] = useState(null)

  const handleDragOver = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDragLeave = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = e => {
    e.preventDefault()
    e.stopPropagation()
    handleFileSelect(e.dataTransfer.files[0])
  }

  const handleSelect = e => {
    handleFileSelect(e.target.files[0])
  }

  const handleFileSelect = file => {
    if (file.type !== 'application/zip' && file.type !== 'application/x-zip-compressed') {
      setErrorMessages({ File: [{ key: 'File', message: `Only application/zip fil are supported. Your file type is ${file.type}` }] })
      return null
    } else {
      setErrorMessages({})
      onDrop(file)
      setFileName(file.name)
    }
  }

  const handleRemove = () => {
    setFileName(null)
    onDrop(null)
    setFile(null)
    setErrorMessages({})
  }

  return (
    <>
      <div className="flex justify-end">
        <a
          href="/sample.zip"
          className="mb-3 flex-nowrap whitespace-nowrap items-center flex justify-end px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white hover:text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
        >
          <DownloadIcon className="h-5 w-5 mr-3" aria-hidden="true" />
          Download Sample Zip
        </a>
      </div>
      <div
        className={`border-2 border-dashed border-gray-400 rounded-lg p-6 flex flex-col justify-center items-center `}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {!fileName && (
          <div className={`text-gray-400 flex items-center flex-col justify-center`}>
            <IoFileTray className="h-20 w-20 text-gray-400" />
            <p className="text-sm font-medium text-gray-900">Drag and drop a Zip file here</p>
            <p className="text-xs text-gray-500">or</p>
            <label htmlFor="fileInput" className="bg-white py-2 px-4 border border-gray-400 rounded-lg font-medium text-sm text-gray-700 cursor-pointer hover:bg-gray-100">
              Select a file
            </label>
            <input id="fileInput" type="file" accept=".zip" className="sr-only" onChange={handleSelect} />
          </div>
        )}
        {fileName && (
          <div className="flex flex-col items-center">
            <div className="relative">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="96" height="96" viewBox="0 0 48 48">
                <path fill="#169154" d="M29,6H15.744C14.781,6,14,6.781,14,7.744v7.259h15V6z"></path>
                <path fill="#18482a" d="M14,33.054v7.202C14,41.219,14.781,42,15.743,42H29v-8.946H14z"></path>
                <path fill="#0c8045" d="M14 15.003H29V24.005000000000003H14z"></path>
                <path fill="#17472a" d="M14 24.005H29V33.055H14z"></path>
                <g>
                  <path fill="#29c27f" d="M42.256,6H29v9.003h15V7.744C44,6.781,43.219,6,42.256,6z"></path>
                  <path fill="#27663f" d="M29,33.054V42h13.257C43.219,42,44,41.219,44,40.257v-7.202H29z"></path>
                  <path fill="#19ac65" d="M29 15.003H44V24.005000000000003H29z"></path>
                  <path fill="#129652" d="M29 24.005H44V33.055H29z"></path>
                </g>
                <path
                  fill="#0c7238"
                  d="M22.319,34H5.681C4.753,34,4,33.247,4,32.319V15.681C4,14.753,4.753,14,5.681,14h16.638 C23.247,14,24,14.753,24,15.681v16.638C24,33.247,23.247,34,22.319,34z"
                ></path>
                <path fill="#fff" d="M9.807 19L12.193 19 14.129 22.754 16.175 19 18.404 19 15.333 24 18.474 29 16.123 29 14.013 25.07 11.912 29 9.526 29 12.719 23.982z"></path>
              </svg>
              <button
                className="absolute top-0 right-0 flex items-center justify-center w-6 h-6 rounded-full bg-red-500 text-white hover:bg-red-600 focus:outline-none"
                onClick={handleRemove}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    d="M11.414 10l4.293-4.293a1 1 0 0 0-1.414-1.414L10 8.586 5.707 4.293a1 1 0 0 0-1.414 1.414L8.586 10l-4.293 4.293a1 1 0 1 0 1.414 1.414L10 11.414l4.293 4.293a1 1 0 0 0 1.414-1.414L11.414 10z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <p className="text-sm font-medium text-gray-900 truncate">{fileName}</p>
          </div>
        )}
      </div>

      {map(errorMessages, (value, key) => {
        return (
          <>
            {size(value) > 0 && isArray(value) && (
              <p className="text-xs text-red-600 flex flex-col mt-1 overflow-hidden">
                <ol key={key} className="text-wrap mb-3" type="1">
                  <li>* {key}:</li>
                  {value?.map((item, index) => {
                    return (
                      <>
                        <li>
                          <ol key={index} className="ml-5">
                            <li>
                              {index + 1} - {item?.message}
                            </li>
                          </ol>
                        </li>
                      </>
                    )
                  })}
                </ol>
              </p>
            )}
          </>
        )
      })}
    </>
  )
}

export default CsvDropzone
