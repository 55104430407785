import { useState, useEffect, useRef } from 'react'
import _ from 'lodash'
import apiGet from 'lib/network/apiGet'
import moment from 'moment'
import { Field } from 'formik'
import Input from 'components/atoms/Input'

export const DashboardCards = ({ branchData }) => {
  const [carStats, setCarStats] = useState({
    total: 0,
    active: 0,
    booked: 0,
    outOfService: 0,
    inProgress: 0,
    availableCars: 0,
    bookedCars: 0,
  })
  const [loading, setLoading] = useState(true)
  const [dateRange, setDateRange] = useState({ endDate: null })

  useEffect(() => {
    fetchCarStats()
  }, [dateRange, branchData])

  const fetchCarStats = async () => {
    try {
      setLoading(true)

      const params = {}

      if (branchData?._id) {
        params.branchId = branchData._id
      }

      if (dateRange.endDate) {
        params.dateParam = dateRange.endDate.format('YYYY-MM-DD')
      }

      const response = await apiGet('stats', params)

      if (response?.success && response?.data?.rentalCars) {
        setCarStats(response.data.rentalCars)
      }
    } catch (error) {
      console.error('Error fetching car statistics:', error)
    } finally {
      setLoading(false)
    }
  }

  const DashboardCard = ({ title, value, subtitle }) => (
    <div className="bg-white rounded-lg shadow p-4 border border-gray-200 mt-4">
      <div className="flex flex-col">
        <h3 className="text-xs font-medium text-gray-500">{title}</h3>
        <p className="mt-1 text-xl font-semibold text-gray-900">{loading ? '...' : _.isNumber(value) ? value.toLocaleString() : value}</p>
        {subtitle && <p className="mt-1 text-xs text-gray-600">{subtitle}</p>}
      </div>
    </div>
  )

  const clearDateFilter = () => {
    setDateRange({ endDate: null })
  }

  return (
    <>
      <div className="ml-4 mt-3 mb-4 w-1/3">
        <Field
          type="date"
          name="rentalEndDate"
          as={Input}
          id="rentalEndDate"
          className="w-1/3"
          onChange={e => {
            if (e.target.value) {
              setDateRange({
                endDate: moment(e.target.value),
              })
            } else {
              clearDateFilter()
            }
          }}
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        <DashboardCard title="Total Rental Cars" value={carStats.total} />
        <DashboardCard title="Active Cars" value={carStats.active} />
        <DashboardCard title="Booked Cars" value={carStats.booked} />
        <DashboardCard title="Out of Service" value={carStats.outOfService} />
        <DashboardCard title="In Progress" value={carStats.inProgress} />
        <DashboardCard title="Available Cars" value={carStats.availableCars} />
        <DashboardCard title="Booked Cars with Date" value={carStats.bookedCars} />
      </div>
    </>
  )
}
