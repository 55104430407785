import { useEffect, useState } from 'react'
import notify from 'components/atoms/Notify'
import apiGet from 'lib/network/apiGet'
import PageFooter from 'components/paginationComponent'
import LoadingIcon from 'components/atoms/LoadingIcon'
import EmptyState from 'components/pageComponents/EmptyState'
import InfiniteScroll from 'react-infinite-scroll-component'
import { IoSearch } from 'react-icons/io5'
import _ from 'lodash'

const AddRentalCar = ({ bookingData, onSelectCar, onClose, loadingCarId }) => {
  const [carData, setCarData] = useState([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPage: 1,
    pageSize: 10,
  })

  const debouncedSearch = _.debounce((searchTerm, page) => {
    fetchCars(page, searchTerm)
  }, 300)
  const fetchCars = async (page = 1, searchTerm = '') => {
    setLoading(true)
    try {
      const params = {
        'booking[from]': bookingData.rentalStartDate,
        'booking[to]': bookingData.rentalEndDate,
        'location[lat]': bookingData.lat,
        'location[lon]': bookingData.lon,
        pageNumber: page,
        pageSize: 9,
      }

      if (_.trim(searchTerm)) {
        params.query = _.trim(searchTerm)
      }

      const queryString = new URLSearchParams(params).toString()
      const response = await apiGet(`cars/for-bookings?${queryString}`)

      if (response.success || response.code === 200) {
        const { rentalCars, pagination: newPagination } = response.data

        setCarData(prevData => {
          return page === 1 ? rentalCars : [...prevData, ...rentalCars]
        })

        setPagination({
          currentPage: page,
          totalPage: newPagination.totalPage,
          pageSize: newPagination.pageSize,
        })
      }
    } catch (error) {
      notify.error('Failed to fetch cars')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setCarData([])
    debouncedSearch(search, 1)

    return () => debouncedSearch.cancel()
  }, [search])

  useEffect(() => {
    fetchCars(1)
  }, [])

  const loadMore = () => {
    if (pagination.currentPage < pagination.totalPage) {
      fetchCars(pagination.currentPage + 1, search)
    }
  }

  const handleRentClick = carId => {
    if (carId) {
      onSelectCar(carId)
    }
  }

  const handleSearchChange = e => {
    const value = e.target.value
    setSearch(value)
  }

  return (
    <div
      className="flex flex-col h-full"
      id="scrollableDiv"
      style={{
        overflow: 'auto',
        height: 'calc(100vh - 200px)',
      }}
    >
      <div className="flex justify-end items-center mb-4 px-4">
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <IoSearch className="mr-3 h-4 text-gray-400" aria-hidden="true" />
          </div>
          <input
            onChange={handleSearchChange}
            value={search}
            type="text"
            name="search"
            id="search"
            className="block pl-9 shadow-none sm:text-sm bg-gray-100 border-0 focus:outline-none rounded-sm"
            placeholder="Search Options"
          />
        </div>
      </div>

      <div className="flex-grow px-4">
        {loading && carData.length === 0 ? (
          <div className="w-full h-[200px] border-0 flex justify-center items-center">
            <LoadingIcon />
          </div>
        ) : carData.length === 0 ? (
          <EmptyState v2 description="No cars available" />
        ) : (
          <InfiniteScroll
            dataLength={carData.length}
            next={loadMore}
            hasMore={pagination.currentPage < pagination.totalPage}
            loader={
              <div className="w-full h-16 flex justify-center items-center">
                <LoadingIcon />
              </div>
            }
            scrollThreshold={0.8}
            scrollableTarget="scrollableDiv"
            className="w-full"
          >
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {carData.map(rentalCarData => (
                <div key={rentalCarData._id} className="bg-white rounded-lg shadow-md overflow-hidden p-4 relative">
                  <div className="relative">
                    <img src={rentalCarData.carImages[0].url} alt={'RentalCar'} className="w-full h-40 object-cover rounded-md mb-3" />
                    <img
                      src={rentalCarData.company.logoUrl}
                      alt="Company Logo"
                      className="absolute top-2 right-2 w-16 h-16 object-cover rounded-md border border-white shadow-md"
                    />
                  </div>
                  <h3 className="text-lg font-semibold text-gray-900">
                    {rentalCarData.car.make} {rentalCarData.car.model} ({rentalCarData.modelYear})
                  </h3>
                  <p className="text-sm text-gray-500">
                    {rentalCarData.car.marketSegment} | {rentalCarData.car.numberOfSeats} |{rentalCarData.car.shifter || ''} | {rentalCarData.allowedKMPerDay} km/day
                  </p>
                  <p className="text-sm text-gray-500">Distance: {rentalCarData.distance}</p>
                  <p className="text-lg font-bold text-black-600 mt-2">{rentalCarData.dailyRate} OMR/day</p>
                  <button
                    onClick={() => handleRentClick(rentalCarData._id)}
                    disabled={loadingCarId === rentalCarData._id}
                    className="w-full bg-dark-600 text-white py-2 px-4 rounded-lg hover:bg-dark-700 transition-colors mt-3"
                  >
                    {loadingCarId === rentalCarData._id ? 'Processing...' : 'Add this car'}
                  </button>
                </div>
              ))}
            </div>
          </InfiniteScroll>
        )}
      </div>
    </div>
  )
}

export default AddRentalCar
