import { useEffect, useState, useRef } from 'react'
import Layout from 'containers/Layout'
import moment from 'moment'
import { forEach, get, startCase } from 'lodash'
import apiGet from 'lib/network/apiGet'
import Table from 'components/pageComponents/Table'
import LoadingIcon from 'components/atoms/LoadingIcon'
import EmptyState from 'components/pageComponents/EmptyState'
import Button from 'components/atoms/Button'
import Popover from 'components/molecules/Popover'
import { formateDate } from 'utils'
import notify from 'components/atoms/Notify'
import { IoSearch, IoCalendar } from 'react-icons/io5'

import useModal from 'hooks/useModal'

import apiDelete from 'lib/network/apiDelete'
import { DateRangePicker } from 'react-date-range'
import { Tooltip } from 'antd'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import apiPost from 'lib/network/apiPost'
import useParams from 'hooks/useGetParams'
import { CloudUploadIcon } from '@heroicons/react/outline'
import PageFooter from 'components/paginationComponent'

const Imports = () => {
  const [filter, setFilter] = useState({ workspace: -1 })
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null })

  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({})
  const [importManagment, setImportManagment] = useState([])

  const [uploading, setUploading] = useState(false)
  const [search, setSearch] = useState('')

  const popover = useRef()

  useEffect(() => {
    fetchImportManagment()
  }, [search, dateRange])

  const fetchImportManagment = async (page = 1) => {
    if (page === 1) {
      setLoading(true)
    }

    const params = {}
    params.pageNumber = page
    params.pageSize = 20
    params.query = search || undefined
    params.startDate = dateRange.startDate ? dateRange.startDate.format('MM-DD-YYYY') : undefined
    params.endDate = dateRange.endDate ? dateRange.endDate.format('MM-DD-YYYY') : undefined

    const response = await apiGet('imports', params)
    setImportManagment(page === 1 ? response.data.importManagment : [...importManagment, ...response.data.importManagment])
    setPagination(response?.data?.pagination || [])

    setLoading(false)
  }

  const handleSelect = range => {
    setDateRange({
      startDate: moment(range.selection.startDate),
      endDate: moment(range.selection.endDate),
    })
  }
  const id = useParams()?.id

  const ReImport = async id => {
    try {
      setUploading(prev => ({ ...prev, [id]: true }))

      const response = await apiPost(`re-import-cars/${id}`)
      if (response.success) {
        notify.success('Import Users', 'CSV Imported successfully')
        fetchImportManagment()
      } else {
        try {
          const errors = JSON.parse(response.err)
        } catch (error) {}
      }
    } finally {
      setUploading(prev => ({ ...prev, [id]: false }))
    }
  }

  const columns = [
    {
      name: 'File Name',
      width: '10%',
      key: 'name',
      sortable: true,
      rendered: item => {
        return (
          <div>
            <a href={item?.uploadFile?.url} className="text-sm text-normal text-blue-400">
              {item?.uploadFile?.fileName}
            </a>
          </div>
        )
      },
    },

    {
      width: '10%',
      name: 'File size',
      key: 'type',
      sortable: true,
      rendered: item => {
        const fileSizeInKB = (item?.uploadFile.fileSize / 1000).toFixed(2)
        return (
          <>
            <div>
              <span className="text-[13px]">{fileSizeInKB} KB</span>
            </div>
          </>
        )
      },
    },
    {
      width: '10%',
      name: 'File Type',
      key: 'type',
      sortable: true,
      rendered: item => {
        return (
          <>
            <div>
              <span className="text-[13px]">{item?.uploadFile.fileType}</span>
            </div>
          </>
        )
      },
    },

    {
      width: '10%',
      name: 'Initiated By',
      key: 'type',
      sortable: true,
      rendered: item => {
        return (
          <>
            <div>
              <span className="text-[13px]">{item.createdBy.fullName}</span>
            </div>
          </>
        )
      },
    },
    {
      width: '10%',
      name: 'Re Initiated By',
      key: 'type',
      sortable: true,
      rendered: item => {
        return (
          <>
            <div>
              <span className="text-[13px]">{item.createdBy.fullName}</span>
            </div>
          </>
        )
      },
    },
    {
      width: '10%',
      name: 'Company',
      key: 'type',
      sortable: true,
      rendered: item => {
        return (
          <>
            <div>
              <span className="text-[13px]">{item?.company?.name}</span>
            </div>
          </>
        )
      },
    },
    {
      width: '10%',
      name: 'Branch',
      key: 'type',
      sortable: true,
      rendered: item => {
        return (
          <>
            <div>
              <span className="text-[13px]">{item?.branch?.name}</span>
            </div>
          </>
        )
      },
    },
    {
      name: 'Errors',
      width: '10%',
      key: 'type',
      sortable: false,
      rendered: item => (
        <div className="text-xs text-blue-500 font-mono">
          {item?.error && item?.error.length > 0 ? (
            <Tooltip
              color="white"
              overlayClassName="!min-w-[400px] !w-full"
              title={
                <div className="flex flex-col gap-2 p-2">
                  <div className="flex border rounded p-2 flex-col text-[12px] text-gray-500">
                    <b className="mb-1.5 border-b">Errors Details:</b>
                    {item?.error.map((error, index) => (
                      <div key={index} className="text-xs font-mono mb-2">
                        <div>
                          <b>Error {index + 1}:</b>
                        </div>
                        <pre className="bg-gray-100 p-2 rounded">{JSON.stringify(error, null, 2)}</pre>
                      </div>
                    ))}
                  </div>
                </div>
              }
            >
              <a target="_blank" rel="noreferrer">
                View errors
              </a>
            </Tooltip>
          ) : (
            <span>No errors</span>
          )}
        </div>
      ),
    },
    {
      name: 'Status',
      width: '10%',
      key: 'status',
      sortable: false,
      rendered: item => (
        <span
          className={`text-xs font-regular ${
            item.status === 'success'
              ? 'bg-green-100 text-green-700'
              : item.status === 'failure'
              ? 'bg-red-100 text-red-700'
              : item.status === 'inProgress'
              ? 'bg-yellow-100 text-yellow-800'
              : 'bg-gray-200'
          } px-2 py-1 rounded-md`}
        >
          {startCase(item.status)}
        </span>
      ),
    },

    {
      width: '10%',
      name: 'process Records',
      key: 'processNumberOfRecords',
      sortable: true,
      rendered: item => {
        return (
          <>
            <div>
              <span className="text-[13px]">{item.processNumberOfRecords}</span>
            </div>
          </>
        )
      },
    },

    {
      width: '5%',
      name: 'Created',
      key: 'created',
      sortable: true,
      rendered: item => {
        const createdTime = get(item, 'createdAt', '')
        const createdAt = createdTime ? moment(createdTime).fromNow() : 'Registered'

        return (
          <div className="text-sm text-gray-500">
            <Tooltip title={createdTime ? formateDate(moment(createdTime)) : 'N/A'}>{createdAt}</Tooltip>
          </div>
        )
      },
    },

    {
      width: '5%',
      name: 'Updated',
      key: 'updated',
      sortable: true,
      rendered: item => {
        const updatedTime = get(item, 'updatedAt', '')
        const updatedAt = updatedTime ? moment(updatedTime).fromNow() : 'Registered'

        return (
          <div className="text-sm text-gray-500">
            <Tooltip title={updatedTime ? formateDate(moment(updatedTime)) : 'N/A'}>{updatedAt}</Tooltip>
          </div>
        )
      },
    },

    {
      width: '5%',
      name: 'Actions',
      key: 'actions',
      rendered: item => (
        <div className="flex">
          {item.status === 'failure' && (
            <button
              onClick={() => ReImport(item._id)}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
            >
              {uploading[item._id] ? (
                <svg className="animate-spin h-5 w-5 mr-3 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path
                    className="opacity-75"
                    fill="white"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <CloudUploadIcon className="h-5 w-5 mr-3" aria-hidden="true" />
              )}
              Re-Import
            </button>
          )}
        </div>
      ),
    },
  ]

  return (
    <Layout
      headerRight={
        <div className="flex items-center  justify-end ">
          <div className="mt-1 relative rounded-md shadow-sm flex-grow">
            <PageFooter currentPage={pagination.currentPage} totalPages={pagination.totalPages} totalRecords={pagination.totalRecords} />
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" aria-hidden="true">
              <IoSearch className="mr-3 h-4 w-4 text-gray-400" aria-hidden="true" />
            </div>
            <input
              onChange={e => setSearch(e.target.value)}
              value={search}
              type="text"
              name="search"
              id="search"
              className="block  pl-9 shadow-none sm:text-sm bg-gray-100 border-0 focus:outline-none rounded-sm"
              placeholder="Search options"
            />
          </div>

          <div className="ml-4">
            <Popover
              custom
              placement="left"
              ref={popover}
              content={close => (
                <div className="p-4">
                  <DateRangePicker
                    ranges={[
                      {
                        startDate: dateRange.startDate ? dateRange.startDate.toDate() : new Date(),
                        endDate: dateRange.endDate ? dateRange.endDate.toDate() : new Date(),
                        key: 'selection',
                      },
                    ]}
                    onChange={handleSelect}
                  />
                  <div className="flex justify-end mt-4">
                    <button
                      onClick={close}
                      className="w-[100px] py-2 mr-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 outline-none"
                    >
                      Close
                    </button>
                    <button
                      disabled={!dateRange.startDate && !dateRange.endDate}
                      onClick={() => {
                        setDateRange({ startDate: null, endDate: null })
                      }}
                      className="w-[100px] py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 outline-none"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              )}
            >
              <button
                type="button"
                className="inline-flex w-[250px] h-[30px] justify-center items-center border border-gray-300 shadow-sm text-sm leading-4 font-regular rounded-md text-gray-700 bg-white hover:bg-gray-50 outline-none"
              >
                <IoCalendar className="h-4 w-4 mr-4 text-gray-500" />
                {dateRange.startDate && dateRange.endDate ? `${dateRange.startDate.format('MM-DD-YYYY')} to ${dateRange.endDate.format('MM-DD-YYYY')}` : 'Select Date Range'}
              </button>
            </Popover>
          </div>
        </div>
      }
      title="Import Managment"
    >
      <div className="flex flex-col">
        <div className="flex-1 flex items-stretch">
          <main className="flex-1">
            <div className="align-middle inline-block min-w-full">
              <div className="min-w-full">
                {!loading ? (
                  <>
                    {importManagment?.length === 0 ? (
                      <EmptyState v2 description="No Imports found" />
                    ) : (
                      <Table
                        hasMore={pagination?.currentPage !== pagination?.totalPages}
                        fetchData={() => fetchImportManagment(pagination?.currentPage + 1)}
                        sortBy={filter.sortBy}
                        sortOrder={filter.sortOrder}
                        handleSort={(sortBy, sortOrder) => {
                          setImportManagment([])
                        }}
                        data={importManagment}
                        columns={columns}
                      />
                    )}
                  </>
                ) : (
                  <div className="w-full h-[200px] border-0 flex justify-center items-center">
                    <LoadingIcon />
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </Layout>
  )
}

export default Imports
