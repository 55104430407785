import { useState, useEffect, useRef } from 'react'
import { parseCookies } from 'nookies'
import _ from 'lodash'

const BookingsStream = ({ id, onStatusChange, onConnectionChange, onNewBooking, url }) => {
  const [sseConnected, setSseConnected] = useState(false)
  const [reconnectAttempts, setReconnectAttempts] = useState(0)
  const { accessToken } = parseCookies()
  const token = _.split(accessToken, ' ')[1]
  const sseRef = useRef(null)
  const reconnectTimeoutRef = useRef(null)

  useEffect(() => {
    if (onConnectionChange) {
      onConnectionChange(sseConnected)
    }
  }, [sseConnected, onConnectionChange])

  useEffect(() => {
    if (!url) return

    const connectSSE = () => {
      if (sseRef.current) {
        sseRef.current.close()
      }

      const apiUrl = `${url}?token=${encodeURIComponent(token)}`
      const eventSource = new EventSource(apiUrl)
      sseRef.current = eventSource

      eventSource.onopen = () => {
        setSseConnected(true)
        setReconnectAttempts(0)
        console.log('SSE connection established')
      }

      eventSource.onmessage = event => {
        try {
          const data = JSON.parse(event.data)

          if (data.connected) {
            console.log('SSE connection confirmed')
            return
          }

          // eslint-disable-next-line
          if (Array.isArray(data)) {
            // eslint-disable-next-line
            data.forEach(booking => {
              const bookingId = booking._id || booking.id
              const { status } = booking

              if (bookingId && status) {
                if (!id || bookingId === id) {
                  onStatusChange(bookingId, status, booking)
                }

                if (onNewBooking) {
                  onNewBooking(booking)
                }
              }
            })
          } else if (data.bookingId && data.status) {
            if (!id || data.bookingId === id) {
              onStatusChange(data.bookingId, data.status, data)

              if (onNewBooking) {
                onNewBooking(data)
              }
            }
          }
        } catch (error) {
          console.error('Error parsing SSE data:', error)
        }
      }

      eventSource.onerror = error => {
        console.error('SSE Error:', error)
        setSseConnected(false)

        if (sseRef.current) {
          sseRef.current.close()
          sseRef.current = null
        }

        const maxAttempts = 5
        if (reconnectAttempts < maxAttempts) {
          const delay = Math.min(1000 * 2 ** reconnectAttempts, 30000)
          console.log(`Reconnecting in ${delay}ms (attempt ${reconnectAttempts + 1}/${maxAttempts})`)

          reconnectTimeoutRef.current = setTimeout(() => {
            setReconnectAttempts(prev => prev + 1)
            connectSSE()
          }, delay)
        } else {
          console.error('Max reconnection attempts reached')
        }
      }
    }

    connectSSE()

    return () => {
      if (sseRef.current) {
        sseRef.current.close()
        sseRef.current = null
      }
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current)
      }
    }
  }, [url, token, reconnectAttempts, onStatusChange, onNewBooking, id])

  useEffect(() => {
    if (sseConnected && token) {
      if (sseRef.current) {
        sseRef.current.close()
        sseRef.current = null
      }
      setReconnectAttempts(0)
    }
  }, [token, sseConnected])

  return null
}

export default BookingsStream
