import React, { useEffect, useState } from 'react'
import { Dialog } from '@headlessui/react'
import Switch from 'components/atoms/Switch'
import Button from 'components/atoms/Button'
import { ClockIcon } from '@heroicons/react/outline'
import { IoClose } from 'react-icons/io5'
import { find, some } from 'lodash'

const ShiftModeDialog = ({ isOpen, onClose, onSave, timings, onShiftChange, onSwitchChange, values, setFieldValue }) => {
  const handleSave = () => {
    const timetable = Object.entries(timings).map(([day, value]) => ({
      day,
      active: value.enabled,
      shift1: {
        startTime: value.shifts[0].start + ':00',
        endTime: value.shifts[0].end + ':00',
      },
      shift2: {
        startTime: value.shifts[1].start + ':00',
        endTime: value.shifts[1].end + ':00',
      },
    }))

    const existingShitingMode = find(values?.timings, { mode: 'shift' }) || {}

    onSave([
      {
        mode: 'shift',
        isActive: existingShitingMode.isActive ?? false,
        timetable: timetable,
      },
    ])

    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="bg-white rounded-lg p-6 w-full max-w-xl max-h-[80vh] overflow-y-auto">
          <div className="flex justify-between items-center mb-6">
            <Dialog.Title className="text-xl font-semibold">TIMINGS</Dialog.Title>
            <button onClick={onClose} className="p-1">
              <IoClose />
            </button>
          </div>

          <div className="mb-6">
            <div className="flex items-center justify-between">
              <h3 className="text-lg mb-2">Shift Mode</h3>
              <Switch
                value={some(values.timings, { mode: 'shift', isActive: true })}
                onChange={e => {
                  setFieldValue('timings', [...values.timings.filter(t => t.mode !== 'shift'), { mode: 'shift', isActive: e }])
                }}
              />
            </div>
            <p className="text-gray-600 text-sm mt-2 mb-4">The office works for multiple shifts per day</p>
          </div>

          <div className="space-y-4">
            {Object.entries(timings).map(([day, value]) => (
              <div key={day} className="border-b border-gray-200 pb-4">
                <div className="flex items-center justify-between mb-2">
                  <span className="text-gray-700 font-medium">{day}</span>
                  <Switch value={value.enabled} onChange={checked => onSwitchChange(day, checked)} />
                </div>
                {value.enabled && (
                  <div className="space-y-2">
                    {value.shifts.map((shift, index) => (
                      <div key={index} className="flex items-center gap-2">
                        <ClockIcon className="h-4 w-4 text-gray-400" />
                        <input
                          type="time"
                          value={shift.start}
                          onChange={e => onShiftChange(day, index, 'start', e.target.value)}
                          className="border border-gray-300 rounded px-2 py-1 text-sm"
                        />
                        <span>-</span>
                        <input
                          type="time"
                          value={shift.end}
                          onChange={e => onShiftChange(day, index, 'end', e.target.value)}
                          className="border border-gray-300 rounded px-2 py-1 text-sm"
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="mt-8 flex justify-end gap-3">
            <Button onClick={onClose} extraClassName="bg-gray-500 hover:bg-gray-600">
              Cancel
            </Button>
            <Button onClick={handleSave}>Save Changes</Button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

export default ShiftModeDialog
