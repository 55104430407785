import { ArrowSmUpIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import LoadingIcon from 'components/atoms/LoadingIcon'
import InfiniteScroll from 'react-infinite-scroll-component'

import { accessibleOnClick } from 'utils'

const Table = ({ onRowClick = () => {}, columns, fetchData, data = [], noTable, handleSort, sortBy, sortOrder, hasMore = true, bgRow }) => {
  return (
    <div className="">
      <InfiniteScroll
        className="w-full"
        style={{ minHeight: 'calc(100vh - 54px)' }}
        dataLength={data.length} // This is important field to render the next data
        next={fetchData}
        scrollableTarget="rootScrollable"
        hasMore={hasMore}
        loader={
          <div style={{ width: 'calc(100vw - 256px)' }} className="h-[54px] flex justify-center items-center">
            <LoadingIcon />
          </div>
        }
      >
        <table className="min-w-full table-auto select-none w-full overflow-auto">
          <thead>
            <tr className="border-gray-200 border-b">
              {!noTable &&
                columns.map((column, index) => {
                  return (
                    <th
                      key={index}
                      width={column.width}
                      style={{ width: column.width || 'auto' }}
                      className={classNames('px-4 py-2 border-b', ' border-gray-200 bg-gray-50 text-left text-xs text-normal text-gray-500 uppercase tracking-wider')}
                    >
                      <div className={classNames('flex items-center', column.align === 'right' ? 'justify-end' : 'justify-start')}>
                        <span className="text-xs font-normal">{column.name}</span>
                        {column.sortable && (
                          <span
                            className={classNames(
                              sortBy === column.key && 'text-primary-500',
                              'ml-2 h-6 w-6 hover:bg-gray-100 text-xs font-normal cursor-pointer rounded-full flex items-center justify-center'
                            )}
                            {...accessibleOnClick(() => {
                              if (sortOrder === 'asc') {
                                handleSort(column.key, 'desc')
                              } else {
                                handleSort(column.key, 'asc')
                              }
                            })}
                          >
                            <ArrowSmUpIcon className={classNames(sortBy === column.key && sortOrder === 'asc' && 'rotate-180', 'h-4 w-4')} />
                          </span>
                        )}
                      </div>
                    </th>
                  )
                })}
            </tr>
          </thead>
          <tbody>
            {data.map((item, rowIndex) => {
              return (
                <tr onClick={onRowClick} key={rowIndex} className={classNames(!noTable && 'border-t border-gray-200', bgRow || 'bg-white')}>
                  {columns.map((column, columnIdx) => {
                    return (
                      <td
                        width={column.width}
                        style={{ width: column.width || 'auto' }}
                        key={columnIdx}
                        className={classNames(noTable && 'p-0', !noTable && 'px-4 py-2', 'whitespace-nowrap text-left text-sm text-gray-900', `text-${column.align}`)}
                      >
                        {column.rendered(item, rowIndex)}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </InfiniteScroll>
    </div>
  )
}

export default Table
