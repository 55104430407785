import { initializeApp } from 'firebase/app'
// import { getMessaging } from 'firebase/messaging'
import { getMessaging } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyC_zC7Edr_y84an1vfIDsTK8AsIR8GnMY4',
  authDomain: 'ajarlee-office-cbef1.firebaseapp.com',
  projectId: 'ajarlee-office-cbef1',
  storageBucket: 'ajarlee-office-cbef1.firebasestorage.app',
  messagingSenderId: '994693789524',
  appId: '1:994693789524:web:29d75a4d464e0a0e687ac1',
}

const app = initializeApp(firebaseConfig)
export const messaging = getMessaging(app)
