import { useEffect, useState } from 'react'
import Layout from 'containers/Layout'
import { Route, Switch, useHistory, useParams } from 'react-router-dom'

import apiGet from 'lib/network/apiGet'
import { forEach, get } from 'lodash'
import Booking from './booking'
import apiPost from 'lib/network/apiPost'
import notify from 'components/atoms/Notify'
import BookingLogsDrawer from './bookingLogs'
import { useSelector } from 'react-redux'
import useModal from 'hooks/useModal'
import AddRentalCar from './modals/rentalCarModal'
import Stream from 'lib/network/apiStream'

const ViewBooking = () => {
  const history = useHistory()

  const [isEdit, setIsEdit] = useState(null)
  const [bookingData, setBookingData] = useState({})
  const [loading, setLoading] = useState(false)
  const [acceptLoading, setAcceptLoading] = useState(false)
  const [rejectLoading, setRejectLoading] = useState(false)
  const [loadingCarId, setLoadingCarId] = useState(null)
  const [streamConnected, setStreamConnected] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const userFromStore = useSelector(state => get(state, 'user.user'))
  // eslint-disable-next-line
  const isCompanyRole = userFromStore?.roles?.some(role => role.type === 'company')
  // eslint-disable-next-line
  const hasAdminAccess = userFromStore?.roles?.some(role => role.type === 'admin')

  const id = useParams()?.id

  const baseUrl = process.env.REACT_APP_BASE_URL
  const url = `${baseUrl}/admin/status/stream/${id}`

  useEffect(() => {
    if (id) {
      setIsEdit(true)
      fetchBooking(id)
    }
  }, [id])

  const fetchBooking = async () => {
    setLoading(true)
    const response = await apiGet(`booking/${id}`)

    forEach(Object.entries(response.data), ([key, value]) => {
      if (value === 'null') {
        delete response.data[key]
      }
    })

    const data = response.data

    setBookingData(data)
    setLoading(false)
  }

  const handleStatusChange = newStatus => {
    if (newStatus !== bookingData.status) {
      setBookingData(prevData => ({
        ...prevData,
        status: newStatus,
      }))
      notify.info(`Booking status updated to: ${newStatus}`)
    }
  }

  const handleConnectionChange = connected => {
    setStreamConnected(connected)
  }
  const handleCancel = async (rentalCarId = null) => {
    const message = rentalCarId ? 'Are you sure you want to cancel this booking and create a new one with the selected car?' : 'Are you sure you want to cancel this booking?'

    notify.deleteConfirm({
      title: 'Cancel Booking',
      message,
      onConfirm: async () => {
        try {
          setLoadingCarId(rentalCarId)

          const response = await apiPost(`booking/cancel/${id}`, rentalCarId ? { rentalCarId } : {})

          if (response?.success) {
            if (rentalCarId) {
              closeModal()
            }

            notify.success(rentalCarId ? 'Booking cancelled and new booking created successfully' : 'Booking cancelled successfully')
            history.push('/bookings')
          }
        } catch (error) {
          notify.error(error?.message || 'Failed to process booking')
        } finally {
          setLoadingCarId(false)
        }
      },
    })
  }

  const handleUpdateBooking = async status => {
    try {
      if (status === 'confirmed') {
        setAcceptLoading(true)
      } else if (status === 'rejected') {
        setRejectLoading(true)
      }

      const response = await apiPost(`booking/${id}`, { status })

      if (response?.success) {
        notify.success(`Booking ${status} successfully`)
      }
    } catch (error) {
      notify.error('Failed to update booking')
      fetchBooking()
    } finally {
      setAcceptLoading(false)
      setRejectLoading(false)
    }
  }

  const closeDrawer = () => {
    setIsDrawerOpen(false)
  }

  const [AddUserModal, openAddUserModel, closeModal] = useModal({
    content: <AddRentalCar bookingData={bookingData} onSelectCar={handleCancel} loadingCarId={loadingCarId} />,

    title: 'Change Car',
    dimensions: {
      height: '650px',
      width: '950px',
    },
  })

  return (
    <Layout
      headerRight={
        <>
          {hasAdminAccess && !isCompanyRole && (bookingData?.status === 'pending' || bookingData?.status === 'confirmed') && (
            <div className="mt-4 flex sm:mt-0 sm:ml-4">
              <button
                onClick={() => handleCancel()}
                type="button"
                className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:order-1 sm:ml-3"
              >
                Cancel Booking
              </button>
            </div>
          )}
          {bookingData?.status === 'pending' && bookingData?.paymentStatus === 'paid' && (
            <>
              <div className="mt-4 flex sm:mt-0 sm:ml-4">
                <button
                  onClick={() => handleUpdateBooking('rejected')}
                  type="button"
                  className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:order-1 sm:ml-3"
                  disabled={rejectLoading || acceptLoading}
                >
                  {rejectLoading ? 'Rejecting...' : 'Reject Booking'}
                </button>
              </div>
              <div className="mt-4 flex sm:mt-0 sm:ml-4">
                <button
                  onClick={() => handleUpdateBooking('confirmed')}
                  type="button"
                  className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500 sm:order-1 sm:ml-3"
                  disabled={acceptLoading || rejectLoading}
                >
                  {acceptLoading ? 'Accepting...' : 'Accept Booking'}
                </button>
              </div>
            </>
          )}
          {!(bookingData.status === 'cancelled' || bookingData.status === 'rejected' || bookingData.status === 'completed') && (
            <div className="mt-4 flex sm:mt-0 sm:ml-4">
              <button
                type="button"
                className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500 sm:order-1 sm:ml-3"
                onClick={openAddUserModel}
              >
                Change Car
              </button>
            </div>
          )}

          <div className="mt-4 flex sm:mt-0 sm:ml-4">
            <button
              onClick={() => setIsDrawerOpen(true)}
              type="button"
              className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500 sm:order-1 sm:ml-3"
            >
              View Logs
            </button>
          </div>
          <div className="mt-4 flex sm:mt-0 sm:ml-4">
            <button
              onClick={() => {
                history.push('/bookings')
              }}
              type="button"
              className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500 sm:order-1 sm:ml-3"
            >
              Go Back
            </button>
          </div>
        </>
      }
      title={'Order Summary'}
    >
      <Stream id={id} onStatusChange={handleStatusChange} url={url} onConnectionChange={handleConnectionChange} />

      <div className="flex overflow-auto">
        <Switch>
          <Route
            key={id}
            path="/booking/:id"
            component={() => <Booking refetch={fetchBooking} bookingData={bookingData} loading={loading} id={id} isEdit={isEdit} streamConnected={streamConnected} />}
          />
        </Switch>
      </div>

      <BookingLogsDrawer isDrawerOpen={isDrawerOpen} closeDrawer={closeDrawer} selectedBooking={bookingData} />
      <AddUserModal />
    </Layout>
  )
}

export default ViewBooking
