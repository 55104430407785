export const SET_USER_DATA = 'SET_USER_DATA'
export const SET_USER_PROJECTS = 'SET_USER_PROJECTS'
export const SET_VALUE = 'SET_VALUE'
export const SET_TEMPORARY_USER = 'SET_TEMPORARY_USER'
export const SET_DRAWER_TOGGLE = 'SET_DRAWER_TOGGLE'
export const SET_WORKSPACE_LIST = 'SET_WORKSPACE_LIST'
export const SET_WORKSPACE = 'SET_WORKSPACE'
export const SET_MENU_TOGGLES = 'SET_MENU_TOGGLES'
export const SET_TYPES = 'SET_TYPES'
export const SET_FORMS = 'SET_FORMS'
export const SET_COMMUNITY_LIST = 'SET_COMMUNITY_LIST'
export const SET_TIERS = 'SET_TIERS'
export const SET_MAIN_MENU_TOGGLES = 'SET_MAIN_MENU_TOGGLES'
export const SET_NOTIFICATION_LIST = 'SET_NOTIFICATION_LIST'
export const SET_NOTIFICATION_COUNT = 'SET_NOTIFICATION_COUNT'
export const DECREMENT_NOTIFICATION_COUNT = 'DECREMENT_NOTIFICATION_COUNT'
export const SET_EVENT_LIST = 'SET_EVENT_LIST'
