import { useState, useEffect, useRef } from 'react'
import { parseCookies } from 'nookies'
import _ from 'lodash'

const Stream = ({ id, onStatusChange, url, onConnectionChange }) => {
  const [sseConnected, setSseConnected] = useState(false)
  const { accessToken } = parseCookies()
  const token = _.split(accessToken, ' ')[1]
  const sseRef = useRef(null)

  useEffect(() => {
    if (onConnectionChange) {
      onConnectionChange(sseConnected)
    }
  }, [sseConnected, onConnectionChange])

  useEffect(() => {
    if (!id) return

    const apiUrl = `${url}?token=${encodeURIComponent(token)}`

    const eventSource = new EventSource(apiUrl)
    sseRef.current = eventSource

    eventSource.onopen = () => {
      setSseConnected(true)
    }

    eventSource.onmessage = event => {
      try {
        const data = JSON.parse(event.data)
        if (data.bookingId === id && data.status) {
          onStatusChange(data.status)
        }
      } catch (error) {
        console.error('Error parsing SSE data:', error)
      }
    }

    eventSource.onerror = error => {
      console.error('SSE Error details:', error)
      setSseConnected(false)
      if (error.target?.readyState === EventSource.CLOSED) {
        eventSource.close()
      }
    }

    return () => {
      if (sseRef.current) {
        sseRef.current.close()
      }
    }
  }, [id, token, onStatusChange])

  useEffect(() => {
    if (sseConnected && token) {
      if (sseRef.current) {
        sseRef.current.close()
        sseRef.current = null
      }
    }
  }, [token, sseConnected])
  return null
}

export default Stream
