import notify from 'components/atoms/Notify'
import apiPost from 'lib/network/apiPost'
import Switch from 'components/atoms/Switch'
import { useState } from 'react'

const ViewCompanyCar = ({ imageUrl, onClose, imageId, refetch }) => {
  const [isRealImageSwitch, setIsRealImageSwitch] = useState(false)
  const handleStatusChange = async status => {
    try {
      const updateIsRealImage = isRealImageSwitch && status === 'accepted'

      const payload = { status, updateIsRealImage }

      const response = await apiPost(`verify/company/car-image/${imageId}`, payload)

      if (response.success) {
        notify.success('Car Images updated successfully')
        refetch()
        onClose()
      }
    } catch (err) {
      notify.error('An error occurred')
    }
  }
  const handleSwitchChange = checked => {
    setIsRealImageSwitch(checked)
  }

  if (!imageUrl) return null

  return (
    <div className="relative w-full max-w-4xl mx-auto">
      <div className="flex flex-col">
        <div className="flex-1 overflow-hidden mb-4">
          <img src={imageUrl} alt="Car" className="w-full h-auto max-h-[60vh] object-contain" />
        </div>

        <div className="flex justify-center gap-2 mt-4">
          <div className="grid grid-cols-2 gap-2 ml-[10px]">
            <p className="whitespace-nowrap">Treated As Real Images</p>
            <Switch id="isRealImageSwitch" value={isRealImageSwitch} onChange={handleSwitchChange} />
          </div>
          <button
            className="mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
            onClick={() => handleStatusChange('accepted')}
          >
            Accepted
          </button>
          <button
            className="mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
            onClick={() => handleStatusChange('rejected')}
          >
            Rejected
          </button>
        </div>
      </div>
    </div>
  )
}

export default ViewCompanyCar
