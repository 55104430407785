import { BellIcon, CheckCircleIcon } from '@heroicons/react/outline'
import { BellAlertIcon } from '@heroiconsv2/react/24/outline'
import classNames from 'classnames'
import LoadingIcon from 'components/atoms/LoadingIcon'
import EmptyState from 'components/pageComponents/EmptyState'
import apiGet from 'lib/network/apiGet'
import apiPut from 'lib/network/apiPut'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { decrementNotificationCount } from 'state/redux/reducers/notification.reducers'

const NotificationDrawer = ({ open }) => {
  const [notifications, setNotifications] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({})
  const [loadingMore, setLoadingMore] = useState(false)
  const dispatch = useDispatch()

  const fetchNotifications = async (page = 1) => {
    if (page === 1) {
      setLoading(true)
    } else {
      setLoadingMore(true)
    }

    const params = {}
    params.pageNumber = page
    params.pageSize = 20

    try {
      const response = await apiGet(`notifications`, params)
      if (page === 1) {
        setNotifications(response?.data?.notifications || [])
      } else {
        setNotifications(prev => [...prev, ...(response?.data?.notifications || [])])
      }
      setPagination(response?.data?.pagination || {})
    } catch (error) {
      console.error('Error fetching notifications:', error)
    } finally {
      setLoading(false)
      setLoadingMore(false)
    }
  }

  const markAsRead = async id => {
    try {
      await apiPut(`/notifications/${id}`)
      setNotifications(notifications.map(notification => (notification._id === id ? { ...notification, isRead: true } : notification)))
      dispatch(decrementNotificationCount())
    } catch (error) {
      console.error('Error marking notification as read:', error)
    }
  }

  useEffect(() => {
    if (open) fetchNotifications()
  }, [open])

  const handleScroll = e => {
    const { scrollTop, clientHeight, scrollHeight } = e.target
    if (scrollHeight - scrollTop <= clientHeight * 1.5) {
      if (!loadingMore && pagination?.currentPage !== pagination?.totalPages) {
        fetchNotifications(pagination?.currentPage + 1)
      }
    }
  }

  return (
    <div>
      {!loading ? (
        <>
          {notifications?.length === 0 ? (
            <div>
              <EmptyState icon={<BellIcon className="h-16 text-gray-400 v-16 m-auto" />} v2 description="All Caught Up!" />
            </div>
          ) : (
            <div className="mb-0 overflow-auto max-h-[400px] z-8000" onScroll={handleScroll}>
              <ul className="divide-y divide-gray-200">
                {notifications.map(notification => (
                  <li
                    key={notification._id}
                    className="relative mb-0 flex bg-white py-4 px-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 hover:bg-gray-50"
                  >
                    <div className="bg-[#1344ce] mr-4 min-w-[40px] h-[40px] w-[40px] flex justify-center items-center rounded-full">
                      <BellAlertIcon className="h-6 w-6 text-white" />
                    </div>
                    <div className="w-full">
                      <h4 className={classNames('text-sm font-medium mb-1 text-gray-900', !notification.isRead && 'font-semibold')}>{notification.title.en}</h4>
                      <p className={classNames('text-sm mb-0 text-gray-800 line-clamp-2', !notification.isRead && 'font-semibold')}>{notification.message.en}</p>
                      <span className="text-xs mt-1 block text-gray-500">{moment(notification.createdAt).fromNow()}</span>
                    </div>
                    {!notification.isRead && (
                      <button className="absolute top-4 right-4 text-indigo-600 hover:text-indigo-800" onClick={() => markAsRead(notification._id)}>
                        <CheckCircleIcon className="h-5 w-5" />
                      </button>
                    )}
                  </li>
                ))}
              </ul>
              {loadingMore && (
                <div className="w-full py-4 flex justify-center">
                  <LoadingIcon />
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <div className="w-full h-[200px] border-0 flex justify-center items-center">
          <LoadingIcon />
        </div>
      )}
    </div>
  )
}

export default NotificationDrawer
