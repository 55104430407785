import { useEffect, useImperativeHandle, useState } from 'react'
import apiGet from 'lib/network/apiGet'
import Table from 'components/pageComponents/Table'
import LoadingIcon from 'components/atoms/LoadingIcon'
import EmptyState from 'components/pageComponents/EmptyState'
import useParams from 'hooks/useGetParams'
import { isEmpty, isEqual, keys, uniqBy, uniqWith } from 'lodash'
import queryString from 'query-string'
import PageFooter from 'components/paginationComponent'

const WithReports = ({
  isReg,
  isCampaign,
  isTransaction,
  isExternalUser,
  onRowClick,
  workspace,
  setSearchKey,
  forwardedRef,
  apiDownloadURL,
  q,
  currType,
  noTable,
  columns,
  apiURL,
  status,
  dateRange,
  search,
  sStatus,
  setDateRange,
  setScanStatus,
  apiQueryString,
  bgRow,
  streamConnected,
  onDataUpdate,
  bookingCreatedBy,
}) => {
  const [filter, setFilter] = useParams({ workspace })

  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({})
  const [users, setUsers] = useState([])

  // useEffect(() => {
  //   const qs = queryString.parse(window.location.search)
  //   if (!qs.ed && !qs.sd) {
  //     setDateRange && setDateRange({})
  //   }
  //   if (!qs.search) {
  //     setSearchKey && setSearchKey('')
  //   }
  //   if (!qs.scanStatus) {
  //     setScanStatus && setScanStatus(null)
  //   }
  // }, [])
  useEffect(() => {
    const qs = queryString.parse(window.location.search)
    if (!qs.ed && !qs.sd) {
      setDateRange({})
    }
    if (!qs.search) {
      setSearchKey('')
    }
    if (!qs.scanStatus) {
      setScanStatus(null)
    }
  }, [])

  useEffect(() => {
    if (dateRange) {
      setFilter({ ...filter, ...dateRange })
      setPagination({ currentPage: 0 })
      setUsers([])
    }
  }, [dateRange])

  useEffect(() => {
    if (filter.workspace !== null) {
      fetchData()
    }
  }, [filter])

  useEffect(() => {
    if (sStatus) {
      setFilter({ ...filter, scanStatus: sStatus })
      setPagination({ currentPage: 0 })
      setUsers([])
    }
  }, [sStatus])

  useEffect(() => {
    searchList(search)
  }, [search])

  useState(() => {
    if (search !== filter.search && setSearchKey) {
      setSearchKey(filter.search)
    }
  }, [search, filter.search, setSearchKey])

  useEffect(() => {
    if (workspace != null) {
      setFilter({ ...filter, workspace })
      setPagination({ currentPage: 0 })
      setUsers([])
    }
  }, [workspace])
  useEffect(() => {
    setFilter(prevFilter => ({
      ...prevFilter,
      bookingCreatedBy,
    }))

    setPagination({ currentPage: 0 })
    setUsers([])
  }, [bookingCreatedBy])

  useEffect(() => {
    if (q) {
      if (keys(q)?.length > 0) {
        setFilter({ ...filter, q: JSON.stringify(q) })
        setPagination({ currentPage: 0 })
        setUsers([])
      } else {
        setFilter(prev => {
          return {
            ...prev,
            q: '',
          }
        })
      }
    }
  }, [q])

  useEffect(() => {
    if (onDataUpdate && users.length > 0) {
      onDataUpdate(users)
    }
  }, [users, onDataUpdate])

  useImperativeHandle(forwardedRef, () => ({
    download: async (setDownloading, cb) => {
      setDownloading(true)
      const response = await apiGet(apiDownloadURL, { size: 9999999, page: 0, ...filter, ...apiQueryString })
      if (response.data?.content) {
        const csvContent = 'data:text/csv;charset=utf-8,' + response?.data?.content

        const encodedUri = encodeURI(csvContent)
        const link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute('download', response?.data?.name)
        document.body.appendChild(link)

        link.click() // This will download the data file named "my_data.csv".
      } else {
        window.open(`${process.env.REACT_APP_BASE_URL}/admin/registrations/download/${response?.data?.name}`, '_blank')
      }

      setDownloading(false)

      cb()
    },
    addNewBooking: booking => {
      if (booking) {
        const bookingId = booking._id || booking.id

        // eslint-disable-next-line
        const exists = users.some(existingBooking => {
          const existingId = existingBooking._id || existingBooking.id
          return existingId === bookingId
        })

        if (!exists) {
          setUsers(prevUsers => [booking, ...prevUsers])
        }
      }
    },
  }))

  const fetchData = async (noLoading = false) => {
    if (!noLoading) setLoading(true)
    const response = await apiGet(apiURL, { size: 30, page: (pagination?.currentPage || 0) + 1, ...filter, ...apiQueryString })

    setPagination(response?.data?.pagination)
    if (isReg) {
      const data = response.data?.list
      setUsers(uniqBy([...users, ...data], 'id'))
    } else if (isCampaign) {
      const data = response.data?.list
      setUsers(uniqWith([...users, ...data], isEqual))
    } else {
      const data = response.data?.list
      setUsers([...users, ...data])
    }

    setLoading(false)
  }

  const searchList = async value => {
    if (value?.length >= 2) {
      setPagination({ currentPage: 0 })
      setUsers([])
      setFilter({ ...filter, search: value })
    } else {
      setPagination({ currentPage: 0 })
      setUsers([])
      setFilter({ ...filter, search: null })
    }
  }

  return (
    <div className="flex flex-col">
      <div className="flex-1 flex items-stretch">
        <main className="flex-1">
          <div className="align-middle inline-block min-w-full">
            {(isReg || isCampaign) && (
              <div className="px-4 py-2 absolute bottom-4 left-[50%]">
                {isReg && users?.length !== pagination?.count && (
                  <span className="tag">
                    Showing {users?.length} of {pagination?.count}
                  </span>
                )}
                {isCampaign && <span className="tag">Campaign data queried from {users?.reduce((a, b) => a + parseInt(b?.count), 0)} users</span>}
                {isReg &&
                  keys(q).map((item, index) =>
                    !isEmpty(q[item]) ? (
                      <span key={index} className="tag">
                        {item} : {q[item]}
                      </span>
                    ) : null
                  )}
              </div>
            )}
            <PageFooter currentPage={pagination.currentPage} totalPages={pagination.totalPages} totalRecords={pagination.totalRecords} />

            <div className="min-w-full">
              {!loading ? (
                <>
                  <div className="flex justify-start mt-4">
                    {streamConnected && (
                      <div className="mb-4 text-xs text-green-600 p-2 bg-green-50 rounded-md">
                        <span className="inline-block w-2 h-2 bg-green-500 rounded-full mr-2"></span>
                        Live updates connected
                      </div>
                    )}
                  </div>

                  {users?.length === 0 ? (
                    <EmptyState v2 description="No data found" />
                  ) : (
                    <Table
                      bgRow={bgRow}
                      sortBy={filter.sortBy}
                      sortOrder={filter.sortOrder}
                      handleSort={(sortBy, sortOrder) => {
                        setUsers([])
                        setFilter({ ...filter, sortBy, sortOrder })
                        if (isReg || isTransaction) {
                          setPagination({ currentPage: 0 })
                        }
                      }}
                      // onRowClick={isReg ? onRowClick : null}
                      noTable={noTable}
                      hasMore={pagination?.currentPage !== pagination?.totalPages}
                      fetchData={() => fetchData(true)}
                      data={isReg ? uniqBy(users, 'id') : users}
                      columns={columns}
                    />
                  )}
                </>
              ) : (
                <div className="w-full h-[200px] border-0 flex justify-center items-center">
                  <LoadingIcon />
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

export default WithReports
