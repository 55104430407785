import { lazy } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import AuthRoute from 'containers/AuthRoute'
import AuthContainer from 'containers/AuthContainer'
import NotFound from 'pages/common/NotFound'
import Users from 'pages/users/Users'
import Companies from 'pages/company/Companies'
import Cars from 'pages/car/Cars'
import AddEditUser from 'pages/users/AddEditUser'
import AddEditCompany from 'pages/company/AddEditCompany'
import AddEditCar from 'pages/car/AddEditCar'
import Roles from 'pages/roles/Roles'
import Reports from 'pages/reports/Reports'
import Branches from 'pages/branches/branches'
import AddEditBranch from 'pages/branches/AddEditBranch'

import ForgotPassword from 'pages/auth/ForgotPassword'
import Discounts from 'pages/discount/Discounts'
import BlackList from 'pages/blackList/BlackList'
import Imports from 'pages/imports/Imports'
import SalesReports from 'pages/salesReports/reports'

import { filter, flatMap, get, includes, map } from 'lodash'
import ViewBooking from 'pages/booking/viewBooking'
import { useSelector } from 'react-redux'
const DashboardPage = lazy(() => import('pages/dashboard'))
const LoginPage = lazy(() => import('pages/auth/Login'))

const LayoutRouter = ({ loading, isUser, user = {}, openChangePasswordModal }) => {
  const userFromStore = useSelector(state => get(state, 'user.user'))
  // eslint-disable-next-line
  const hasAdminAccess = userFromStore?.roles?.some(role => role.type === 'admin')

  const routes = [
    { path: '/login', exact: true, isPublic: true, isUser, component: LoginPage, showSideBar: false },
    { path: '/forgot', exact: true, isPublic: true, isUser, component: <ForgotPassword />, showSideBar: false },
    { path: '/dashboard', isUser, component: <DashboardPage /> },
    { path: '/users/add', isUser, component: <AddEditUser /> },
    { path: '/users/edit/:id', isUser, component: <AddEditUser /> },
    { path: '/transactions', isUser, component: <Reports /> },
    { path: '/bookings', isUser, component: <Reports /> },
    { path: '/audits', isUser, component: <Reports /> },
    // { path: '/users/edit/:id/admins', isUser, component: <Users />, key: 'id' },
    { path: '/branches', isUser, component: <Branches /> },
    { path: '/add/branch', isUser, component: <AddEditBranch /> },
    { path: '/branch/:id', isUser, component: <AddEditBranch /> },
    { path: '/users/:id', isUser, component: <Users /> },
    { path: '/users', isUser, component: <Users /> },
    { path: '/companies', isUser, component: <Companies /> },
    { path: '/roles', isUser, component: <Roles /> },
    { path: '/add/company', isUser, component: <AddEditCompany /> },
    { path: '/company/edit/:id', isUser, component: <AddEditCompany /> },
    { path: '/cars', isUser, component: <Cars /> },
    { path: '/car/edit/:id', isUser, component: <AddEditCar /> },
    { path: '/discounts', isUser, component: <Discounts /> },
    { path: '/fraud-users', isUser, component: <BlackList /> },
    { path: '/imports', isUser, component: <Imports /> },
    { path: '/booking/:id', isUser, component: <ViewBooking /> },
    { path: '/salesReport', isUser, component: <SalesReports /> },
  ]

  const access = flatMap(user?.roles, item => map(item.access, 'menu.pathname'))
  const hasAccess = route => {
    if (includes(['/users/:id', '/users/edit/:id', '/users'], route.path) && includes(access, '/users')) {
      return true
    }

    if (includes(['/add/company', '/company/edit/:id', '/companies'], route.path) && includes(access, '/companies')) {
      return true
    }
    if (includes(['/salesReport'], route.path) && includes(access, '/salesReport')) {
      return true
    }
    if (includes(['/car/edit/:id', '/cars'], route.path) && includes(access, '/cars')) {
      return true
    }
    if (includes(['/bookings', '/booking/:id', '/booking/cancel/:id'], route.path) && includes(access, '/bookings')) {
      return true
    }
    if (!hasAdminAccess && !user?.superCompanyUser) {
      if (includes(['/fraud-user/:id', '/fraud-users'], route.path) && includes(access, '/fraud-users')) {
        return false
      }
    }
    if (includes(['/add/branch', '/branch/:id', '/branches'], route.path) && includes(access, '/branches')) {
      return true
    }
    return includes(access, route.path)
  }

  return (
    <Router>
      <AuthContainer loading={loading}>
        {!loading && user ? (
          <>
            <Switch>
              <AuthRoute isUser={isUser} component={<LoginPage />} showSideBar={false} isPublic exact path="/login" />
              <AuthRoute isUser={isUser} component={<ForgotPassword />} showSideBar={false} isPublic exact path="/forgot" />

              {filter(routes, route => hasAccess(route))?.map((route, index) => {
                return <AuthRoute key={route.key || index} {...route} />
              })}

              {!isUser && <AuthRoute component={<Redirect to="/login" />} isUser={isUser} path="/" />}

              {isUser && <AuthRoute component={<Redirect to="/dashboard" />} isUser={isUser} path="/" />}

              <Route component={NotFound} />
            </Switch>
          </>
        ) : (
          <Switch>
            <AuthRoute isUser={isUser} component={<LoginPage />} showSideBar={false} isPublic exact path="/login" />
            <AuthRoute component={<Redirect to="/login" />} isUser={isUser} path="/" />
            <AuthRoute isUser={isUser} component={<ForgotPassword />} showSideBar={false} isPublic exact path="/forgot" />
          </Switch>
        )}
      </AuthContainer>
    </Router>
  )
}

export default LayoutRouter
