import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { BellIcon, XCircleIcon } from '@heroicons/react/outline'

const NotificationDialog = ({ notification, isNotificationOpen, setIsNotificationOpen }) => {
  const handleNotificationClick = () => {
    try {
      if (notification.bookingId) {
        const url = `booking/${notification.bookingId}`
        window.open(url, '_blank')
      }
    } catch (error) {
      console.error('Navigation error:', error)
    } finally {
      setIsNotificationOpen(false)
    }
  }

  return (
    <Transition appear show={isNotificationOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => setIsNotificationOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all cursor-pointer hover:bg-gray-50"
                onClick={handleNotificationClick}
              >
                <div className="flex items-start gap-4">
                  <div className="flex-shrink-0">
                    <BellIcon className="h-6 w-6 text-blue-500" />
                  </div>
                  <div className="flex-1">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 mb-2">
                      {notification.title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{notification.body}</p>
                    </div>
                  </div>
                  <button
                    onClick={e => {
                      e.stopPropagation()
                      setIsNotificationOpen(false)
                    }}
                    className="flex-shrink-0 text-gray-400 hover:text-gray-500"
                  >
                    <XCircleIcon className="h-5 w-5" />
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default NotificationDialog
