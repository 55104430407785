const colors = {
  inputBg: '#181728',
  darkText: '#181728',
  borderColor: 'rgba(255,255,255,0.1)',
  primary: '#fe5579',
  lightBackground: '#52516a',
  background: '#181728',
}

export default colors
