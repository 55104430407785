import * as types from 'state/redux/constants'

export const SET_NOTIFICATION_COUNT = 'SET_NOTIFICATION_COUNT'
export const DECREMENT_NOTIFICATION_COUNT = 'DECREMENT_NOTIFICATION_COUNT'

export const setNotificationCount = count => ({
  type: SET_NOTIFICATION_COUNT,
  payload: count,
})

export const decrementNotificationCount = () => ({
  type: DECREMENT_NOTIFICATION_COUNT,
})
const initialState = {
  unreadCount: 0,
}

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_NOTIFICATION_COUNT:
      return {
        ...state,
        unreadCount: action.payload,
      }
    case types.DECREMENT_NOTIFICATION_COUNT:
      return {
        ...state,
        unreadCount: Math.max(state.unreadCount - 1, 0),
      }
    default:
      return state
  }
}

export default notificationReducer
