import moment from 'moment'
import { forwardRef } from 'react'
import WithReports from './WithReports'
import toUpper from 'lodash/toUpper'
import { formateDate } from 'utils'
import { Tooltip } from 'antd'
import { useHistory } from 'react-router-dom'
import Blockies from 'react-blockies'

// eslint-disable-next-line
const ActivityLogs = forwardRef(({ ...props }, ref) => {
  const history = useHistory()

  const getLogType = type => {
    switch (type) {
      case 'direct':
        return 'ETH Payment'
      case 'moonpay':
        return 'MoonPay Payment'
      case 'moonpay_asset_info':
        return 'Requested Moonpay Asset Info'
      case 'moonpay_mint':
        return 'Moonpay Requested Mint'
      case 'admin':
        return 'Admin Action'
      case 'user':
        return 'User Action'
      case null:
        return 'Admin Action'
      default:
        return type
    }
  }

  const columns = [
    {
      name: 'Type',
      width: '100%',
      key: 'logType',
      sortable: false,
      rendered: item => (
        <div className="text-sm items-center bg-[#f3f3f3] flex text-gray-500 ">
          <div className="flex justify-center h-full w-[290px] ml-2 bg-[#f3f3f3] items-center mr-3">
            <span className="text-[12px] py-2 px-2 font-mono rounded-lg text-gray-500">
              {toUpper(getLogType(item.logType))}{' '}
              <span className="">
                {formateDate(moment(item.createdAt))} {moment(item.createdAt).fromNow()}
              </span>
            </span>
          </div>
          <div className="w-full flex px-3 items-center ">
            <div className="flex items-center">
              <span className="cursor-pointer" onClick={item?.updatedBy?._id ? () => history.push(`/users/edit/${item?.updatedBy?._id}/user-profile?t=2`) : null}>
                {item?.updatedBy?.avatar ? (
                  <img className="h-8 w-8 rounded-full mr-2" src={item?.updatedBy?.avatar} alt="" />
                ) : (
                  <Blockies className="rounded-full mr-2" seed={item.walletAddress || item.email || ''} size={5.5} scale={6} />
                )}
              </span>
              <span
                style={{ borderBottom: '1px dashed #dedede' }}
                className="font-regular text-[12px] block font-mono"
                onClick={item?.updatedBy?._id ? () => history.push(`/users/edit/${item?.updatedBy?._id}/user-profile?t=2`) : null}
              >
                {item.updatedBy?.fullName || item.updatedBy?.email || item.updatedBy?.phone || 'Admin'}
              </span>
              <div className="ml-4 flex justify-center h-full items-center ml-3">
                <span
                  className={`text-[10px] py-0.6 px-1 font-mono rounded-lg ${
                    // eslint-disable-next-line
                    item.action?.toLowerCase().includes('add')
                      ? 'bg-green-500 text-white'
                      : // eslint-disable-next-line
                      item.action?.toLowerCase().includes('delete')
                      ? 'bg-red-500 text-white'
                      : // eslint-disable-next-line
                      item.action?.toLowerCase().includes('update')
                      ? 'bg-yellow-500 text-black'
                      : 'bg-gray-500 text-white'
                  }`}
                >
                  {item.action}
                </span>
              </div>
              <div className="ml-4 flex justify-center max-w-full items-center">
                <span className="text-[10px] py-2 px-2 font-mono rounded-lg text-gray-500 whitespace-normal  max-w-[800px]">{item.message}</span>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ]

  const headers = [
    { label: 'Type', key: 'logType' },
    { label: 'Description', key: 'shortDescription' },
    { label: 'Date', key: 'createdAt' },
  ]
  const bgRow = 'bg-[#f3f3f3]'

  return (
    <WithReports
      filters={false}
      {...props}
      apiURL="audits"
      defaultFileName="Audit Logs"
      forwardedRef={ref}
      headers={headers}
      columns={columns}
      bgRow={bgRow}
      activeFilterName="past_7_days"
    />
  )
})

export default ActivityLogs
