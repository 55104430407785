import { useEffect, useState } from 'react'
import Layout from 'containers/Layout'
import moment from 'moment'
import _, { get } from 'lodash'
import apiGet from 'lib/network/apiGet'
import { useHistory, useParams } from 'react-router-dom'
import Blockies from 'react-blockies'

import Table from 'components/pageComponents/Table'
import LoadingIcon from 'components/atoms/LoadingIcon'
import EmptyState from 'components/pageComponents/EmptyState'
import Button from 'components/atoms/Button'

import useModal from 'hooks/useModal'

import { IoEye, IoPencil, IoSearch, IoTrashBin } from 'react-icons/io5'

import AddBlackList from './modals/AddUserModal'
import notify from 'components/atoms/Notify'
import apiDelete from 'lib/network/apiDelete'
import PageFooter from 'components/paginationComponent'
import { useSelector } from 'react-redux'

const BlackList = () => {
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({})
  const [fraudUsers, setFraudUsers] = useState([])
  const userFromStore = useSelector(state => get(state, 'user.user'))
  // eslint-disable-next-line
  const hasAdminAccess = userFromStore?.roles?.some(role => role.type === 'admin')

  const [selectedFraudUserId, setSelectedFraudUserId] = useState(null)
  const [search, setSearch] = useState('')

  const { id } = useParams()

  useEffect(() => {
    fetchBlackList()
  }, [search])

  const fetchBlackList = async (page = 1) => {
    if (page === 1) {
      setLoading(true)
    }
    const params = {}
    params.pageNumber = page
    params.pageSize = 20
    params.query = search || undefined

    const response = await apiGet(`fraud-users`, params)
    setFraudUsers(page === 1 ? response.data.fruadUser : [...fraudUsers, ...response.data.fruadUser])

    setPagination(response?.data?.pagination || {})
    setLoading(false)
  }

  const handleAddFraudUserSuccess = userId => {
    closeModal()
    fetchBlackList()
  }

  const deleteFraudUser = id => {
    notify.deleteConfirm({
      title: 'Delete Fraud User',
      message: 'Are you sure you want to delete this Fraud user? It will delete all related data.',
      onConfirm: async () => {
        const response = await apiDelete(`fraud-users/${id}`)
        if (response?.success) {
          fetchBlackList()
          notify.success('Fraud User deleted successfully')
        }
      },
    })
  }

  const [AddUserModal, openAddFraudUserModel, closeModal] = useModal({
    content: <AddBlackList onSuccess={handleAddFraudUserSuccess} id={selectedFraudUserId} />,
    title: 'Add BlackList',
    dimensions: {
      height: '660px',
      width: '650px',
    },
  })
  const columns = [
    {
      name: 'User Detail',
      width: '10%',
      key: 'name',
      sortable: true,
      rendered: item => {
        return (
          <div className="flex items-center cursor-pointer">
            <div className="flex-shrink-0 h-10 w-10 relative">
              {item?.user?.avatar ? (
                <img className="h-10 w-10 rounded-full" src={item?.user?.avatar} alt="" />
              ) : (
                <Blockies className="rounded-full" seed={item.walletAddress || item.email || ''} size={6.5} scale={6} />
              )}
            </div>
            <div className="ml-4">
              <div className="text-sm text-normal text-gray-900">{item?.user?.fullName}</div>
              <div className="text-sm text-normal text-gray-900">{item?.user?.email}</div>
              <div className="text-sm text-normal text-gray-900">{item?.user?.phone}</div>
            </div>
          </div>
        )
      },
    },
    {
      width: '10%',
      name: 'Fraud Name',
      key: 'name',
      sortable: true,
      rendered: item => {
        const name = item.name

        return (
          <>
            <div>
              <span className="text-[13px]">{name}</span>
            </div>
          </>
        )
      },
    },
    {
      width: '10%',
      name: 'Phone Number',
      key: 'phone',
      sortable: true,
      rendered: item => {
        const phone = item.phone

        return (
          <>
            <div>
              <span className="text-[13px]">{phone}</span>
            </div>
          </>
        )
      },
    },
    {
      width: '10%',
      name: 'Fraud CivilId',
      key: 'civilId',
      sortable: true,
      rendered: item => {
        const civilId = item.civilId
        return (
          <>
            <div>
              <span className="text-[13px]">{civilId}</span>
            </div>
          </>
        )
      },
    },
    {
      width: '10%',
      name: 'Created by User',
      key: 'createdByUser',
      sortable: true,
      rendered: item => {
        const createdByUser = item?.createdByUser?.fullName

        return (
          <>
            <div>
              <span className="text-[13px]">{createdByUser}</span>
            </div>
          </>
        )
      },
    },
    {
      width: '10%',
      name: 'Created bY Company',
      key: 'createdByCompany',
      sortable: true,
      rendered: item => {
        const createdByCompany = item?.createdByCompany?.name

        return (
          <>
            <div>
              <span className="text-[13px]">{createdByCompany}</span>
            </div>
          </>
        )
      },
    },

    {
      width: '5%',
      name: 'Created',
      key: 'created',
      sortable: true,
      rendered: item => {
        const createdTime = get(item, 'createdAt', '')
        const createdAt = createdTime ? moment(createdTime).fromNow() : 'Registered'
        return createdAt
      },
    },
    {
      width: '5%',
      name: 'Actions',
      key: 'actions',
      rendered: item => (
        <div className="flex space-x-4">
          {hasAdminAccess ? (
            <IoPencil
              className="text-dark-600 cursor-pointer"
              onClick={() => {
                setSelectedFraudUserId(item._id)
                openAddFraudUserModel()
              }}
            />
          ) : (
            <IoEye
              className="text-dark-600 cursor-pointer"
              onClick={() => {
                setSelectedFraudUserId(item._id)
                openAddFraudUserModel()
              }}
            />
          )}
          {hasAdminAccess && <IoTrashBin className="text-red-500 cursor-pointer" onClick={() => deleteFraudUser(item._id)} />}
        </div>
      ),
    },
  ]

  return (
    <Layout
      showLogo={!!id}
      breadcrumbs={[{ name: 'Fraud Users', pathname: '/fraud-users' }]}
      headerRight={
        <div className="flex items-center justify-end ">
          <div className="mt-1 relative rounded-md shadow-sm flex-grow">
            <PageFooter currentPage={pagination.currentPage} totalPages={pagination.totalPages} totalRecords={pagination.totalRecords} />
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" aria-hidden="true">
              <IoSearch className="mr-3 h-4 w-4 text-gray-400" aria-hidden="true" />
            </div>
            <input
              onChange={e => setSearch(e.target.value)}
              value={search}
              type="text"
              name="search"
              id="search"
              className="block  pl-9 shadow-none sm:text-sm bg-gray-100 border-0 focus:outline-none rounded-sm"
              placeholder="Search options"
            />
          </div>
          <div className="mt-1 relative rounded-md shadow-sm"></div>
          <Button
            onClick={() => {
              setSelectedFraudUserId(null)
              openAddFraudUserModel()
            }}
          >
            Add BlackList
          </Button>
        </div>
      }
      title="BlackList Users"
    >
      <div className="flex flex-col">
        <div className="flex-1 flex items-stretch">
          <main className="flex-1">
            <div className="align-middle inline-block min-w-full">
              <div className="min-w-full">
                {!loading ? (
                  <>
                    {fraudUsers?.length === 0 ? (
                      <EmptyState v2 description="No users found" />
                    ) : (
                      <Table
                        hasMore={pagination?.currentPage !== pagination?.totalPages}
                        fetchData={() => fetchBlackList(pagination?.currentPage + 1)}
                        data={fraudUsers}
                        columns={columns}
                      />
                    )}
                  </>
                ) : (
                  <div className="w-full h-[200px] border-0 flex justify-center items-center">
                    <LoadingIcon />
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
      <AddUserModal />
    </Layout>
  )
}

export default BlackList
