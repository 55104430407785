import moment from 'moment'
import { forwardRef, Fragment, useEffect, useState, useRef } from 'react'
import WithReports from './WithReports'
import { formateDate, startEndTruncate, walletLink } from 'utils'
import _, { toLower, map, join, startCase, get } from 'lodash'
import classNames from 'classnames'
import { Tooltip } from 'antd'
import { IoInformationCircleSharp } from 'react-icons/io5'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import apiGet from 'lib/network/apiGet'
import queryString from 'query-string'
import { useHistory, useParams } from 'react-router-dom'
import BookingLogsDrawer from 'pages/booking/bookingLogs'
import BookingsStream from 'lib/network/apiBookingsStream'
import { useSelector } from 'react-redux'

// eslint-disable-next-line
const Bookings = forwardRef(({ setSearchKey, bookingCreatedBy, ...props }, ref) => {
  const history = useHistory()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [selectedBooking, setSelectedBooking] = useState(null)
  const [bookingStatuses, setBookingStatuses] = useState({})
  const [bookingPayments, setBookingPayments] = useState({})
  const [streamConnected, setStreamConnected] = useState(false)
  const withReportsRef = useRef(null)
  const userFromStore = useSelector(state => get(state, 'user.user'))
  // eslint-disable-next-line
  const hasAdminAccess = userFromStore?.roles?.some(role => role.type === 'admin')

  useEffect(() => {
    const qs = queryString.parse(window.location.search)

    if (qs?.bookingId) {
      setSelectedBooking({ _id: qs?.bookingId })
      setIsDrawerOpen(true)
    }
  }, [])

  const handleStatusChange = (bookingId, status, bookingData) => {
    setBookingStatuses(prev => ({
      ...prev,
      [bookingId]: status,
    }))

    setBookingPayments(prev => ({
      ...prev,
      [bookingId]: bookingData.paymentStatus,
    }))

    if (selectedBooking && selectedBooking._id === bookingId) {
      setSelectedBooking(prev => ({
        ...prev,
        ...bookingData,
        status: status,
        paymentStatus: bookingData.paymentStatus,
      }))
    }
  }

  const handleConnectionChange = connected => {
    setStreamConnected(connected)
  }

  const handleNewBooking = booking => {
    if (withReportsRef.current && booking) {
      withReportsRef.current.addNewBooking(booking)
    }
  }

  const calculateAjarleeAmount = (totalPrice, percentage) => {
    if (!totalPrice || !percentage) return 0
    return (totalPrice * (percentage / 100)).toFixed(2)
  }

  const calculateCompanyAmount = (totalPrice, percentage) => {
    if (!totalPrice || !percentage) return totalPrice || 0
    return (totalPrice - totalPrice * (percentage / 100)).toFixed(2)
  }

  const columns = [
    {
      name: 'User',
      width: '10%',
      key: 'type',
      sortable: false,
      rendered: item => (
        <div className="text-xs text-blue-500 font-mono">
          {item?.user ? (
            <Tooltip
              color="white"
              overlayClassName="!min-w-[400px] !w-full"
              title={
                <div className="flex flex-col gap-2 p-2">
                  <div className="flex border rounded p-2 flex-col text-[12px] text-gray-500">
                    <b className="mb-1.5 border-b">User Details:</b>
                    <div className="text-xs font-mono">
                      {item?.user ? (
                        <>
                          <div>
                            Name:{' '}
                            <a target="_blank" className="text-blue-500" href={`/users/edit/${item?.user?._id}`} rel="noreferrer">
                              {item?.user?.fullName}
                            </a>
                          </div>
                          <div className="mt-1">
                            Email: <span className="text-black">{item?.user?.email}</span>{' '}
                          </div>

                          {hasAdminAccess || _.includes(['confirmed', 'completed'], bookingStatuses[item._id] || item?.status) ? (
                            <div className="mt-1">
                              Phone: <span className="text-black">{item?.user?.phone}</span>{' '}
                            </div>
                          ) : null}

                          <div className="mt-1">
                            Nationality: <span className="text-black">{item?.user?.nationality}</span>{' '}
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              }
            >
              <a target="_blank" className="text-blue-500" href={`/users/edit/${item?.user?._id}`} rel="noreferrer">
                {item?.user?.fullName || item?.user?.email || item?.user?.phone}
              </a>
            </Tooltip>
          ) : (
            ''
          )}
        </div>
      ),
    },
    {
      name: 'Car',
      width: '10%',
      key: 'stripe',
      sortable: false,
      rendered: item => {
        return (
          <div className="text-xs text-blue-500 font-mono">
            {item ? (
              <a target="_blank" href={`/car/edit/${item?.rentalCar?._id}/car-profile`} rel="noreferrer">
                {item?.car?.make} {item?.car?.model} {item?.rentalCar?.modelYear}
              </a>
            ) : (
              ''
            )}
          </div>
        )
      },
    },
    {
      name: 'Company',
      width: '10%',
      key: 'stripe',
      sortable: false,
      rendered: item => {
        return (
          <div className="text-xs text-blue-500 font-mono">
            {item?.rentalCar?.company ? (
              <a target="_blank" href={`/company/edit/${item?.rentalCar?.company?._id}/company-profile`} rel="noreferrer">
                {item?.rentalCar?.company?.name}
              </a>
            ) : (
              ''
            )}
          </div>
        )
      },
    },
    {
      name: 'Branch',
      width: '10%',
      key: 'stripe',
      sortable: false,
      rendered: item => {
        return <div className="text-xs text-blue-500 font-mono">{item?.branch?.name}</div>
      },
    },
    {
      name: 'Invoice',
      width: '10%',
      key: 'name',
      sortable: true,
      rendered: item => {
        if (item?.invoiceUrl) {
          return (
            <div>
              <a href={item?.invoiceUrl} className="text-sm text-normal text-blue-400">
                <p>Download Invoice</p>
              </a>
            </div>
          )
        }
      },
    },
    {
      name: 'Booking Status',
      width: '10%',
      key: 'type',
      sortable: false,
      rendered: item => (
        <span
          className={classNames(`text-xs font-medium p-1 rounded-md`, {
            'bg-gray-200 text-black': (bookingStatuses[item._id] || bookingStatuses[item.id] || item?.status) === 'pending',
            'bg-rose-400 !text-white':
              (bookingStatuses[item._id] || bookingStatuses[item.id] || item?.status) === 'cancelled' ||
              (bookingStatuses[item._id] || bookingStatuses[item.id] || item?.status) === 'rejected',
            'bg-green-500 !text-white':
              (bookingStatuses[item._id] || bookingStatuses[item.id] || item?.status) === 'completed' ||
              (bookingStatuses[item._id] || bookingStatuses[item.id] || item?.status) === 'confirmed',
          })}
        >
          {startCase(bookingStatuses[item._id] || bookingStatuses[item.id] || item?.status)}
        </span>
      ),
    },
    {
      name: 'Created By',
      width: '10%',
      key: 'bookingCreatedBy',
      sortable: false,
      rendered: item => (
        <span
          className={`text-xs font-regular ${
            toLower(item.bookingCreatedBy) === 'user'
              ? 'bg-green-100 text-green-700'
              : toLower(item.bookingCreatedBy) === 'office'
              ? 'bg-yellow-100 text-yellow-800'
              : toLower(item.bookingCreatedBy) === 'admin'
              ? 'bg-gray-200 text-gray-700'
              : 'bg-gray-200 text-gray-700'
          } px-2 py-1 rounded-md`}
        >
          {startCase(toLower(item.bookingCreatedBy))}
        </span>
      ),
    },
    {
      name: 'Payment Method',
      width: '10%',
      key: 'stripe',
      sortable: false,
      rendered: item => (
        <span
          className={`text-xs font-regular ${
            toLower(item.paymentMethod) === 'card'
              ? 'bg-green-100 text-green-700'
              : toLower(item.paymentMethod) === 'cash'
              ? 'bg-yellow-100 text-yellow-800'
              : 'bg-gray-200 text-gray-700'
          } px-2 py-1 rounded-md`}
        >
          {startCase(toLower(item.paymentMethod))}
        </span>
      ),
    },
    {
      name: 'Delivery Type',
      width: '10%',
      key: 'name',
      sortable: true,
      rendered: item => {
        return (
          <div>
            <p>{startCase(item?.delivery?.type || 'PICK UP MYSELF')}</p>
          </div>
        )
      },
    },

    {
      name: 'Amount',
      width: '10%',
      key: 'amount',
      sortable: false,
      rendered: item => (
        <Tooltip
          color="white"
          overlayClassName="!min-w-[270px] !w-full"
          title={
            <div className="flex flex-col gap-2">
              <div className="flex border rounded p-2 flex-col text-[12px] text-gray-500">
                <b className="mb-1.5 border-b">Payment Breakdown:</b>
                <div className="text-xs font-mono">
                  {item ? (
                    <>
                      <div>
                        <div className="mt-1 grid grid-cols-2">
                          Car Price: <span className="text-black">{item?.priceWithoutTax} OMR</span>
                        </div>
                        {item?.priceWithoutTax !== item?.rentalCarDiscountPrice && (
                          <div className="mt-1 grid grid-cols-2">
                            Discounted Price: <span className="text-black">{item?.rentalCarDiscountPrice} OMR</span>
                          </div>
                        )}
                      </div>
                      <div className="mt-1 grid grid-cols-2">
                        Deposit Amount: <span className="text-black">{item?.depositAmount || 0} OMR</span>{' '}
                      </div>
                      <div className="mt-1 grid grid-cols-2">
                        Delivery Fee <span className="text-black">{item?.delivery?.deliveryCost || 0} OMR</span>{' '}
                      </div>
                      <div className="mt-1 grid grid-cols-2">
                        Insurance Fee: <span className="text-black">{item?.insuranceFee || 0} OMR</span>{' '}
                      </div>
                      <div className="mt-1 mb-2 grid grid-cols-2">
                        Vat Tax: <span className="text-black">{item?.vatTax || 0} OMR</span>{' '}
                      </div>
                      <div className="mt-1 grid border-t pt-1 grid-cols-2">
                        Total Amount: <span className="text-black">{item?.totalPrice} OMR</span>{' '}
                      </div>
                      <div className="mt-1 grid  pt-1 grid-cols-2">
                        Ajarlee {item?.ajarleePercentage}%: <span className="text-black">{calculateAjarleeAmount(item?.totalPrice, item?.ajarleePercentage)} OMR</span>
                      </div>
                      <div className="mt-1 grid border-t pt-1 grid-cols-2">
                        Company Amount: <span className="text-black">{calculateCompanyAmount(item?.totalPrice, item?.ajarleePercentage)} OMR</span>
                      </div>
                    </>
                  ) : (
                    'No payment details available'
                  )}
                </div>
              </div>
            </div>
          }
        >
          <span className={`text-xs font-medium text-gray-500 py-1 rounded-md`}>{item.totalPrice} OMR</span>
        </Tooltip>
      ),
    },
    {
      name: 'Payment Status',
      width: '10%',
      key: 'type',
      sortable: false,
      rendered: item => (
        <span
          className={`text-xs font-regular ${
            (bookingPayments[item._id] || bookingPayments[item.id] || item.paymentStatus) === 'paid'
              ? 'bg-green-100 text-green-700'
              : (bookingPayments[item._id] || bookingPayments[item.id] || item.paymentStatus) === 'failure'
              ? 'bg-red-100 text-red-700'
              : (bookingPayments[item._id] || bookingPayments[item.id] || item.paymentStatus) === 'unpaid'
              ? 'bg-yellow-100 text-yellow-800'
              : 'bg-gray-200'
          } px-2 py-1 rounded-md`}
        >
          {startCase(bookingPayments[item._id] || bookingPayments[item.id] || item.paymentStatus)}
        </span>
      ),
    },
    {
      name: 'Booking Date',
      width: '5%',
      key: 'bookingDate',
      align: 'left',
      sortable: true,
      rendered: item => (
        <div className="text-sm text-gray-500">
          <Tooltip title={moment.parseZone(item.bookingDate).format('MMMM Do YYYY, h:mm A')}>{moment.parseZone(item.bookingDate).fromNow()}</Tooltip>
        </div>
      ),
    },
    {
      name: 'Booking Start Date',
      width: '5%',
      key: 'rentalStartDate',
      align: 'left',
      sortable: true,
      rendered: item =>
        item.rentalStartDate && (
          <div className="text-sm text-gray-500">
            <Tooltip title={moment.parseZone(item.rentalStartDate).format('MMMM Do YYYY, h:mm A')}>{moment.parseZone(item.rentalStartDate).fromNow()}</Tooltip>
          </div>
        ),
    },

    {
      name: 'Booking End Date',
      width: '5%',
      key: 'rentalEndDate',
      align: 'left',
      sortable: true,
      rendered: item =>
        item.rentalEndDate && (
          <div className="text-sm text-gray-500 flex gap-1">
            <Tooltip title={moment.parseZone(item.rentalEndDate).format('MMMM Do YYYY, h:mm A')}>{moment.parseZone(item.rentalEndDate).fromNow()}</Tooltip>
          </div>
        ),
    },
    {
      name: 'Action',
      width: '5%',
      key: 'completedAt',
      align: 'left',
      rendered: item => (
        <>
          <div className="flex space-x-4">
            <button
              onClick={() => {
                // const bookingId = item._id || item.id
                history.push(`/booking/${item._id}`)
              }}
              className="w-full flex justify-center py-0 px-1 border border-transparent rounded-md shadow-sm text-sm text-normal text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
            >
              View Booking
            </button>
            <button
              onClick={() => {
                setSelectedBooking(item)
                setIsDrawerOpen(true)
              }}
              className="w-full flex justify-center py-0 px-1 border border-transparent rounded-md shadow-sm text-sm text-normal text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
              type="submit"
            >
              View logs
            </button>
          </div>
        </>
      ),
    },
  ]
  const closeDrawer = () => {
    setIsDrawerOpen(false)
    setSelectedBooking(null)
  }

  return (
    <>
      <WithReports
        {...props}
        setSearchKey={setSearchKey}
        apiURL="bookings"
        defaultFileName="Transaction"
        forwardedRef={withReportsRef}
        ref={ref}
        columns={columns}
        activeFilterName="past_7_days"
        streamConnected={streamConnected}
        bookingCreatedBy={bookingCreatedBy}
      />

      <BookingLogsDrawer isDrawerOpen={isDrawerOpen} closeDrawer={closeDrawer} selectedBooking={selectedBooking} />

      <BookingsStream
        id={selectedBooking?._id || selectedBooking?.id}
        onStatusChange={(bookingId, status, booking) => handleStatusChange(bookingId, status, booking)}
        onConnectionChange={handleConnectionChange}
        onNewBooking={handleNewBooking}
        url={`${process.env.REACT_APP_BASE_URL}/admin/bookings/stream`}
      />
    </>
  )
})

export default Bookings
