import { useEffect, useState } from 'react'
import Layout from 'containers/Layout'
import { useGet } from 'hooks/useApi'
import { Route, Switch, useHistory, useParams } from 'react-router-dom'

import CarProfile from 'pages/car/CarProfile'

import apiGet from 'lib/network/apiGet'
import { forEach, get } from 'lodash'
import { useSelector } from 'react-redux'

const AddCar = ({ readOnly, currentUserId }) => {
  const history = useHistory()
  const userFromStore = useSelector(state => get(state, 'user.user'))
  // eslint-disable-next-line
  const hasAdminAccess = userFromStore?.roles?.some(role => role.type === 'admin')

  const [roles, setRoles] = useState([])
  const [isEdit, setIsEdit] = useState(null)
  const [carData, setCarData] = useState({})
  const [carImages, setCarImages] = useState([])
  const [loading, setLoading] = useState(false)

  const id = useParams()?.id

  useEffect(() => {
    if (hasAdminAccess) {
      fetchRoles()
    }
  }, [hasAdminAccess])

  useEffect(() => {
    if (id) {
      setIsEdit(true)
      fetchCar(id)
    }
  }, [id])

  const fetchRoles = async () => {
    const response = await apiGet('roles')
    if (response?.data?.roles) {
      setRoles(response.data.roles)
    } else {
      setRoles([])
    }
  }

  const fetchCar = async () => {
    setLoading(true)
    const response = await apiGet(`car/${id}`)

    forEach(Object.entries(response.data), ([key, value]) => {
      if (value === 'null') {
        delete response.data[key]
      }
    })

    const data = response.data

    setCarData(data)
    setLoading(false)
    if (data.carImages) {
      setCarImages(data.carImages)
    }
  }

  return (
    <Layout
      headerRight={
        <div className="mt-4 flex sm:mt-0 sm:ml-4">
          <button
            onClick={() => {
              history.push('/cars')
            }}
            type="button"
            className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500 sm:order-1 sm:ml-3"
          >
            Go Back
          </button>
        </div>
      }
      title={isEdit ? 'Edit Car' : 'Add New Car'}
      breadcrumbs={[
        { name: 'Car List', pathname: '/cars' },
        { name: isEdit ? 'Cars Details' : 'Add New Car', pathname: '/add/company', current: true },
      ]}
    >
      <div className="flex overflow-auto">
        <Switch>
          <Route key={id} path="/add/company" component={() => <CarProfile id={id} isEdit={isEdit} roles={roles} />} />
          <Route
            key={id}
            path="/car/edit/:id/car-profile"
            component={() => (
              <CarProfile readOnly={readOnly} refetch={fetchCar} carData={carData} setCarImages={setCarImages} carImages={carImages} loading={loading} id={id} isEdit={isEdit} />
            )}
          />
        </Switch>
      </div>
    </Layout>
  )
}

export default AddCar
