import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LayoutRouter from 'containers/Router'
import { destroyCookie, parseCookies } from 'nookies'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { useGet } from 'hooks/useApi'
import { setUser } from 'state/redux/actions/user.actions'
import 'styles/index.less'
import useModal from 'hooks/useModal'
import apiGet from 'lib/network/apiGet'
import ChangePassword from 'containers/ChangePassword'
import apiPut from 'lib/network/apiPut'
import { setNotificationCount } from 'state/redux/reducers/notification.reducers'
import { getToken, onMessage } from 'firebase/messaging'
import { messaging } from './firebase'
import NotificationDialog from 'bookingNotificationDialog'

const App = () => {
  const dispatch = useDispatch()
  const [isNotificationOpen, setIsNotificationOpen] = useState(false)
  const [notification, setNotification] = useState({ title: '', body: '', bookingId: '' })
  const [loading, setLoading] = useState(true)
  const [pollingInterval, setPollingInterval] = useState(null)
  const { accessToken } = parseCookies()
  const { response, refetch, isFetched } = useGet('user', {
    retry: false,
    enabled: false,
  })

  const user = useSelector(state => get(state, 'user.user'))
  // eslint-disable-next-line
  const hasAdminAccess = user?.roles?.some(role => role.type === 'admin')
  const [ChangePasswordModal, openChangePasswordModal] = useModal({
    content: <ChangePassword />,
    title: `Change Password`,
    dimensions: {
      height: 'auto',
      width: '500px',
      minHeight: 'auto',
      minWidth: '500px',
    },
  })
  const APP_VAPID_KEY = 'BAJXB5CkdNAkS86uHLRc_l17xJkszHO5vd1O_bdZn4FJ7OuIETOPd9R4iWUL7zXHxbRi-dlnE7DBbrZZlrUiOr8'

  async function requestPermission() {
    const permission = await Notification.requestPermission()
    if (permission === 'granted') {
      try {
        const token = await getToken(messaging, {
          vapidKey: APP_VAPID_KEY,
        })

        if (token) {
          await updateFcmToken(token)
        }
      } catch (err) {
        console.error('An error occurred while retrieving token:', err)
      }
    }
  }

  async function updateFcmToken(fcmToken) {
    const userDeviceData = {
      fcmToken,
    }

    const response = await apiPut('fcm-token', userDeviceData)

    if (!response.success) {
      console.error('Failed to update FCM token:', response.message || 'Unknown error')
    }
  }

  useEffect(() => {
    if (accessToken && !isEmpty(user)) {
      requestPermission()
    }
  }, [user])

  useEffect(() => {
    if (messaging && !isEmpty(user)) {
      const unsubscribe = onMessage(messaging, payload => {
        setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
          bookingId: payload.data?.bookingId,
        })
        setIsNotificationOpen(true)
      })

      return () => unsubscribe()
    }
  }, [user])

  const fetchUnreadNotifications = async () => {
    try {
      const response = await apiGet('unread-notifications')
      dispatch(setNotificationCount(response?.data.unreadCount))
    } catch (error) {
      console.error('Error fetching notifications:', error)
    }
  }

  const startPolling = () => {
    fetchUnreadNotifications()
    const interval = setInterval(fetchUnreadNotifications, 30000)
    setPollingInterval(interval)
  }

  const stopPolling = () => {
    if (pollingInterval) {
      clearInterval(pollingInterval)
      setPollingInterval(null)
    }
  }

  useEffect(() => {
    if (user && hasAdminAccess) {
      startPolling()
    } else {
      stopPolling()
    }

    return () => {
      stopPolling()
    }
  }, [user, hasAdminAccess])

  useEffect(() => {
    if (response && !response?.success) {
      dispatch(setUser({}))
      destroyCookie(null, 'accessToken')
      return
    }

    if (response?.success) {
      dispatch(setUser(response?.data))
      setLoading(false)
    }
  }, [response])

  useEffect(() => {
    if (!isEmpty(accessToken)) {
      try {
        refetch()
      } catch (err) {
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  }, [accessToken])

  return (
    <>
      <LayoutRouter user={user} isUser={!isEmpty(user) && !loading && isFetched} loading={loading} openChangePasswordModal={openChangePasswordModal} />
      <ChangePasswordModal />

      <NotificationDialog notification={notification} isNotificationOpen={isNotificationOpen} setIsNotificationOpen={setIsNotificationOpen} />
    </>
  )
}

export default App
