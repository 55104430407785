import React from 'react'
import { Dialog } from '@headlessui/react'
import Switch from 'components/atoms/Switch'
import Button from 'components/atoms/Button'
import { IoClose } from 'react-icons/io5'
import { find, some } from 'lodash'

const BranchTimingDialog = ({ isOpen, onClose, onSave, timings, onTimingChange, values, setFieldValue }) => {
  const handleSave = () => {
    const timetable = Object.entries(timings).map(([day, value]) => ({
      day,
      active: value.enabled,
      time: value.hours === '24 hrs' ? '00:00:00' : value.hours,
    }))

    const existing24Mode = find(values?.timings, { mode: '24/7' }) || {}

    onSave([
      {
        mode: '24/7',
        isActive: existing24Mode.isActive ?? false,
        timetable: timetable,
      },
    ])

    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="bg-white rounded-lg p-6 w-full max-w-md">
          <div className="flex justify-between items-center mb-6">
            <Dialog.Title className="text-xl font-semibold">TIMINGS</Dialog.Title>
            <button onClick={onClose} className="p-1">
              <IoClose />
            </button>
          </div>

          <div className="mb-6">
            <div className="flex items-center justify-between">
              <h3 className="text-lg">24/7 Mode</h3>
              <Switch
                value={some(values.timings, { mode: '24/7', isActive: true })}
                onChange={e => {
                  setFieldValue('timings', [...values.timings.filter(t => t.mode !== '24/7'), { mode: '24/7', isActive: e }])
                }}
              />
            </div>
            <p className="text-gray-600 text-sm mt-2 mb-4">The office is open for 24 hours all days</p>
          </div>

          <div className="space-y-4">
            {Object.entries(timings).map(([day, value]) => (
              <div key={day} className="flex items-center justify-between py-2">
                <span className="text-gray-700">{day}</span>
                <div className="flex items-center gap-4">
                  <Switch value={value.enabled} onChange={checked => onTimingChange(day, 'enabled', checked)} />
                  <span className="min-w-[60px] text-right">24 hrs</span>
                </div>
              </div>
            ))}
          </div>

          <div className="mt-8 flex justify-end gap-3">
            <Button onClick={onClose} extraClassName="bg-gray-500 hover:bg-gray-600">
              Cancel
            </Button>
            <Button onClick={handleSave}>Save Changes</Button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

export default BranchTimingDialog
