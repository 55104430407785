/* eslint-disable jsx-a11y/no-autofocus */
/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { includes, find, debounce, isFunction } from 'lodash'
import { CheckCircleIcon } from '@heroicons/react/outline'
import EmptyState from 'components/pageComponents/EmptyState'

export default function Selection({
  searchPlaceholder = 'Search',
  showSearch,
  onSearch,
  disabled,
  height = '200px',
  value,
  error,
  onChange,
  onAllSelect,
  multiple,
  onClose,
  list = [],
  title,
  className,
  valueByName,
}) {
  const debounceFn = onSearch ? debounce(onSearch, 500) : null
  const [search, setValue] = useState('')

  const handleChange = event => {
    setValue(event.target.value)
    debounceFn(event.target.value)
  }

  return multiple ? (
    <Listbox value={value} onChange={onChange} on disabled={disabled}>
      {({ open }) => (
        <>
          <div className={classNames(className, !className && 'max-w-lg', 'relative', error && 'error-selection')}>
            <Listbox.Button className="bg-white flex-wrap min-h-[36px] items-center flex relative w-full border border-gray-300 rounded-md pl-1.5 pr-10 py-1 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-dark-500 focus:border-dark-500 sm:text-xs">
              {value?.length > 0 ? (
                value
                  ?.map(v =>
                    // eslint-disable-next-line
                    find(list, function (item) {
                      return item.id === v
                    })
                  )
                  ?.map(val => (
                    <span key={val?.id} className="block bg-gray-200 text-sm px-2 py-0 rounded mr-1 my-1">
                      {val?.name}
                    </span>
                  ))
              ) : (
                <span className="block ml-2 text-sm truncate">{<span className="block truncate">{title}</span>}</span>
              )}
              <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                {onAllSelect && isFunction(onAllSelect) && (
                  <CheckCircleIcon
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      onAllSelect(value?.length === list?.length ? [] : list.map(d => d.id))
                    }}
                    className={classNames('h-5 w-5 cursor-pointer text-gray-400', value?.length === list?.length && 'text-indigo-500')}
                    aria-hidden="true"
                  />
                )}
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options
                style={{ maxHeight: height }}
                className={`absolute z-10 mt-1 w-full  bg-white shadow-lg  rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm`}
              >
                {showSearch ? (
                  <div className="p-2 -mt-1">
                    <input autoFocus className="w-full relative" placeholder={searchPlaceholder} value={search} onChange={handleChange} />
                  </div>
                ) : null}

                {list?.length > 0 ? (
                  list.map(item => (
                    <Listbox.Option
                      disabled={item.disabled}
                      key={item.id}
                      className={({ active }) => classNames(active ? 'text-white bg-dark-600' : 'text-gray-700', 'cursor-default select-none relative py-2 pl-3 pr-9')}
                      value={item}
                    >
                      {({ active }) => {
                        const selected = includes(value, item.id)
                        return (
                          <>
                            <span className={classNames(item.disabled ? 'opacity-50' : '', selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                              {item?.name} <span className="pl-1 font-normal text-gray-400">{item?.description}</span>
                            </span>

                            {selected ? (
                              <span className={classNames(active ? 'text-white' : 'text-dark-600', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )
                      }}
                    </Listbox.Option>
                  ))
                ) : (
                  <EmptyState v2 description="No items in the list" />
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  ) : (
    <Listbox value={value} onChange={onChange} disabled={disabled}>
      {({ open }) => (
        <>
          <div className={classNames(className, !className && 'max-w-lg', 'relative', error && 'error-selection')}>
            <Listbox.Button className="bg-white h-[37px]  w-full border border-gray-300 rounded-md pl-3 pr-10 py-1 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-dark-500 focus:border-dark-500 sm:text-sm">
              <span className="block truncate text-sm">{find(list, valueByName ? { name: value }?.name : { id: value })?.name || title}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options
                style={{ maxHeight: height }}
                className={`absolute z-10 mt-1 w-full bg-white max-h-[200px] shadow-lg  rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm`}
              >
                {showSearch ? (
                  <div className="p-2 -mt-1">
                    <input className="w-full" placeholder={searchPlaceholder} value={search} onChange={handleChange} />
                  </div>
                ) : null}
                {list?.map(item => (
                  <Listbox.Option
                    key={item.id}
                    className={({ active }) => classNames(active ? 'text-white bg-dark-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9')}
                    value={item}
                  >
                    {({ active }) => {
                      const selected = value === item.id
                      return (
                        <>
                          <span className={classNames(selected ? 'font-medium' : 'font-normal', 'block truncate text-sm')}>{item?.name}</span>
                          {selected ? (
                            <span className={classNames(active ? 'text-white' : 'text-dark-600', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )
                    }}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
