import { useEffect, useState, useRef } from 'react'
import Layout from 'containers/Layout'
import moment from 'moment'
import { forEach, get, startCase } from 'lodash'
import apiGet from 'lib/network/apiGet'
import Table from 'components/pageComponents/Table'
import LoadingIcon from 'components/atoms/LoadingIcon'
import EmptyState from 'components/pageComponents/EmptyState'
import Button from 'components/atoms/Button'
import Popover from 'components/molecules/Popover'
import { formateDate } from 'utils'
import notify from 'components/atoms/Notify'
import { IoSearch, IoCalendar } from 'react-icons/io5'

import useModal from 'hooks/useModal'

import apiDelete from 'lib/network/apiDelete'
import { DateRangePicker } from 'react-date-range'
import { Tooltip, Select } from 'antd'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import apiPost from 'lib/network/apiPost'
import useParams from 'hooks/useGetParams'
import { CloudUploadIcon, ChevronDownIcon } from '@heroicons/react/outline'
import PageFooter from 'components/paginationComponent'
import Selection from 'components/molecules/Selection'

const SalesReports = () => {
  const [filter, setFilter] = useState({ workspace: -1 })
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null })

  const [loading, setLoading] = useState(false)
  const [generating, setGenerating] = useState(false)
  const [pagination, setPagination] = useState({})
  const [importManagment, setImportManagment] = useState([])
  const [companyNames, setCompanyNames] = useState([])

  const [uploading, setUploading] = useState(false)
  const [search, setSearch] = useState('')

  const [companies, setCompanies] = useState([])
  const [branches, setBranches] = useState([])
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [selectedBranch, setSelectedBranch] = useState(null)
  const [loadingCompanies, setLoadingCompanies] = useState(false)
  const [loadingBranches, setLoadingBranches] = useState(false)
  const [selectedReportType, setSelectedReportType] = useState('')

  const popover = useRef()

  useEffect(() => {
    fetchCompanies()
  }, [])

  const fetchCompanies = async () => {
    setLoadingCompanies(true)
    const response = await apiGet('companies')
    if (response?.data) {
      const companies = response.data.company.map(company => ({
        id: company._id,
        name: company.name,
      }))
      setCompanyNames(companies)
    } else {
      setCompanyNames([])
    }
    setLoadingCompanies(false)
  }

  const fetchBranches = async companyId => {
    try {
      setLoadingBranches(true)
      const response = await apiGet(`company/${companyId}/branches`)
      if (response?.data) {
        const branchList = response.data.map(branch => ({
          id: branch._id,
          name: branch.name,
        }))
        setBranches(branchList)
      } else {
        setBranches([])
      }
      setLoadingBranches(false)
    } catch (error) {
      notify.error('Failed to fetch branches')
      setBranches([])
      setLoadingBranches(false)
    }
  }

  const handleCompanyChange = value => {
    setSelectedCompany(value)
    setSelectedBranch(null)
    if (value) {
      fetchBranches(value)
    } else {
      setBranches([])
    }
  }

  useEffect(() => {
    fetchImportManagment()
  }, [])

  const fetchImportManagment = async (page = 1) => {
    if (page === 1) {
      setLoading(true)
    }

    const params = {}
    params.pageNumber = page
    params.pageSize = 20

    const response = await apiGet('reports', params)
    setImportManagment(page === 1 ? response.data.report : [...importManagment, ...response.data.report])
    setPagination(response?.data?.pagination || [])

    setLoading(false)
  }

  const handleSelect = range => {
    setDateRange({
      startDate: moment(range.selection.startDate),
      endDate: moment(range.selection.endDate),
    })
  }

  const generateSalesReport = async () => {
    try {
      setGenerating(true)

      const payload = {
        company: selectedCompany,
        branch: selectedBranch,
        type: selectedReportType,
        reportStartDate: dateRange.startDate ? dateRange.startDate.format('YYYY-MM-DD') : null,
        reportEndDate: dateRange.endDate ? dateRange.endDate.format('YYYY-MM-DD') : null,
      }

      const response = await apiPost('transactions/sales-report', payload)

      if (response.success) {
        notify.success('Sales Report', 'Report generated successfully')
        fetchImportManagment()

        if (response.data?.pdfUrl) {
          window.open(response.data.pdfUrl, '_blank')
        }

        setSelectedCompany(null)
        setSelectedBranch(null)
        setSelectedReportType('')
        setDateRange({ startDate: null, endDate: null })
        setBranches([])
      } else {
        notify.error(response?.data?.message)
      }
    } catch (error) {
      notify.error('Failed to generate report')
    } finally {
      setGenerating(false)
    }
  }

  const handleReportTypeChange = value => {
    setSelectedReportType(value?.id)
  }

  const columns = [
    {
      width: '10%',
      name: 'Generated By',
      key: 'type',
      sortable: true,
      rendered: item => {
        return (
          <>
            <div>
              <span className="text-[13px]">{item?.user?.fullName || 'Admin'}</span>
            </div>
          </>
        )
      },
    },
    {
      width: '10%',
      name: 'Report Type',
      key: 'type',
      sortable: true,
      rendered: item => {
        return (
          <>
            <div>
              <span className="text-[13px]">{item?.type}</span>
            </div>
          </>
        )
      },
    },
    {
      width: '10%',
      name: 'Company',
      key: 'company',
      sortable: true,
      rendered: item => {
        return (
          <>
            <div>
              <span className="text-[13px]">{item?.company?.name}</span>
            </div>
          </>
        )
      },
    },
    {
      width: '10%',
      name: 'Branch',
      key: 'branch',
      sortable: true,
      rendered: item => {
        return (
          <>
            <div>
              <span className="text-[13px]">{item?.branch?.name}</span>
            </div>
          </>
        )
      },
    },
    {
      width: '10%',
      name: 'Date Range',
      key: 'range',
      sortable: true,
      rendered: item => {
        const formatDate = dateStr => {
          if (!dateStr) return ''
          const date = new Date(dateStr)
          return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          })
        }

        return (
          <div>
            <span className="text-[13px]">
              {formatDate(item?.startDate)} - {formatDate(item?.endDate)}
            </span>
          </div>
        )
      },
    },

    {
      name: 'Sales Report',
      width: '10%',
      key: 'name',
      sortable: true,
      rendered: item => {
        return (
          <div>
            <a href={item?.signedPdfUrl || item?.pdfUrl} className="text-sm text-normal text-blue-400" target="_blank" rel="noopener noreferrer">
              Download Report
            </a>
          </div>
        )
      },
    },

    {
      width: '5%',
      name: 'Created',
      key: 'created',
      sortable: true,
      rendered: item => {
        const createdTime = get(item, 'createdAt', '')
        const createdAt = createdTime ? moment(createdTime).fromNow() : 'Registered'

        return (
          <div className="text-sm text-gray-500">
            <Tooltip title={createdTime ? formateDate(moment(createdTime)) : 'N/A'}>{createdAt}</Tooltip>
          </div>
        )
      },
    },

    {
      width: '5%',
      name: 'Updated',
      key: 'updated',
      sortable: true,
      rendered: item => {
        const updatedTime = get(item, 'updatedAt', '')
        const updatedAt = updatedTime ? moment(updatedTime).fromNow() : 'Registered'

        return (
          <div className="text-sm text-gray-500">
            <Tooltip title={updatedTime ? formateDate(moment(updatedTime)) : 'N/A'}>{updatedAt}</Tooltip>
          </div>
        )
      },
    },
  ]

  return (
    <Layout
      headerRight={
        <div className="flex items-center justify-end">
          <div className="mt-1 relative rounded-md shadow-sm flex-grow">
            <PageFooter currentPage={pagination.currentPage} totalPages={pagination.totalPages} totalRecords={pagination.totalRecords} />
          </div>
        </div>
      }
      title="Reports"
    >
      <div className="space-y-6 sm:space-y-5 pt-8">
        <div className="grid grid-cols-2 gap-6 ml-4">
          <div>
            <label htmlFor="company" className="block text-sm font-normal text-gray-700 mb-1">
              Company
            </label>
            <Selection title="Select Companies" list={companyNames} className="w-full" onChange={value => handleCompanyChange(value?.id || null)} value={selectedCompany || ''} />
          </div>
          <div>
            <label htmlFor="branch" className="block text-sm font-normal text-gray-700 mb-1">
              Branch
            </label>
            <Selection
              title="Select Branch"
              list={branches}
              onChange={value => setSelectedBranch(value?.id || null)}
              value={selectedBranch || ''}
              className="w-full"
              disabled={!selectedCompany || loadingBranches}
            />
          </div>
          <div>
            <label htmlFor="type" className="block text-sm font-normal text-gray-700 mb-1">
              Report Type
            </label>
            <Selection title="Select Report Type" list={[{ id: 'sales', name: 'Sales Report' }]} className="w-full" onChange={handleReportTypeChange} value={selectedReportType} />
          </div>
          <div>
            <label htmlFor="date-range" className="block text-sm font-medium text-gray-700 mb-1">
              Date Range
            </label>
            <Popover
              custom
              placement="bottom-start"
              ref={popover}
              content={close => (
                <div className="p-4">
                  <DateRangePicker
                    ranges={[
                      {
                        startDate: dateRange.startDate ? dateRange.startDate.toDate() : new Date(),
                        endDate: dateRange.endDate ? dateRange.endDate.toDate() : new Date(),
                        key: 'selection',
                      },
                    ]}
                    onChange={handleSelect}
                  />
                  <div className="flex justify-end mt-4">
                    <button
                      onClick={close}
                      className="w-[100px] py-2 mr-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 outline-none"
                    >
                      Close
                    </button>
                    <button
                      disabled={!dateRange.startDate && !dateRange.endDate}
                      onClick={() => {
                        setDateRange({ startDate: null, endDate: null })
                        close()
                      }}
                      className="w-[100px] py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 outline-none"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              )}
            >
              <button
                id="date-range"
                type="button"
                className="inline-flex w-full h-[38px] justify-between items-center border border-gray-300 shadow-sm text-sm leading-4 font-regular rounded-md text-gray-700 bg-white hover:bg-gray-50 outline-none px-3"
              >
                <div className="flex items-center">
                  <IoCalendar className="h-4 w-4 mr-2 text-gray-500" />
                  <span>
                    {dateRange.startDate && dateRange.endDate ? `${dateRange.startDate.format('MM-DD-YYYY')} to ${dateRange.endDate.format('MM-DD-YYYY')}` : 'Select Date Range'}
                  </span>
                </div>
                <ChevronDownIcon className="h-4 w-4 text-gray-500" />
              </button>
            </Popover>
          </div>
        </div>
        <div className="mt-3">
          <Button onClick={generateSalesReport} disabled={generating}>
            {generating ? (
              <>
                <svg className="animate-spin h-5 w-5 mr-3 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path
                    className="opacity-75"
                    fill="white"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Generating Report...
              </>
            ) : (
              'Generat Report'
            )}
          </Button>
        </div>

        <div className="flex-1 flex items-stretch">
          <main className="flex-1">
            <div className="align-middle inline-block min-w-full">
              <div className="min-w-full">
                {!loading ? (
                  <>
                    {importManagment?.length === 0 ? (
                      <EmptyState v2 description="No Reports found" />
                    ) : (
                      <Table
                        hasMore={pagination?.currentPage !== pagination?.totalPages}
                        fetchData={() => fetchImportManagment(pagination?.currentPage + 1)}
                        sortBy={filter.sortBy}
                        sortOrder={filter.sortOrder}
                        handleSort={(sortBy, sortOrder) => {
                          setImportManagment([])
                        }}
                        data={importManagment}
                        columns={columns}
                      />
                    )}
                  </>
                ) : (
                  <div className="w-full h-[200px] border-0 flex justify-center items-center">
                    <LoadingIcon />
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </Layout>
  )
}

export default SalesReports
