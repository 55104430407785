import moment from 'moment'
import { forwardRef, useEffect, useState } from 'react'
import WithReports from './WithReports'
import { formateDate, startEndTruncate, walletLink } from 'utils'
import { map, join, startCase, truncate } from 'lodash'
import classNames from 'classnames'
import { Tooltip } from 'antd'
import { IoCopyOutline } from 'react-icons/io5'
import notify from 'components/atoms/Notify'
import queryString from 'query-string'
import BookingLogsDrawer from 'pages/booking/bookingLogs'
import TransactionLogsDrawer from 'pages/transaction/transactionLogs'
// eslint-disable-next-line
const TransactionLogs = forwardRef(({ ...props }, ref) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [selectedBooking, setSelectedBooking] = useState(null)
  const [transactionLogs, setTransactionLogs] = useState([])
  const copyText = text => {
    navigator.clipboard.writeText(text).then(() => {
      notify.success('Copied to clipboard')
    })
  }

  const calculateAjarleeAmount = (totalPrice, percentage) => {
    if (!totalPrice || !percentage) return 0
    return (totalPrice * (percentage / 100)).toFixed(2)
  }

  const calculateCompanyAmount = (totalPrice, percentage) => {
    if (!totalPrice || !percentage) return totalPrice || 0
    return (totalPrice - totalPrice * (percentage / 100)).toFixed(2)
  }

  useEffect(() => {
    const qs = queryString.parse(window.location.search)

    if (qs?.bookingId) {
      setSelectedBooking({ _id: qs?.bookingId })
      setIsDrawerOpen(true)
    }
  }, [])
  const columns = [
    {
      name: 'Thawani Invoice ID',
      width: '10%',
      key: 'stripe',
      sortable: false,
      rendered: item => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span>{item?.transactionId}</span>
          {item?.transactionId && (
            <button id="copy-transaction-id" className="hover:text-indigo-500" onClick={() => copyText(item.transactionId)}>
              <IoCopyOutline className="ml-2" />
            </button>
          )}
        </div>
      ),
    },

    {
      name: 'Transaction Status',
      width: '10%',
      key: 'type',
      sortable: false,
      rendered: item => (
        <span
          className={`text-xs font-regular ${
            item.status === 'completed'
              ? 'bg-green-100 text-green-700'
              : item.status === 'canceled'
              ? 'bg-red-100 text-red-700'
              : item.status === 'initiated'
              ? 'bg-yellow-100 text-yellow-800'
              : 'bg-gray-200'
          } px-2 py-1 rounded-md`}
        >
          {startCase(item.status)}
        </span>
      ),
    },
    // {
    //   name: 'Car',
    //   width: '10%',
    //   key: 'stripe',
    //   sortable: false,
    //   rendered: item => {
    //     return (
    // <div className="text-xs text-blue-500 font-mono">
    //   {item?.booking ? (
    //     <a target="_blank" href={`https://${process.env.REACT_APP_ENV === 'production' ? '' : 'goerli.'}etherscan.io/tx/${item?.transactionHash}`} rel="noreferrer">
    //       {item?.booking?.car?.make} {item?.booking?.car?.model} {item?.booking?.rentalCar?.modelYear}
    //     </a>
    //   ) : (
    //     ''
    //   )}
    // </div>
    //     )
    //   },
    // },
    {
      name: 'Booking',
      width: '10%',
      key: 'type',
      sortable: false,
      rendered: item => (
        <div className="text-xs text-blue-500 font-mono">
          {item?.booking ? (
            <Tooltip
              color="white"
              overlayClassName="!min-w-[400px] !w-full"
              title={
                <div className="flex flex-col gap-2 p-2">
                  <div className="flex border rounded p-2 flex-col text-[12px] text-gray-500">
                    <b className="mb-1.5 border-b">Car Details:</b>
                    <div className="text-xs font-mono">
                      {item?.booking ? (
                        <>
                          <div>
                            Name:{' '}
                            <a target="_blank" className="text-blue-500" href={`/car/edit/${item?.booking?.rentalCar?._id}/car-profile`} rel="noreferrer">
                              {item?.booking?.car?.make} {item?.booking?.car?.model} {item?.booking?.rentalCar?.modelYear}
                            </a>
                          </div>
                          <div className="mt-1">Status: {item?.booking?.rentalCar?.status}</div>
                          <div className="mt-1">
                            Company:{' '}
                            <a target="_blank" className="text-blue-500" href={`/company/edit/${item?.booking?.rentalCar?.company?._id}/company-profile`} rel="noreferrer">
                              {item?.booking?.rentalCar?.company?.name}
                            </a>
                          </div>
                          <div className="mt-1">Branch: {item?.booking?.branch?.name}</div>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className="flex border rounded p-2 flex-col text-[12px] text-gray-500">
                    <b className="mb-1.5 border-b">Booking Details:</b>
                    <div className="text-xs font-mono">
                      {item?.booking ? (
                        <>
                          <div>
                            Status:{' '}
                            <span
                              className={classNames(`text-xs font-medium p-0.5 rounded-md`, {
                                'bg-gray-200 text-black': item?.booking?.status === 'pending',
                                'text-white bg-dark-600': item?.booking?.status === 'pending',
                                'bg-rose-400 !text-white': item?.booking?.status === 'cancelled',
                                'bg-green-500 !text-white': item?.booking?.status === 'completed' || item?.booking?.status === 'confirmed',
                              })}
                            >
                              {startCase(item?.booking?.status)}
                            </span>
                          </div>
                          <div className="mt-1">
                            Start Date: <span className="text-black">{formateDate(moment(item?.booking?.rentalStartDate))}</span>
                          </div>
                          <div className="mt-1">
                            End Date: <span className="text-black">{formateDate(moment(item?.booking?.rentalEndDate))}</span>
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  {item?.booking?.delivery && (
                    <div className="flex border rounded p-2 flex-col text-[12px] text-gray-500">
                      <b className="mb-1.5 border-b">Delivery Details:</b>
                      <div className="text-xs font-mono">
                        <div className="mt-1">Type: {item?.booking?.delivery?.type}</div>
                        <div className="mt-1">Pickup Address: {item?.booking?.delivery?.pickupLocationAddress || item?.booking?.address || 'Office'}</div>
                        <div className="mt-1">DropOff Address: {item?.booking?.delivery?.dropOffLocationAddress || item?.booking?.address || 'Office'}</div>
                      </div>
                    </div>
                  )}
                </div>
              }
            >
              <a target="_blank" href={`/booking/${item?.booking?._id}`} rel="noreferrer">
                View booking
              </a>
            </Tooltip>
          ) : (
            ''
          )}
        </div>
      ),
    },
    {
      name: 'Invoice',
      width: '10%',
      key: 'name',
      sortable: true,
      rendered: item => {
        if (item?.booking?.invoiceUrl) {
          return (
            <div>
              <a href={item?.booking?.invoiceUrl} className="text-sm text-normal text-blue-400">
                <p>Download Invoice</p>
              </a>
            </div>
          )
        }
      },
    },

    // {
    //   name: 'Booking Status',
    //   width: '10%',
    //   key: 'type',
    //   sortable: false,
    //   rendered: item => (
    //     <span
    //       className={classNames(`text-xs font-medium p-1 rounded-md`, {
    //         'bg-gray-200 text-black': item?.booking?.status === 'pending',
    //         'text-white bg-dark-600': item?.booking?.status === 'pending',
    //         'bg-rose-400 !text-white': item?.booking?.status === 'cancelled',
    //         'bg-green-500 !text-white': item?.booking?.status === 'completed' || item?.booking?.status === 'confirmed',
    //       })}
    //     >
    //       {startCase(item?.booking?.status)}
    //     </span>
    //   ),
    // },
    {
      name: 'User',
      width: '10%',
      key: 'type',
      sortable: false,
      rendered: item => (
        <div className="text-xs text-blue-500 font-mono">
          {item?.user ? (
            <Tooltip
              color="white"
              overlayClassName="!min-w-[400px] !w-full"
              title={
                <div className="flex flex-col gap-2 p-2">
                  <div className="flex border rounded p-2 flex-col text-[12px] text-gray-500">
                    <b className="mb-1.5 border-b">User Details:</b>
                    <div className="text-xs font-mono">
                      {item?.user ? (
                        <>
                          <div>
                            Name:{' '}
                            <a target="_blank" className="text-blue-500" href={`/users/edit/${item?.user?._id}`} rel="noreferrer">
                              {item?.user?.fullName}
                            </a>
                          </div>
                          <div className="mt-1">
                            Email: <span className="text-black">{item?.user?.email}</span>{' '}
                          </div>
                          <div className="mt-1">
                            Phone: <span className="text-black">{item?.user?.phone}</span>{' '}
                          </div>
                          <div className="mt-1">
                            Nationality: <span className="text-black">{item?.user?.nationality}</span>{' '}
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              }
            >
              <a target="_blank" className="text-blue-500" href={`/users/edit/${item?.user?._id}`} rel="noreferrer">
                {item?.user?.fullName || item?.user?.email || item?.user?.phone}
              </a>
            </Tooltip>
          ) : (
            ''
          )}
        </div>
      ),
    },
    {
      name: 'Amount',
      width: '10%',
      key: 'amount',
      sortable: false,
      rendered: item => (
        <Tooltip
          color="white"
          overlayClassName="!min-w-[270px] !w-full"
          title={
            <div className="flex flex-col gap-2">
              <div className="flex border rounded p-2 flex-col text-[12px] text-gray-500">
                <b className="mb-1.5 border-b">Payment Breakdown:</b>
                <div className="text-xs font-mono">
                  {item?.booking ? (
                    <>
                      <div>
                        <div className="mt-1 grid grid-cols-2">
                          Car Price: <span className="text-black">{item?.booking?.priceWithoutTax || 0} OMR</span>
                        </div>
                        {item?.booking?.priceWithoutTax !== item?.booking?.rentalCarDiscountPrice && (
                          <div className="mt-1 grid grid-cols-2">
                            Discounted Price: <span className="text-black">{item?.booking?.rentalCarDiscountPrice || 0} OMR</span>
                          </div>
                        )}
                      </div>
                      <div className="mt-1 grid grid-cols-2">
                        Deposit Amount: <span className="text-black">{item?.booking?.depositAmount || 0} OMR</span>{' '}
                      </div>
                      <div className="mt-1 grid grid-cols-2">
                        Delivery Fee <span className="text-black">{item?.booking?.delivery?.deliveryCost || 0} OMR</span>{' '}
                      </div>
                      <div className="mt-1 grid grid-cols-2">
                        Insurance Fee: <span className="text-black">{item?.booking?.insuranceFee || 0} OMR</span>{' '}
                      </div>
                      <div className="mt-1 mb-2 grid grid-cols-2">
                        Vat Tax: <span className="text-black">{item?.booking?.vatTax || 0} OMR</span>{' '}
                      </div>
                      <div className="mt-1 grid border-t pt-1 grid-cols-2">
                        Total Amount: <span className="text-black">{item?.booking?.totalPrice} OMR</span>{' '}
                      </div>
                      <div className="mt-1 grid  pt-1 grid-cols-2">
                        Ajarlee {item?.booking?.ajarleePercentage}%:{' '}
                        <span className="text-black">{calculateAjarleeAmount(item?.booking?.totalPrice, item?.booking?.ajarleePercentage)} OMR</span>
                      </div>
                      <div className="mt-1 grid border-t pt-1 grid-cols-2">
                        Company Amount: <span className="text-black">{calculateCompanyAmount(item?.booking?.totalPrice, item?.booking?.ajarleePercentage)} OMR</span>
                      </div>
                    </>
                  ) : (
                    'No payment details available'
                  )}
                </div>
              </div>
            </div>
          }
        >
          <span className={`text-xs font-medium text-gray-500 py-1 rounded-md`}>{item.amount} OMR</span>
        </Tooltip>
      ),
    },
    {
      name: 'Payment Status',
      width: '10%',
      key: 'type',
      sortable: false,
      rendered: item => (
        <span
          className={`text-xs font-regular ${
            item.paymentStatus === 'paid'
              ? 'bg-green-100 text-green-700'
              : item.paymentStatus === 'failure'
              ? 'bg-red-100 text-red-700'
              : item.paymentStatus === 'unpaid'
              ? 'bg-yellow-100 text-yellow-800'
              : 'bg-gray-200'
          } px-2 py-1 rounded-md`}
        >
          {startCase(item.paymentStatus)}
        </span>
      ),
    },
    {
      name: 'TXT Initiation',
      width: '5%',
      key: 'transactionDate',
      align: 'right',
      sortable: true,
      rendered: item => (
        <div className="text-sm text-gray-500">
          <Tooltip title={moment.parseZone(item.transactionDate).format('MMMM Do YYYY, h:mm A')}>{moment.parseZone(item.transactionDate).fromNow()}</Tooltip>
        </div>
      ),
    },
    {
      name: 'TXT Completion',
      width: '5%',
      key: 'completedAt',
      align: 'right',
      sortable: true,
      rendered: item =>
        item.completedAt && (
          <div className="text-sm text-gray-500">
            <Tooltip title={moment.parseZone(item.completedAt).format('MMMM Do YYYY, h:mm A')}>{moment.parseZone(item.completedAt).fromNow()}</Tooltip>
          </div>
        ),
    },
    {
      name: 'TXT Cancellation',
      width: '5%',
      key: 'canceledAt',
      align: 'right',
      sortable: true,
      rendered: item =>
        item.canceledAt && (
          <div className="text-sm text-gray-500 flex gap-1">
            <Tooltip title={moment.parseZone(item.canceledAt).format('MMMM Do YYYY, h:mm A')}>{moment.parseZone(item.canceledAt).fromNow()}</Tooltip>
          </div>
        ),
    },
    {
      name: 'Action',
      width: '5%',
      key: 'completedAt',
      align: 'left',
      rendered: item => (
        <>
          {item.transactionLogs && item.transactionLogs.length > 0 && (
            <div className="flex space-x-4">
              <button
                onClick={() => {
                  setTransactionLogs(item.transactionLogs)
                  setSelectedBooking(item)
                  setIsDrawerOpen(true)
                }}
                className="w-full flex justify-center py-0 px-1 border border-transparent rounded-md shadow-sm text-sm text-normal text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
                type="submit"
              >
                View logs
              </button>
            </div>
          )}
        </>
      ),
    },
  ]
  const closeDrawer = () => {
    setIsDrawerOpen(false)
    setSelectedBooking(null)
  }

  return (
    <>
      <WithReports {...props} apiURL="transactions" defaultFileName="Transaction" forwardedRef={ref} columns={columns} activeFilterName="past_7_days" />
      <TransactionLogsDrawer isDrawerOpen={isDrawerOpen} closeDrawer={closeDrawer} selectedBooking={selectedBooking} isTransactionDrawer transactionLogs={transactionLogs} />
    </>
  )
})

export default TransactionLogs
