const timezones = [
  { id: 'Pacific/Midway', name: '(UTC-11:00) Midway Island, Samoa' },
  { id: 'Pacific/Niue', name: '(UTC-11:00) Niue' },
  { id: 'Pacific/Honolulu', name: '(UTC-10:00) Hawaii' },
  { id: 'Pacific/Rarotonga', name: '(UTC-10:00) Cook Islands' },
  { id: 'Pacific/Tahiti', name: '(UTC-10:00) Tahiti' },
  { id: 'America/Anchorage', name: '(UTC-09:00) Alaska' },
  { id: 'Pacific/Gambier', name: '(UTC-09:00) Gambier Islands' },
  { id: 'America/Los_Angeles', name: '(UTC-08:00) Pacific Time (US & Canada)' },
  { id: 'America/Tijuana', name: '(UTC-08:00) Tijuana, Baja California' },
  { id: 'America/Vancouver', name: '(UTC-08:00) Vancouver' },
  { id: 'America/Denver', name: '(UTC-07:00) Mountain Time (US & Canada)' },
  { id: 'America/Phoenix', name: '(UTC-07:00) Arizona' },
  { id: 'America/Mazatlan', name: '(UTC-07:00) Mazatlan' },
  { id: 'America/Chicago', name: '(UTC-06:00) Central Time (US & Canada)' },
  { id: 'America/Mexico_City', name: '(UTC-06:00) Mexico City' },
  { id: 'America/Regina', name: '(UTC-06:00) Saskatchewan' },
  { id: 'America/New_York', name: '(UTC-05:00) Eastern Time (US & Canada)' },
  { id: 'America/Bogota', name: '(UTC-05:00) Bogota' },
  { id: 'America/Lima', name: '(UTC-05:00) Lima' },
  { id: 'America/Halifax', name: '(UTC-04:00) Atlantic Time (Canada)' },
  { id: 'America/Caracas', name: '(UTC-04:00) Caracas' },
  { id: 'America/Santiago', name: '(UTC-04:00) Santiago' },
  { id: 'America/St_Johns', name: '(UTC-03:30) Newfoundland' },
  { id: 'America/Argentina/Buenos_Aires', name: '(UTC-03:00) Buenos Aires' },
  { id: 'America/Sao_Paulo', name: '(UTC-03:00) Sao Paulo' },
  { id: 'America/Godthab', name: '(UTC-03:00) Greenland' },
  { id: 'Atlantic/South_Georgia', name: '(UTC-02:00) Mid-Atlantic' },
  { id: 'Atlantic/Azores', name: '(UTC-01:00) Azores' },
  { id: 'Atlantic/Cape_Verde', name: '(UTC-01:00) Cape Verde Islands' },
  { id: 'UTC', name: '(UTC+00:00) Universal Time UTC' },
  { id: 'Europe/London', name: '(UTC+00:00) London, Dublin, Edinburgh' },
  { id: 'Europe/Lisbon', name: '(UTC+00:00) Lisbon' },
  { id: 'Africa/Casablanca', name: '(UTC+00:00) Casablanca' },
  { id: 'Africa/Monrovia', name: '(UTC+00:00) Monrovia' },
  { id: 'Europe/Paris', name: '(UTC+01:00) Paris' },
  { id: 'Europe/Amsterdam', name: '(UTC+01:00) Amsterdam' },
  { id: 'Europe/Berlin', name: '(UTC+01:00) Berlin' },
  { id: 'Europe/Rome', name: '(UTC+01:00) Rome' },
  { id: 'Europe/Stockholm', name: '(UTC+01:00) Stockholm' },
  { id: 'Europe/Vienna', name: '(UTC+01:00) Vienna' },
  { id: 'Europe/Madrid', name: '(UTC+01:00) Madrid' },
  { id: 'Africa/Lagos', name: '(UTC+01:00) West Central Africa' },
  { id: 'Europe/Helsinki', name: '(UTC+02:00) Helsinki' },
  { id: 'Europe/Kiev', name: '(UTC+02:00) Kiev' },
  { id: 'Europe/Riga', name: '(UTC+02:00) Riga' },
  { id: 'Europe/Athens', name: '(UTC+02:00) Athens' },
  { id: 'Europe/Bucharest', name: '(UTC+02:00) Bucharest' },
  { id: 'Africa/Cairo', name: '(UTC+02:00) Cairo' },
  { id: 'Africa/Johannesburg', name: '(UTC+02:00) Johannesburg' },
  { id: 'Asia/Jerusalem', name: '(UTC+02:00) Jerusalem' },
  { id: 'Europe/Moscow', name: '(UTC+03:00) Moscow' },
  { id: 'Asia/Baghdad', name: '(UTC+03:00) Baghdad' },
  { id: 'Asia/Riyadh', name: '(UTC+03:00) Riyadh' },
  { id: 'Asia/Kuwait', name: '(UTC+03:00) Kuwait' },
  { id: 'Africa/Nairobi', name: '(UTC+03:00) Nairobi' },
  { id: 'Asia/Tehran', name: '(UTC+03:30) Tehran' },
  { id: 'Asia/Dubai', name: '(UTC+04:00) Dubai' },
  { id: 'Asia/Muscat', name: '(UTC+04:00) Muscat' },
  { id: 'Asia/Baku', name: '(UTC+04:00) Baku' },
  { id: 'Asia/Yerevan', name: '(UTC+04:00) Yerevan' },
  { id: 'Asia/Tbilisi', name: '(UTC+04:00) Tbilisi' },
  { id: 'Indian/Mauritius', name: '(UTC+04:00) Port Louis' },
  { id: 'Asia/Kabul', name: '(UTC+04:30) Kabul' },
  { id: 'Asia/Karachi', name: '(UTC+05:00) Karachi' },
  { id: 'Asia/Tashkent', name: '(UTC+05:00) Tashkent' },
  { id: 'Asia/Yekaterinburg', name: '(UTC+05:00) Yekaterinburg' },
  { id: 'Asia/Kolkata', name: '(UTC+05:30) Kolkata, Mumbai, New Delhi' },
  { id: 'Asia/Colombo', name: '(UTC+05:30) Colombo' },
  { id: 'Asia/Kathmandu', name: '(UTC+05:45) Kathmandu' },
  { id: 'Asia/Dhaka', name: '(UTC+06:00) Dhaka' },
  { id: 'Asia/Almaty', name: '(UTC+06:00) Almaty' },
  { id: 'Asia/Novosibirsk', name: '(UTC+06:00) Novosibirsk' },
  { id: 'Asia/Yangon', name: '(UTC+06:30) Yangon' },
  { id: 'Asia/Bangkok', name: '(UTC+07:00) Bangkok' },
  { id: 'Asia/Jakarta', name: '(UTC+07:00) Jakarta' },
  { id: 'Asia/Krasnoyarsk', name: '(UTC+07:00) Krasnoyarsk' },
  { id: 'Asia/Ho_Chi_Minh', name: '(UTC+07:00) Ho Chi Minh City' },
  { id: 'Asia/Shanghai', name: '(UTC+08:00) Shanghai' },
  { id: 'Asia/Hong_Kong', name: '(UTC+08:00) Hong Kong' },
  { id: 'Asia/Singapore', name: '(UTC+08:00) Singapore' },
  { id: 'Asia/Taipei', name: '(UTC+08:00) Taipei' },
  { id: 'Asia/Perth', name: '(UTC+08:00) Perth' },
  { id: 'Asia/Manila', name: '(UTC+08:00) Manila' },
  { id: 'Asia/Makassar', name: '(UTC+08:00) Makassar' },
  { id: 'Asia/Seoul', name: '(UTC+09:00) Seoul' },
  { id: 'Asia/Tokyo', name: '(UTC+09:00) Tokyo' },
  { id: 'Asia/Yakutsk', name: '(UTC+09:00) Yakutsk' },
  { id: 'Australia/Darwin', name: '(UTC+09:30) Darwin' },
  { id: 'Australia/Adelaide', name: '(UTC+09:30) Adelaide' },
  { id: 'Australia/Sydney', name: '(UTC+10:00) Sydney' },
  { id: 'Australia/Melbourne', name: '(UTC+10:00) Melbourne' },
  { id: 'Australia/Brisbane', name: '(UTC+10:00) Brisbane' },
  { id: 'Asia/Vladivostok', name: '(UTC+10:00) Vladivostok' },
  { id: 'Pacific/Guam', name: '(UTC+10:00) Guam' },
  { id: 'Pacific/Port_Moresby', name: '(UTC+10:00) Port Moresby' },
  { id: 'Pacific/Guadalcanal', name: '(UTC+11:00) Solomon Islands' },
  { id: 'Asia/Magadan', name: '(UTC+11:00) Magadan' },
  { id: 'Pacific/Norfolk', name: '(UTC+11:00) Norfolk Island' },
  { id: 'Pacific/Auckland', name: '(UTC+12:00) Auckland' },
  { id: 'Pacific/Fiji', name: '(UTC+12:00) Fiji' },
  { id: 'Asia/Kamchatka', name: '(UTC+12:00) Kamchatka' },
  { id: 'Pacific/Tongatapu', name: "(UTC+13:00) Nuku'alofa" },
  { id: 'Pacific/Apia', name: '(UTC+13:00) Samoa' },
  { id: 'Pacific/Kiritimati', name: '(UTC+14:00) Kiritimati' },
]

export default timezones
