import { useEffect, useState } from 'react'
import Layout from 'containers/Layout'
import { Route, Switch, useHistory, useParams } from 'react-router-dom'
import CompanyProfile from 'pages/company/CompanyProfile'
import apiGet from 'lib/network/apiGet'
import { forEach } from 'lodash'
import BranchProfile from './BranchProfile'

const AddBranch = ({ readOnly, currentUserId }) => {
  const history = useHistory()

  const [isEdit, setIsEdit] = useState(null)
  const [branchData, setBranchData] = useState({})

  const [loading, setLoading] = useState(false)

  const id = useParams()?.id

  useEffect(() => {
    if (id) {
      setIsEdit(true)
      fetchBranch(id)
    }
  }, [id])

  const fetchBranch = async () => {
    setLoading(true)
    const response = await apiGet(`branch/${id}`)

    forEach(Object.entries(response.data), ([key, value]) => {
      if (value === 'null') {
        delete response.data[key]
      }
    })

    const data = response.data

    setBranchData(data)
    setLoading(false)
  }

  return (
    <Layout
      headerRight={
        <div className="mt-4 flex sm:mt-0 sm:ml-4">
          <button
            onClick={() => {
              history.push('/branches')
            }}
            type="button"
            className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500 sm:order-1 sm:ml-3"
          >
            Go Back
          </button>
        </div>
      }
      title={isEdit ? 'Edit Branch' : 'Add New Branch'}
      breadcrumbs={[
        { name: 'Branch List', pathname: '/branches' },
        { name: isEdit ? 'User Details' : 'Add New Users', pathname: '/add/branch', current: true },
      ]}
    >
      <div className="flex overflow-auto">
        <Switch>
          <Route key={id} path="/add/branch" component={() => <BranchProfile id={id} isEdit={isEdit} />} />
          <Route
            key={id}
            path="/branch/:id/branch-profile"
            component={() => <BranchProfile readOnly={readOnly} refetch={fetchBranch} branchData={branchData} loading={loading} id={id} isEdit={isEdit} />}
          />
        </Switch>
      </div>
    </Layout>
  )
}

export default AddBranch
