import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import LoadingIcon from 'components/atoms/LoadingIcon'
import { Formik, Form, Field, FieldArray } from 'formik'
import { CameraIcon } from '@heroicons/react/solid'
import { size, get, xor, isArray, filter, reject, some, find, forEach, split } from 'lodash'

import Input from 'components/atoms/Input'
import notify from 'components/atoms/Notify'
import Selection from 'components/molecules/Selection'
import apiPost from 'lib/network/apiPost'
import apiPut from 'lib/network/apiPut'
import apiDelete from 'lib/network/apiDelete'
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/outline'
import TextArea from 'antd/lib/input/TextArea'
import classNames from 'classnames'
import Switch from 'components/atoms/Switch'
import apiGet from 'lib/network/apiGet'
import BranchTimingDialog from './branchTimingDialog/hoursDialog'
import ShiftModeDialog from './branchTimingDialog/shiftDialog'
import NormalModeDialog from './branchTimingDialog/normalDialoh'
import timezones from './timeZone'
import { DashboardCards } from './carStats'

const tabs = [
  { id: 1, name: 'Branch Detail', href: '#', current: false },
  { id: 2, name: 'Branch Phones', href: '#', current: false },
  { id: 3, name: 'Delivery Details', href: '#', current: false },
  { id: 4, name: 'Branch Timings', href: '#', current: false },
  { id: 5, name: 'Car Stats', href: '#', current: false },
]

const BranchProfile = ({ readOnly, loading, refetch, branchData, isEdit, id, roles }) => {
  const [previews, setPreviews] = useState({})
  const [files, setFiles] = useState({})
  const [profile, setProfile] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [params, setParams] = useState({ t: tabs[0].id })
  const [companyNames, setCompanyNames] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const userFromStore = useSelector(state => get(state, 'user.user'))
  // eslint-disable-next-line
  const isCompanyRole = userFromStore?.roles?.some(role => role.type === 'company')
  // eslint-disable-next-line
  const hasAdminAccess = userFromStore?.roles?.some(role => role.type === 'admin')

  const [twentyFourSevenTimings, setTwentyFourSevenTimings] = useState(() => {
    const defaultTimings = {
      Sunday: { enabled: true, hours: '24 hrs' },
      Monday: { enabled: true, hours: '24 hrs' },
      Tuesday: { enabled: true, hours: '24 hrs' },
      Wednesday: { enabled: true, hours: '24 hrs' },
      Thursday: { enabled: true, hours: '24 hrs' },
      Friday: { enabled: true, hours: '24 hrs' },
      Saturday: { enabled: true, hours: '24 hrs' },
    }

    const twentyFourSevenMode = find(branchData?.timings, { mode: '24/7' })

    if (twentyFourSevenMode?.timetable) {
      const formattedTimings = { ...defaultTimings }

      forEach(twentyFourSevenMode?.timetable, ({ day, active, time }) => {
        formattedTimings[day] = {
          enabled: active,
          hours: time === '00:00:00' ? '24 hrs' : time,
        }
      })

      return formattedTimings
    }

    return defaultTimings
  })

  const handleTimingChange = (day, field, value) => {
    setTwentyFourSevenTimings(prev => ({
      ...prev,
      [day]: { ...prev[day], [field]: value },
    }))
  }

  const [shiftModeTimings, setShiftModeTimings] = useState(() => {
    const defaultTimings = {
      Sunday: {
        enabled: false,
        shifts: [
          { start: '00:00', end: '00:00' },
          { start: '00:00', end: '00:00' },
        ],
      },
      Monday: {
        enabled: false,
        shifts: [
          { start: '00:00', end: '00:00' },
          { start: '00:00', end: '00:00' },
        ],
      },
      Tuesday: {
        enabled: false,
        shifts: [
          { start: '00:00', end: '00:00' },
          { start: '00:00', end: '00:00' },
        ],
      },
      Wednesday: {
        enabled: false,
        shifts: [
          { start: '00:00', end: '00:00' },
          { start: '00:00', end: '00:00' },
        ],
      },
      Thursday: {
        enabled: false,
        shifts: [
          { start: '00:00', end: '00:00' },
          { start: '00:00', end: '00:00' },
        ],
      },
      Friday: {
        enabled: false,
        shifts: [
          { start: '00:00', end: '00:00' },
          { start: '00:00', end: '00:00' },
        ],
      },
      Saturday: {
        enabled: false,
        shifts: [
          { start: '00:00', end: '00:00' },
          { start: '00:00', end: '00:00' },
        ],
      },
    }

    if (branchData?.timings) {
      const shiftMode = find(branchData?.timings, { mode: 'shift' })

      if (shiftMode?.timetable) {
        const formattedTimings = { ...defaultTimings }

        forEach(shiftMode?.timetable, daySchedule => {
          formattedTimings[daySchedule.day] = {
            enabled: daySchedule.active,
            shifts: [
              {
                start: daySchedule.shift1?.startTime ? daySchedule.shift1.startTime.slice(0, -3) : '00:00',
                end: daySchedule.shift1?.endTime ? daySchedule.shift1.endTime.slice(0, -3) : '00:00',
              },
              {
                start: daySchedule.shift2?.startTime ? daySchedule.shift2.startTime.slice(0, -3) : '00:00',
                end: daySchedule.shift2?.endTime ? daySchedule.shift2.endTime.slice(0, -3) : '00:00',
              },
            ],
          }
        })

        return formattedTimings
      }
    }

    return defaultTimings
  })

  const handleShiftChange = (day, shiftIndex, field, value) => {
    setShiftModeTimings(prev => ({
      ...prev,
      [day]: {
        ...prev[day],
        shifts: prev[day].shifts.map((shift, index) => (index === shiftIndex ? { ...shift, [field]: value } : shift)),
      },
    }))
  }

  const handleShiftSwitchChange = (day, checked) => {
    setShiftModeTimings(prev => ({
      ...prev,
      [day]: { ...prev[day], enabled: checked },
    }))
  }

  const [normalModeTimings, setNormalModeTimings] = useState(() => {
    const defaultTimings = {
      Sunday: { enabled: true, startTime: '09:33', endTime: '22:33' },
      Monday: { enabled: true, startTime: '09:33', endTime: '22:33' },
      Tuesday: { enabled: true, startTime: '09:33', endTime: '22:33' },
      Wednesday: { enabled: true, startTime: '09:33', endTime: '22:33' },
      Thursday: { enabled: true, startTime: '09:33', endTime: '22:33' },
      Friday: { enabled: true, startTime: '09:33', endTime: '22:33' },
      Saturday: { enabled: true, startTime: '09:33', endTime: '22:33' },
    }

    if (branchData?.timings) {
      const normalMode = find(branchData?.timings, { mode: 'normal' })

      if (normalMode?.timetable) {
        const formattedTimings = {}

        forEach(normalMode?.timetable, ({ day, active, startTime, endTime }) => {
          const formatTime = timeString => {
            if (!timeString) return '00:00'

            const [time] = split(timeString, ':00')
            return time
          }

          formattedTimings[day] = {
            enabled: active,
            startTime: formatTime(startTime),
            endTime: formatTime(endTime),
          }
        })

        return formattedTimings
      }
    }

    return defaultTimings
  })

  const handleNormalModeChange = (day, field, value) => {
    setNormalModeTimings(prev => ({
      ...prev,
      [day]: { ...prev[day], [field]: value },
    }))
  }

  const history = useHistory()

  useEffect(() => {
    setPreviews({
      profile: branchData?.companyProfile?.profile,
      logoUrl: branchData?.logoUrl,
      cover: branchData?.companyProfile?.cover,
    })
    setProfile({
      username: branchData?.companyProfile?.username,
      ...branchData?.companyProfile?.userKyc,
      twoFactorAuth: branchData?.companyProfile?.twoFactorAuth,
    })
  }, [branchData])

  const setCurrentTab = currentTab => setParams({ t: parseInt(currentTab, 10) })

  const addUpdateBranch = async (values, { setSubmitting }) => {
    const data = {
      name: values.name,
      company: values.company,
      governorate: values.governorate,
      deliveryCost: values.deliveryCost,
      deliveryKmRange: values.deliveryKmRange,
      lat: values.lat,
      lon: values.lon,
      email: values.email,
      address: values.address,
      isDeliveryAvailable: values?.isDeliveryAvailable,
      timings: values.timings,
      timeZone: values.timeZone,
    }

    delete data.id
    delete data.logoUrl

    setIsSubmitting(true)
    const formData = new FormData()

    if (files.cover) formData.append('cover', files.cover)
    if (files.profile) formData.append(`profile`, files.profile)
    if (files.logoUrl) formData.append(`logoUrl`, files.logoUrl)
    // eslint-disable-next-line

    if (isArray(values.officeInstructions)) {
      // eslint-disable-next-line
      values.officeInstructions.forEach((instruction, index) => {
        formData.append(`officeInstructions[${index}]`, JSON.stringify(instruction))
      })
    }
    if (isArray(values.phones)) {
      // eslint-disable-next-line
      values.phones.forEach((phone, index) => {
        formData.append(`phones[${index}]`, JSON.stringify(phone))
      })
    }

    // eslint-disable-next-line
    Object.keys(data).forEach(key => {
      if (data[key] === 'null' || data[key] === undefined || data[key] === null) delete data[key]
      else {
        // eslint-disable-next-line
        if (typeof data[key] === 'object' && !Array.isArray(data[key])) {
          formData.append(key, JSON.stringify(data[key]))
        } else {
          formData.append(key, data[key])
        }
      }
    })

    // eslint-disable-next-line
    Object.keys(profile).forEach(key => {
      if (profile[key] === 'null' || profile[key] === undefined || profile[key] === null) delete profile[key]
      // eslint-disable-next-line
      if (typeof profile[key] === 'string' || typeof profile[key] === 'number' || typeof profile[key] === 'boolean' || Array.isArray(profile[key])) {
        formData.append(key, profile[key])
      }
    })

    try {
      if (isEdit) {
        const response = await apiPut(`branch/${id}`, values)
        if (response.success) {
          notify.success('Company updated successfully!')
          history.push('/branches')
        }
      } else {
        const response = await apiPost('add/branch', values)
        console.log('values', values)
        if (response.success) {
          notify.success('Branch added successfully!')
          history.push('/branches')
        }
      }
    } catch (error) {
      notify.error('Failed to save company')
    } finally {
      setIsSubmitting(false)
      setSubmitting(false)
    }
  }

  useEffect(() => {
    if (hasAdminAccess) {
      fetchCompanies()
    }
  }, [hasAdminAccess])
  const fetchCompanies = async () => {
    const response = await apiGet('companies')

    if (response?.data) {
      const companies = response.data.company.map(company => ({
        id: company._id,
        name: company.name,
      }))
      setCompanyNames(companies)
    } else {
      setCompanyNames([])
    }
  }

  const deleteBranch = () => {
    notify.deleteConfirm({
      title: 'Delete company',
      message: 'Are you sure you want to delete this branch? It will delete all the related data',
      onConfirm: async () => {
        const response = await apiDelete(`branch/${id}`)
        if (response?.success) {
          history.push('/branches')
          notify.success('Branch deleted successfully')
        }
      },
    })
  }

  const showPreview = (key, file) => {
    const preview = URL.createObjectURL(file)
    setPreviews({ ...previews, [key]: preview })
  }

  const initialValues = isEdit
    ? {
        name: branchData?.name || '',
        company: branchData?.company || '',
        governorate: branchData?.governorate || '',
        deliveryCost: branchData?.deliveryCost || '',
        deliveryKmRange: branchData?.deliveryKmRange || '',
        lat: branchData?.lat || '',
        lon: branchData?.lon || '',
        email: branchData?.email || '',
        address: branchData?.address || '',
        isDeliveryAvailable: branchData?.isDeliveryAvailable || false,
        phones: branchData?.phones || [{ title: '', phone: '' }],
        timings: branchData?.timings || [],
        timeZone: branchData?.timeZone,
      }
    : {
        name: '',
        company: '',
        governorate: '',
        deliveryCost: '',
        deliveryKmRange: '',
        lat: '',
        lon: '',
        email: '',
        address: '',
        isDeliveryAvailable: false,
        phones: [{ title: '', phone: '' }],
        timings: [],
        timeZone: '',
      }

  const visibleTabs = isEdit ? tabs : tabs.filter(tab => tab.id !== 4)
  return (
    <Formik initialValues={initialValues} onSubmit={addUpdateBranch} enableReinitialize>
      {({ values, setFieldValue, isValid }) =>
        !loading ? (
          <div className="w-full mb-[60px] flex flex-col">
            <div className="bg-white flex w-full">
              <Form className="space-y-8 py-8 w-full mx-auto px-8">
                <div>
                  <div className="border-b border-gray-200 pb-5 sm:pb-0">
                    <h3 className="text-xl font-medium leading-6 text-gray-900">Branch Overview {readOnly ? '(Read-only)' : ''}</h3>
                    <div className="mt-3 sm:mt-4">
                      <div className="hidden sm:block">
                        <nav className="-mb-px flex space-x-8">
                          {visibleTabs.map(tab => (
                            <span
                              key={tab.name}
                              onClick={() => setCurrentTab(tab.id)}
                              className={classNames(
                                tab.id === params?.t ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                'whitespace-nowrap pb-4 border-b-2 font-medium text-sm cursor-pointer'
                              )}
                              aria-current={params?.t === tab.id ? 'page' : undefined}
                            >
                              {tab.name}
                            </span>
                          ))}
                        </nav>
                      </div>
                    </div>
                  </div>
                  {params?.t === 1 && (
                    <div className="space-y-6 sm:space-y-5 pt-8">
                      {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:pt-5">
                        <label htmlFor="photo" className="block text-sm font-medium text-gray-700">
                          Company Logo
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="flex pb-4 items-center">
                            <div className="bg-gray relative h-[200px] w-[150px] border mr-4 border-dashed border-gray-300 rounded-md">
                              <input
                                id="logoUrl"
                                ref={profileRef}
                                name="logoUrl"
                                className="hidden"
                                accept=".jpg,.jpeg,.png"
                                type="file"
                                onChange={event => {
                                  showPreview('logoUrl', event.target.files[0])
                                  setFiles({ ...files, logoUrl: event.target.files[0] })
                                }}
                              />
                              {previews.logoUrl ? (
                                <span onClick={() => profileRef.current.click()}>
                                  <img src={previews.logoUrl} alt="" className="cursor-pointer bg-cover  h-full w-[150px] rounded" />
                                </span>
                              ) : (
                                <div
                                  onClick={() => profileRef.current.click()}
                                  className="bg-white flex p-2 items-center justify-center bg-contain bg-no-repeat bg-center border-gray-300 rounded-md h-full w-full cursor-pointer"
                                >
                                  <CameraIcon className="text-gray-400 h-8 w-8" />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div> */}

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:border-gray-200 sm:pt-5 mb-6">
                        <label htmlFor="name" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                          Branch name
                        </label>
                        <div className="mt-1 flex sm:mt-0 w-full max-w-lg sm:col-span-2">
                          <Field
                            type="text"
                            name="name"
                            as={Input}
                            placeholder="Branch Name"
                            extraClassName="w-full"
                            id="name"
                            autoComplete="given-name"
                            className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div>
                        {(!isCompanyRole || hasAdminAccess) && (
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 sm:pt-5 mb-6">
                            <label htmlFor="branch" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                              Company
                            </label>
                            <div className="mt-1 flex sm:mt-0 w-full max-w-lg sm:col-span-2">
                              <Selection
                                onChange={value => {
                                  if (value?.id) {
                                    setFieldValue('company', value.id)
                                  }
                                }}
                                title="Select Companies"
                                list={companyNames}
                                value={values?.company || ''}
                                className="w-full"
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 sm:pt-5 mb-6">
                        <label htmlFor="branch" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                          Branches
                        </label>
                        <div className="mt-1 flex sm:mt-0 w-full max-w-lg sm:col-span-2">
                          <Selection
                            onChange={selectedValue => {
                              const branch = selectedValue?.id ? xor(values?.branch || [], [selectedValue.id]) : values?.branch || []
                              setFieldValue('branch', branch)
                            }}
                            title="Select Branch"
                            multiple={true}
                            list={branchNames}
                            value={values?.branch}
                            className="w-full"
                            disabled={isFetchingBranches}
                          />
                        </div>
                      </div> */}
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 sm:pt-5 mb-6">
                        <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                          Email
                        </label>
                        <div className="mt-1 flex sm:mt-0 w-full max-w-lg sm:col-span-2">
                          <Field
                            type="text"
                            name="email"
                            as={Input}
                            placeholder="Enter Email"
                            extraClassName="w-full"
                            id="email"
                            className="block shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                        <label htmlFor="governorate" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                          Governorate
                        </label>
                        <div className="mt-1 flex sm:mt-0 w-full max-w-lg sm:col-span-2">
                          <Field
                            type="text"
                            name="governorate"
                            as={Input}
                            id="governorate"
                            extraClassName="w-full"
                            className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 sm:pt-5 mb-6">
                        <label htmlFor="lon" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                          Longitude
                        </label>
                        <div className="mt-1 flex sm:mt-0 w-full max-w-lg sm:col-span-2">
                          <Field
                            type="number"
                            name="lon"
                            as={Input}
                            placeholder="Enter longitude"
                            extraClassName="w-full"
                            id="lon"
                            className="block shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 sm:pt-5 mb-6">
                        <label htmlFor="lat" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                          Latitude
                        </label>
                        <div className="mt-1 flex sm:mt-0 w-full max-w-lg sm:col-span-2">
                          <Field
                            type="number"
                            name="lat"
                            as={Input}
                            extraClassName="w-full"
                            id="lat"
                            className="block shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 sm:pt-5 mb-6">
                        <label htmlFor="address" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                          Address
                        </label>
                        <div className="mt-1 flex sm:mt-0 w-full max-w-lg sm:col-span-2">
                          <Field
                            type="text"
                            name="address"
                            as={Input}
                            placeholder="Enter Branch Address"
                            extraClassName="w-full"
                            id="address"
                            className="block shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 sm:pt-5 mb-6">
                        <label htmlFor="address" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                          Time Zone
                        </label>
                        <div className="mt-1 flex sm:mt-0 w-full max-w-lg sm:col-span-2">
                          <Selection
                            onChange={value => {
                              if (value?.id) {
                                setFieldValue('timeZone', value.id)
                              }
                            }}
                            title="Select TimeZone"
                            list={timezones}
                            value={values?.timeZone || ''}
                            className="w-full"
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {params?.t === 2 && (
                    <div className="space-y-6 sm:space-y-5 pt-8">
                      <FieldArray name="phones">
                        {({ push, remove }) => (
                          <div>
                            {size(values.phones) > 0 &&
                              values.phones.map((phone, index) => (
                                <div key={index} className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                                  <div>
                                    <label htmlFor={`phones.${index}.title`} className="block mb-3 text-sm text-normal text-gray-700">
                                      {`Phone Title ${index + 1}`}
                                    </label>
                                    <div className="mt-1 flex w-full max-w-lg">
                                      <div className="flex w-full gap-2">
                                        <Field
                                          type="text"
                                          name={`phones.${index}.title`}
                                          as={Input}
                                          extraClassName="w-full"
                                          id={`phones.${index}.title`}
                                          className="block shadow-sm w-full focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <label htmlFor={`phones.${index}.phone`} className="block mb-3 text-sm text-normal text-gray-700">
                                      {`Phone Number ${index + 1}`}
                                    </label>
                                    <div className="mt-1 flex w-full max-w-lg">
                                      <div className="flex w-full gap-2">
                                        <Field
                                          type="text"
                                          name={`phones.${index}.phone`}
                                          as={Input}
                                          extraClassName="w-full"
                                          id={`phones.${index}.phone`}
                                          className="block shadow-sm w-full focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                                        />
                                      </div>
                                      <div className="flex gap-2">
                                        <button type="button" onClick={() => remove(index)} className="text-red-600 hover:text-red-700">
                                          <TrashIcon className="w-6 h-6" />
                                        </button>

                                        {index + 1 === size(values?.phones) && (
                                          <button type="button" onClick={() => push({ title: '', phone: '' })} className="text-dark-600">
                                            <PlusCircleIcon className="w-6 h-6" />
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}

                            {size(values?.phones) === 0 && (
                              <button
                                className="inline-flex items-center justify-end p-2 border border-transparent rounded-md shadow-sm text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
                                type="button"
                                onClick={() => push({ title: '', phone: '' })}
                              >
                                Add Phone Number
                              </button>
                            )}
                          </div>
                        )}
                      </FieldArray>
                    </div>
                  )}

                  {params?.t === 3 && (
                    <div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 sm:pt-5 mb-6">
                        <label htmlFor="deliveryCost" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                          Delivery Availability
                        </label>
                        <div className="mt-1 flex sm:mt-0 w-full max-w-lg sm:col-span-2">
                          <Switch
                            value={values?.isDeliveryAvailable}
                            onChange={e => {
                              setFieldValue('isDeliveryAvailable', e)
                            }}
                          />
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 sm:pt-5 mb-6">
                        <label htmlFor="deliveryCost" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                          Delivery Cost
                        </label>
                        <div className="mt-1 flex sm:mt-0 w-full max-w-lg sm:col-span-2">
                          <Field
                            type="number"
                            name="deliveryCost"
                            as={Input}
                            extraClassName="w-full"
                            id="deliveryCost"
                            className="block shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 sm:pt-5 mb-6">
                        <label htmlFor="deliveryKmRange" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                          Delivery Range in Km
                        </label>
                        <div className="mt-1 gap-10 flex sm:mt-0 w-full max-w-lg sm:col-span-2">
                          <Field
                            type="number"
                            name="deliveryKmRange"
                            as={Input}
                            extraClassName="w-full"
                            id="deliveryKmRange"
                            placeholder="Start Delivery Range"
                            className="block shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {params?.t === 4 && (
                    <div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 sm:pt-5 mb-6">
                        <span
                          htmlFor="deliveryCost"
                          className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2 cursor-pointer"
                          onClick={() => {
                            setDialogOpen('24/7')
                          }}
                        >
                          24/7 Mode
                        </span>
                        <div className="mt-1 flex sm:mt-0 w-full max-w-lg sm:col-span-2">
                          <div
                            className={`px-2 py-1 text-white font-medium rounded-full ${
                              some(values.timings, { mode: '24/7', isActive: true }) ? 'bg-green-300 text-green-700' : 'bg-yellow-300 text-yellow-700'
                            }`}
                          >
                            {some(values.timings, { mode: '24/7', isActive: true }) ? 'Active' : 'Not Active'}
                          </div>
                          <span
                            className={`px-2 py-1 text-white font-medium rounded-full  rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500 ml-5 cursor-pointer`}
                            onClick={() => {
                              setDialogOpen('24/7')
                            }}
                          >
                            Adjust Timings
                          </span>
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 sm:pt-5 mb-6">
                        <span
                          htmlFor="deliveryCost"
                          className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2 cursor-pointer"
                          onClick={() => {
                            setDialogOpen('shift')
                          }}
                        >
                          Shift Mode
                        </span>

                        <div className="mt-1 flex sm:mt-0 w-full max-w-lg sm:col-span-2">
                          <div
                            className={`px-2 py-1 text-white font-medium rounded-full ${
                              some(values.timings, { mode: 'shift', isActive: true }) ? 'bg-green-300 text-green-700' : 'bg-yellow-300 text-yellow-700'
                            }`}
                          >
                            {some(values.timings, { mode: 'shift', isActive: true }) ? 'Active' : 'Not Active'}
                          </div>
                          <span
                            className={`px-2 py-1 text-white font-medium rounded-full  rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500 ml-5 cursor-pointer`}
                            onClick={() => {
                              setDialogOpen('shift')
                            }}
                          >
                            Adjust Timings
                          </span>
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 sm:pt-5 mb-6">
                        <span
                          htmlFor="deliveryCost"
                          className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2 cursor-pointer"
                          onClick={() => {
                            setDialogOpen('normal')
                          }}
                        >
                          Normal Mode
                        </span>
                        <div className="mt-1 flex sm:mt-0 w-full max-w-lg sm:col-span-2">
                          <div
                            className={`px-2 py-1 text-white font-medium rounded-full ${
                              some(values.timings, { mode: 'normal', isActive: true }) ? 'bg-green-300 text-green-700' : 'bg-yellow-300 text-yellow-700'
                            }`}
                          >
                            {some(values.timings, { mode: 'normal', isActive: true }) ? 'Active' : 'Not Active'}
                          </div>

                          <span
                            className={`px-2 py-1 text-white font-medium rounded-full  rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500 ml-5 cursor-pointer`}
                            onClick={() => {
                              setDialogOpen('normal')
                            }}
                          >
                            Adjust Timings
                          </span>
                        </div>
                      </div>

                      {dialogOpen === '24/7' && (
                        <BranchTimingDialog
                          isOpen={dialogOpen === '24/7'}
                          onClose={() => setDialogOpen()}
                          timings={twentyFourSevenTimings}
                          onTimingChange={handleTimingChange}
                          onSave={timings => {
                            setFieldValue('timings', [...(reject(values?.timings, { mode: '24/7' }) || []), ...(timings || [])])
                            setDialogOpen()
                          }}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      )}

                      {dialogOpen === 'shift' && (
                        <ShiftModeDialog
                          isOpen={dialogOpen === 'shift'}
                          onClose={() => setDialogOpen()}
                          timings={shiftModeTimings}
                          onShiftChange={handleShiftChange}
                          onSwitchChange={handleShiftSwitchChange}
                          onSave={timings => {
                            setFieldValue('timings', [...(reject(values?.timings, { mode: 'shift' }) || []), ...(timings || [])])
                            setDialogOpen()
                          }}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      )}

                      {dialogOpen === 'normal' && (
                        <NormalModeDialog
                          isOpen={dialogOpen === 'normal'}
                          onClose={() => setDialogOpen(null)}
                          values={values}
                          setFieldValue={setFieldValue}
                          branchData={branchData}
                          timings={normalModeTimings}
                          onTimeChange={handleNormalModeChange}
                          onSave={timings => {
                            setFieldValue('timings', [...(reject(values?.timings, { mode: 'normal' }) || []), ...(timings || [])])
                            setDialogOpen()
                          }}
                        />
                      )}
                    </div>
                  )}
                  {params?.t === 5 && <DashboardCards branchData={branchData} />}
                </div>

                {params.t !== 5 && !readOnly && (
                  <div className="pt-2">
                    <div className="flex justify-between">
                      <button
                        type="submit"
                        disabled={!isValid || isSubmitting}
                        className={`mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500 ${
                          (!isValid || isSubmitting) && 'opacity-50 cursor-not-allowed'
                        }`}
                      >
                        {isSubmitting ? (
                          <span className="flex items-start">
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                            <span>Please wait...</span>
                          </span>
                        ) : (
                          <span>{!isEdit ? 'Add Branch' : 'Update'}</span>
                        )}
                      </button>
                      {(!isCompanyRole || hasAdminAccess) && isEdit && (
                        <div>
                          <button
                            type="button"
                            onClick={deleteBranch}
                            className={`mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500`}
                          >
                            Delete Branch
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </Form>
            </div>
          </div>
        ) : (
          <div className="w-full h-[500px] flex items-center justify-center">
            <LoadingIcon />
          </div>
        )
      }
    </Formik>
  )
}

export default BranchProfile
