import { useEffect, useRef, useState } from 'react'
import notify from 'components/atoms/Notify'
import apiPost from 'lib/network/apiPost'
import apiGet from 'lib/network/apiGet'
import Selection from 'components/molecules/Selection'
import apiPut from 'lib/network/apiPut'
import { CameraIcon } from '@heroicons/react/solid'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

const AddBlackList = ({ onSuccess, close, id = null }) => {
  const [userOptions, setUserOptions] = useState([])
  const [selectedFraudUser, setSelectedFraudUser] = useState('')
  const userFromStore = useSelector(state => get(state, 'user.user'))
  // eslint-disable-next-line
  const hasAdminAccess = userFromStore?.roles?.some(role => role.type === 'admin')
  const [previews, setPreviews] = useState({})
  const [files, setFiles] = useState({})
  const profileRef = useRef(null)
  const [data, setFormData] = useState({
    name: '',
    civilId: '',
    note: '',
    phone: '',
  })
  const shouldDisableFields = id && !hasAdminAccess

  const showPreview = (key, file) => {
    const preview = URL.createObjectURL(file)
    setPreviews({ ...previews, [key]: preview })
  }

  useEffect(() => {
    if (hasAdminAccess) {
      fetchUsers()
    }
  }, [hasAdminAccess])

  const fetchUsers = async (searchQuery = '') => {
    const response = await apiGet('users', { query: searchQuery })

    if (response?.data) {
      const users = response.data.user.map(user => ({
        id: user._id,
        name: user.fullName || user.email || user.phone,
      }))
      setUserOptions(users)
    } else {
      setUserOptions([])
    }
  }

  useEffect(() => {
    if (id) {
      fetchFraudUserData()
    }
  }, [id])

  const fetchFraudUserData = async () => {
    const response = await apiGet(`fraud-users/${id}`)
    if (response.success) {
      const data = response.data
      setFormData({
        name: data.name || '',
        civilId: data.civilId || '',
        note: data.note || '',
        phone: data.phone || '',
      })
      setSelectedFraudUser(data?.user?._id || '')

      if (data.image) {
        setPreviews({ image: data.image })
      }
    } else {
      notify.error('Failed to fetch Fraud user data')
    }
  }

  const handleChange = e => {
    const { name, value } = e.target

    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSaveFraudUser = async () => {
    const formData = new FormData()

    // eslint-disable-next-line
    Object.keys(data).forEach(key => {
      if (data[key] !== null && data[key] !== undefined && data[key] !== '') {
        formData.append(key, data[key])
      }
    })

    if (selectedFraudUser) {
      formData.append('userId', selectedFraudUser)
    }

    if (files.image) {
      formData.append('image', files.image)
    }

    const endpoint = id ? `fraud-users/${id}` : 'fraud-users'
    const apiMethod = id ? apiPut : apiPost

    try {
      const response = await apiMethod(endpoint, formData)

      if (response.success) {
        const message = id ? 'Fraud user updated successfully' : 'Fraud user added successfully'
        notify.success(message)
        onSuccess(response.data._id)
      } else {
        notify.error(response.message || 'Failed to save Fraud user')
      }
    } catch (error) {
      notify.error('Failed to save Fraud user')
    }
  }

  return (
    <div>
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:pt-5">
        <label htmlFor="photo" className="block text-sm font-medium text-gray-700">
          Photo
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="flex pb-4 items-center">
            <div className="bg-gray relative h-[200px] w-[150px] border mr-4 border-dashed border-gray-300 rounded-md">
              <input
                id="image"
                ref={profileRef}
                name="image"
                className="hidden"
                accept=".jpg,.jpeg,.png"
                type="file"
                onChange={event => {
                  showPreview('image', event.target.files[0])
                  setFiles({ ...files, image: event.target.files[0] })
                }}
                disabled={shouldDisableFields}
              />
              {previews.image ? (
                <span onClick={() => !shouldDisableFields && profileRef.current.click()}>
                  <img src={previews.image} alt="" className={`bg-cover h-full w-[150px] rounded ${!shouldDisableFields ? 'cursor-pointer' : ''}`} />
                </span>
              ) : (
                <div
                  onClick={() => !shouldDisableFields && profileRef.current.click()}
                  className={`bg-white flex p-2 items-center justify-center bg-contain bg-no-repeat bg-center border-gray-300 rounded-md h-full w-full ${
                    !shouldDisableFields ? 'cursor-pointer' : ''
                  }`}
                >
                  <CameraIcon className="text-gray-400 h-8 w-8" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {hasAdminAccess && (
        <div className="mb-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
          <label htmlFor="name" className="block text-sm font-normal text-gray-700 mb-1">
            Users
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <Selection
              onChange={value => {
                if (!shouldDisableFields && value && value.id) {
                  setSelectedFraudUser(prevValue => (prevValue === value.id ? '' : value.id))
                }
              }}
              title="Select Fraud User"
              list={userOptions}
              value={selectedFraudUser}
              showSearch
              onSearch={searchQuery => fetchUsers(searchQuery)}
              className="block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
              disabled={shouldDisableFields}
            />
          </div>
        </div>
      )}

      <div className="mb-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
        <label htmlFor="name" className="block text-sm font-normal text-gray-700 mb-1">
          Fraud Name
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="text"
            name="name"
            id="name"
            value={data.name}
            onChange={handleChange}
            disabled={shouldDisableFields}
            className="block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
          />
        </div>
      </div>

      <div className="mb-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
        <label htmlFor="phone" className="block text-sm font-normal text-gray-700 mb-1">
          Phone Number
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="number"
            name="phone"
            id="phone"
            value={data.phone}
            onChange={handleChange}
            disabled={shouldDisableFields}
            className="block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
          />
        </div>
      </div>

      <div className="mb-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
        <label htmlFor="civilId" className="block text-sm font-normal text-gray-700 mb-1">
          Fraud CivilId
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="number"
            name="civilId"
            id="civilId"
            value={data.civilId}
            onChange={handleChange}
            disabled={shouldDisableFields}
            className="block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
          />
        </div>
      </div>

      <div>
        <label htmlFor="note" className="block text-sm font-normal text-gray-700 mb-1">
          Notes
        </label>
        <textarea type="text" name="note" id="note" value={data.note} onChange={handleChange} disabled={shouldDisableFields} className="w-full" />
      </div>

      <div className="mt-4 flex justify-end">
        {!shouldDisableFields && (
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500 sm:ml-3"
            onClick={handleSaveFraudUser}
          >
            {id ? 'Update Fraud User' : 'Add Fraud User'}
          </button>
        )}
        <button
          type="button"
          className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none"
          onClick={() => close()}
        >
          Close
        </button>
      </div>
    </div>
  )
}

export default AddBlackList
