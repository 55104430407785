import { useEffect, useState } from 'react'
import startCase from 'lodash/startCase'
import Layout from 'containers/Layout'
import moment from 'moment'
import { get, orderBy, split, trim, truncate } from 'lodash'
import apiGet from 'lib/network/apiGet'

import { useHistory, useParams } from 'react-router-dom'

import Blockies from 'react-blockies'

import Table from 'components/pageComponents/Table'
import LoadingIcon from 'components/atoms/LoadingIcon'
import EmptyState from 'components/pageComponents/EmptyState'
import Button from 'components/atoms/Button'
import { startEndTruncate, accessibleOnClick } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip } from 'antd'
import { IoCopyOutline, IoSearch } from 'react-icons/io5'
import notify from 'components/atoms/Notify'
import PageFooter from 'components/paginationComponent'

const Companies = () => {
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState({ workspace: -1 })
  const [companies, setCompanies] = useState([])
  const [pagination, setPagination] = useState({})
  const [users, setUsers] = useState()
  const [search, setSearch] = useState('')

  const { id } = useParams()

  useEffect(() => {
    fetchCompanies()
  }, [search])

  const fetchCompanies = async (page = 1) => {
    setLoading(true)
    const params = {}
    params.pageNumber = page
    params.pageSize = 20
    params.query = search || undefined
    const response = await apiGet('companies', params)

    setCompanies(page === 1 ? response.data.company : [...companies, ...response.data.company])
    setPagination(response?.data?.pagination || {})

    setLoading(false)
  }

  const user = useSelector(state => get(state, 'user.user'))

  const copyText = text => {
    navigator.clipboard.writeText(text).then(() => {
      notify.success('Copied to clipboard', truncate(text, { length: 35 }))
    })
  }

  const columns = [
    {
      name: 'Name',
      width: '10%',
      key: 'name',
      sortable: true,
      rendered: item => {
        return (
          <div className="flex items-center cursor-pointer" {...accessibleOnClick(() => (window.location.href = `/company/edit/${item._id}/company-profile`))}>
            <div className="flex-shrink-0 h-10 w-10">
              {item?.logoThumbnailUrl ? (
                <img className="h-10 w-10 rounded-full" src={item?.logoThumbnailUrl} alt="" />
              ) : (
                <Blockies className="rounded-full" seed={item.walletAddress || item.email || ''} size={6.5} scale={6} />
              )}
            </div>
            <div className="ml-4">
              <div className="text-sm text-normal text-gray-900">{item?.name || 'User'}</div>
              <div
                // eslint-disable-next-line
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                }}
                className="text-[13px] text-gray-500 flex"
              >
                {item.walletAddress ? (
                  <span className="flex items-center">
                    <Tooltip title={`${item.walletAddress}`}>
                      <span
                        // eslint-disable-next-line
                        onClick={e => {
                          e.stopPropagation()
                          e.preventDefault()
                          copyText(item.walletAddress)
                        }}
                        className="text-[13px] hover:text-indigo-500 cursor-pointer"
                      >
                        {startEndTruncate(item?.walletAddress, 6)}
                      </span>
                    </Tooltip>
                    <button id="disable-export" className="hover:text-indigo-500" onClick={() => copyText(item?.walletAddress)}>
                      <IoCopyOutline className="ml-2" />
                    </button>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        )
      },
    },
    {
      width: '10%',
      name: 'Governorate',
      key: 'governorate',
      sortable: true,
      rendered: item => {
        return (
          <>
            <div>
              <span className="text-[13px]">{item.governorate}</span>
            </div>
          </>
        )
      },
    },

    {
      width: '15%',
      name: 'Max Age/Min Age',
      key: 'Age',
      sortable: true,
      rendered: item => (
        <span className="text-[13px]">
          {
            // eslint-disable-next-line
            orderBy(item?.roles?.filter(d => d.name !== 'platform_admin')?.map(r => startCase(r.name)))?.map(d => (
              <span className="border mr-3 shadow-sm rounded-2xl px-2 py-0.5" key={d}>
                {d}
              </span>
            ))
          }
          <span className="border mr-3 shadow-sm rounded-2xl px-2 py-0.5">
            {' '}
            {startCase(item.maxAge)}/{startCase(item.minAge)}
          </span>
        </span>
      ),
    },
    {
      width: '10%',
      name: 'Total cars',
      key: 'rentalCarCount',
      sortable: true,
      rendered: item => {
        return (
          <>
            <div>
              <span className="text-[13px]">{item.rentalCarCount}</span>
            </div>
          </>
        )
      },
    },

    {
      width: '10%',
      name: 'Blocked Countries',
      key: 'blockedCountries',
      sortable: true,
      rendered: item => (
        <span className="text-[13px] max-w-[50px]">
          {
            // eslint-disable-next-line
            orderBy(item?.roles?.filter(d => d.name !== 'platform_admin')?.map(r => startCase(r.name)))?.map(d => (
              <span className="border mr-3 shadow-sm rounded-2xl px-2 py-0.5" key={d}>
                {d}
              </span>
            ))
          }
          {split(item.blockedCountries, ',').map(country => {
            const trimmedCountry = trim(country)
            return trimmedCountry ? (
              <span className="border mr-3 shadow-sm rounded-2xl px-2 py-0.5" key={trimmedCountry}>
                {startCase(trimmedCountry)}
              </span>
            ) : (
              <span key={trimmedCountry}>{startCase(trimmedCountry)}</span>
            )
          })}
        </span>
      ),
    },

    {
      width: '5%',
      name: 'Created',
      key: 'created',
      sortable: true,
      rendered: item => {
        const createdTime = get(item, 'createdAt', '')
        const createdAt = createdTime ? moment(createdTime).fromNow() : 'Registered'
        return createdAt
      },
    },
  ]

  return (
    <Layout
      showLogo={!!id}
      breadcrumbs={[{ name: 'Companies', pathname: '/companies' }]}
      headerRight={
        <div className="flex items-center w-full justify-end sm:mt-0 sm:ml-4">
          <div className="mt-1 relative rounded-md shadow-sm">
            <PageFooter currentPage={pagination.currentPage} totalPages={pagination.totalPages} totalRecords={pagination.totalRecords} />
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" aria-hidden="true">
              <IoSearch className="mr-3 h-4 w-4 text-gray-400" aria-hidden="true" />
            </div>
            <input
              onChange={e => setSearch(e.target.value)}
              value={search}
              type="text"
              name="search"
              id="search"
              className="block w-full pl-9 shadow-none sm:text-sm bg-gray-100 border-0 focus:outline-none rounded-sm"
              placeholder="Search options"
            />
          </div>
          {user.isWorkspaceAdmin ? (
            <Button onClick={() => history.push('/users/add')}>Invite User</Button>
          ) : (
            <Button onClick={() => history.push('/add/company')}>Add Company</Button>
          )}
        </div>
      }
      title="Companies"
    >
      <div className="flex flex-col">
        <div className="flex-1 flex items-stretch">
          <main className="flex-1">
            <div className="align-middle inline-block min-w-full">
              <div className="min-w-full">
                {!loading ? (
                  <>
                    {companies?.length === 0 ? (
                      <EmptyState v2 description="No companies found" />
                    ) : (
                      <Table
                        hasMore={pagination?.currentPage !== pagination?.totalPages}
                        fetchData={() => fetchCompanies(pagination?.currentPage + 1)}
                        sortBy={filter.sortBy}
                        sortOrder={filter.sortOrder}
                        handleSort={(sortBy, sortOrder) => {
                          setUsers([])
                        }}
                        data={companies}
                        columns={columns}
                      />
                    )}
                  </>
                ) : (
                  <div className="w-full h-[200px] border-0 flex justify-center items-center">
                    <LoadingIcon />
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </Layout>
  )
}

export default Companies
