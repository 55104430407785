import React, { Fragment, useState, useEffect } from 'react'
import moment from 'moment'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { startCase } from 'lodash'
import apiGet from 'lib/network/apiGet'

const BookingLogsDrawer = ({ isDrawerOpen, closeDrawer, selectedBooking }) => {
  const [bookingsLogs, setBookingLogs] = useState([])

  const fetchBookingLogs = async () => {
    if (selectedBooking?._id) {
      try {
        const response = await apiGet(`/bookings/${selectedBooking._id}/logs`)

        if (response?.success) {
          setBookingLogs(response?.data)
        }
      } catch (error) {
        console.error('Failed to fetch booking logs:', error)
      }
    }
  }

  useEffect(() => {
    if (isDrawerOpen && selectedBooking) {
      fetchBookingLogs()
    }
  }, [isDrawerOpen, selectedBooking])

  return (
    <Transition.Root show={isDrawerOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={closeDrawer}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-screen max-w-md">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={closeDrawer}
                    >
                      <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full flex flex-col py-6 bg-white shadow-xl">
                  <div className="px-4 sm:px-6 border-b pb-3">
                    <Dialog.Title className="text-lg font-medium text-gray-900">{selectedBooking?.user?.fullName} Booking Logs</Dialog.Title>
                  </div>
                  <div className="mt-6 relative flex-1 px-4 overflow-y-scroll sm:px-6">
                    <div className="absolute inset-0 px-4 sm:px-6">
                      <div className="h-full" aria-hidden="true">
                        <div className="container">
                          <div className="flex flex-col md:grid grid-cols-9 mx-auto p-2 text-blue-50">
                            {bookingsLogs?.map((log, index) => {
                              return (index + 1) % 2 !== 0 ? (
                                <div className="flex flex-row-reverse md:contents" key={log?._id}>
                                  <div className="col-start-1 col-end-5 p-- rounded-xl my-4 ml-auto">
                                    <div className="">
                                      <h3 className="font-semibold text-gray-500 text-sm mb-1">{log?.updatedBy?.fullName}</h3>
                                      <p className="leading-tight text-gray-500">{moment(log.createdAt).fromNow()}</p>
                                    </div>

                                    <div className="text-gray-500">
                                      Booking Status:{' '}
                                      <span
                                        className={classNames(`text-xs font-medium p-1 rounded-md`, {
                                          'bg-gray-200 text-black': log?.status === 'pending',
                                          'text-white bg-dark-600': log?.status === 'rejected',
                                          'bg-rose-400 !text-white': log?.status === 'cancelled',
                                          'bg-green-500 !text-white': log?.status === 'completed' || log?.booking?.status === 'confirmed',
                                        })}
                                      >
                                        {startCase(log?.status)}
                                      </span>
                                    </div>

                                    <div className="text-gray-500 mt-1">
                                      Payment Status:{' '}
                                      <span
                                        className={`text-xs font-regular ${
                                          log.paymentStatus === 'paid'
                                            ? 'bg-green-100 text-green-700'
                                            : log.paymentStatus === 'failure'
                                            ? 'bg-red-100 text-red-700'
                                            : log.paymentStatus === 'unpaid'
                                            ? 'bg-yellow-100 text-yellow-800'
                                            : 'bg-gray-200'
                                        } px-2 py-1 rounded-md`}
                                      >
                                        {startCase(log.paymentStatus)}
                                      </span>
                                    </div>

                                    <p className="leading-tight text-gray-500">{log?.message}</p>
                                  </div>
                                  <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
                                    <div className="h-full w-6 flex items-center justify-center">
                                      <div className="h-full w-1 bg-blue-800 pointer-events-none"></div>
                                    </div>
                                    <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-blue-500 shadow"></div>
                                  </div>
                                </div>
                              ) : (
                                <div className="flex md:contents" key={log?._id}>
                                  <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                                    <div className="h-full w-6 flex items-center justify-center">
                                      <div className="h-full w-1 bg-blue-800 pointer-events-none"></div>
                                    </div>
                                    <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-blue-500 shadow"></div>
                                  </div>
                                  <div className="col-start-6 col-end-10 p-0 rounded-xl my-4 mr-auto">
                                    <div className="">
                                      <h3 className="font-semibold text-gray-500 text-sm mb-1">{log?.updatedBy?.fullName}</h3>
                                      <p className="leading-tight text-gray-500">{moment(log.createdAt).fromNow()}</p>
                                    </div>

                                    <div className="text-gray-500">
                                      Booking Status:{' '}
                                      <span
                                        className={classNames(`text-xs font-medium p-1 rounded-md`, {
                                          'bg-gray-200 text-black': log?.status === 'pending',
                                          'text-white bg-dark-600': log?.status === 'rejected',
                                          'bg-rose-400 !text-white': log?.status === 'cancelled',
                                          'bg-green-500 !text-white': log?.status === 'completed' || log?.status === 'confirmed',
                                        })}
                                      >
                                        {startCase(log?.status)}
                                      </span>
                                    </div>

                                    <div className="text-gray-500 mt-1">
                                      Payment Status:{' '}
                                      <span
                                        className={`text-xs font-regular ${
                                          log.paymentStatus === 'paid'
                                            ? 'bg-green-100 text-green-700'
                                            : log.paymentStatus === 'failure'
                                            ? 'bg-red-100 text-red-700'
                                            : log.paymentStatus === 'unpaid'
                                            ? 'bg-yellow-100 text-yellow-800'
                                            : 'bg-gray-200'
                                        } px-2 py-1 rounded-md`}
                                      >
                                        {startCase(log.paymentStatus)}
                                      </span>
                                    </div>
                                    <p className="leading-tight text-gray-500">{log?.message}</p>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default BookingLogsDrawer
