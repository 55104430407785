import { withRouter, useHistory } from 'react-router'
import { destroyCookie } from 'nookies'
import { Dialog, Transition } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from 'state/redux/actions/user.actions'
import {
  SearchIcon,
  BellIcon,
  MenuIcon,
  CalculatorIcon,
  BeakerIcon,
  DocumentReportIcon,
  UserGroupIcon,
  TruckIcon,
  BriefcaseIcon,
  OfficeBuildingIcon,
  BookOpenIcon,
  ClipboardListIcon,
  DocumentTextIcon,
} from '@heroicons/react/outline'
import { Fragment, useEffect, useState } from 'react'
import { CloudUploadIcon, XIcon } from '@heroicons/react/solid'
import { get, xor, toLower, includes, isString, split, flatMap, map, filter } from 'lodash'
import classNames from 'classnames'
import logo from 'assets/images/ajarlee.png'
import { setDrawerToggle, setToggles, setMainToggles } from 'state/redux/actions/settings.actions'
import BottomMenu from 'containers/BottomMenu'
import NotificationDrawer from 'containers/NotificationDrawer'
import { setNotificationCount } from 'state/redux/reducers/notification.reducers'
import BuildingIcon from 'components/Icons/Icons'

const Sidebar = ({ setVisible, visible, user, openChangePasswordModal }) => {
  const [search, setSearch] = useState('')
  const [toggleHold, setToggleHold] = useState([])
  const [notificationDrawer, setNotificationDrawer] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()

  const drawer = useSelector(state => get(state, 'settings.drawer'))
  const toggles = useSelector(state => get(state, 'settings.toggles', [])) || []
  const mainToggles = useSelector(state => get(state, 'settings.mainToggles', [])) || []
  const userFromStore = useSelector(state => get(state, 'user.user'))
  // eslint-disable-next-line
  const hasAdminAccess = userFromStore?.roles?.some(role => role.type === 'admin')
  const notificationCount = useSelector(state => get(state, 'notification.unreadCount', 0))

  const access = flatMap(userFromStore?.roles, item => map(item.access, 'menu.pathname'))

  const handleLogout = e => {
    dispatch(setUser({}))

    destroyCookie({}, 'accessToken', { path: '/' })

    history.push('/login')
    return false
  }

  const handleNotification = () => {
    setNotificationDrawer(!notificationDrawer)
  }

  const menuItems = [
    { name: 'Dashboard', icon: BeakerIcon, pathname: '/dashboard' },
    { name: 'Transactions', icon: DocumentReportIcon, pathname: '/transactions' },
    { name: 'Audit Logs', icon: ClipboardListIcon, pathname: '/audits' },
    { name: 'Bookings', icon: BookOpenIcon, pathname: '/bookings' },
    { name: 'Users', icon: UserGroupIcon, pathname: '/users' },
    { name: 'Roles', icon: BriefcaseIcon, pathname: '/roles' },
    { name: 'Companies', icon: OfficeBuildingIcon, pathname: '/companies' },
    { name: 'Branches', icon: BuildingIcon, pathname: '/branches' },
    { name: 'Cars', icon: TruckIcon, pathname: '/cars' },
    { name: 'Discounts', icon: CalculatorIcon, pathname: '/discounts' },
    ...(hasAdminAccess || userFromStore?.superCompanyUser ? [{ name: 'BlackList', icon: BellIcon, pathname: '/fraud-users' }] : []),
    { name: 'Imports Managment', icon: CloudUploadIcon, pathname: '/imports' },
    { name: 'Reports', icon: DocumentTextIcon, pathname: '/salesReport' },
  ]

  const menu = filter(menuItems, item => includes(access, item.pathname))

  const handleDrawer = () => dispatch(setDrawerToggle(!drawer))

  const color = '#1344ce'
  const lightColor = color + '1A'

  const Submenu = ({ items, item, level }) => {
    const open = level === 3 ? includes(toggles, item.name) : includes(mainToggles, item.name)

    return (
      <div className={classNames('space-y-1 drawer_menu', open ? 'drawer_submenu_open' : 'drawer_submenu_close')}>
        {items
          ?.filter(menu => {
            if (search === '') return true
            if (isString(menu.name)) return true
            if (toLower(item.name).search(toLower(search)) !== -1) return true
            return toLower(menu.name).search(toLower(search)) !== -1
          })
          ?.map((sub, index) => {
            const current = split(sub?.pathname, '?')[0] === history.location.pathname

            const hasSubmenu = sub.items?.length > 0

            return (
              <div key={index}>
                <span
                  onClick={hasSubmenu ? () => dispatch(setToggles(xor([sub.name], toggles))) : () => history.push(sub.pathname)}
                  style={{
                    borderLeftColor: current ? color : 'transparent',
                    background: current ? lightColor : '',
                    color: current ? color : '',
                  }}
                  className={classNames(
                    level === 3 ? 'pl-9' : 'pl-5',
                    current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-900 hover:bg-gray-50',
                    'group  flex h-[32px] items-center border-l-2 px-3 py-1.5 text-[12px] font-normal cursor-pointer'
                  )}
                  aria-current={current ? 'page' : undefined}
                >
                  {hasSubmenu ? (
                    <span className="absolute left-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={classNames('h-4 w-4 transition-all', includes(toggles, sub.name) ? 'rotate-90' : '')}
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  ) : null}
                  <div style={{ color: current ? 'white' : '', background: current ? color : '#eeeeee' }} className={`h-4 w-4 rounded-md mr-3 flex items-center justify-center`}>
                    {sub.icon ? (
                      <sub.icon />
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-2 w-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                        />
                      </svg>
                    )}
                  </div>
                  <span
                    className={classNames(hasSubmenu && ' hover:text-indigo-600', 'text-ellipsis truncate')}
                    onClick={e => {
                      if (hasSubmenu) {
                        e.preventDefault()
                        e.stopPropagation()
                        history.push(sub.pathname)
                      }
                    }}
                  >
                    {sub.span ? sub.span : sub.name}
                  </span>
                </span>
                {hasSubmenu ? <Submenu level={3} items={sub.items} item={sub} /> : null}
              </div>
            )
          })}
      </div>
    )
  }

  const Menu = () => {
    return (
      <nav className="mt-6">
        <div className="pb-40">
          <div className="mt-4">
            {menu
              ?.filter(menu => {
                if (search === '') return true
                if (menu.items) {
                  return (
                    menu.items.filter(item => {
                      return toLower(item?.name).search(toLower(search)) !== -1
                    }).length > 0 || toLower(menu.name).search(toLower(search)) !== -1
                  )
                }
                return toLower(menu.name).search(toLower(search)) !== -1
              })
              .filter(menu => {
                return (
                  menu.items?.filter(item => {
                    return toLower(item.name).search(toLower(search)) !== -1
                  }).length > 0 || toLower(menu.name).search(toLower(search)) !== -1
                )
              })
              .map((item, index) => {
                const current = history.location.pathname === item.pathname

                return <MenuItem current={current} index={index} key={index} item={item} />
              })}
          </div>
        </div>
      </nav>
    )
  }

  const MenuItem = ({ index, current, item }) => {
    const hasSubmenu = item.items?.length > 0
    const selected = includes(mainToggles, item.name)
    const itemOnClick = () => {
      if (item.items) {
        dispatch(setMainToggles(xor([item.name], mainToggles)))
      }
      if (item.pathname) {
        history.push(item.pathname)
      }
    }

    return (
      <div key={index} className={classNames('border-gray-100 relative', selected && hasSubmenu && 'pb-4')}>
        <span
          onClick={itemOnClick}
          style={{
            borderLeftColor: current ? color : 'transparent',
            background: current ? lightColor : '',
            color: current ? color : '',
            letterSpacing: '0.28px',
          }}
          className={classNames(
            current ? 'text-[#53575e]' : 'text-[#979797] hover:bg-gray-50',
            'group flex gap-5 relative items-center h-[48px] border-t border-[#e9ebf0] uppercase border-l-2 px-4 pl-6 py-1.5 text-[11px] font-bold cursor-pointer'
          )}
          aria-current={current ? 'page' : undefined}
        >
          <item.icon className="w-6 h-6" /> {item.name}
          {hasSubmenu ? (
            <span className="absolute right-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={classNames('h-4 w-4 transition-all', includes(mainToggles, item.name) ? 'rotate-90' : '')}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          ) : null}
        </span>
        {selected && item.customHeader ? item.customHeader() : null}
        {hasSubmenu ? <Submenu items={item.items} item={item} /> : null}
      </div>
    )
  }

  return (
    <>
      <Transition.Root show={visible} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setVisible}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setVisible(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4">
                <img className="h-8 w-auto" src={logo} alt="Workflow" />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <Menu />
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
        </Dialog>
      </Transition.Root>
      <div
        style={{ borderRight: '1px solid #e9ebf0' }}
        className={classNames(
          drawer ? 'noscrollbar drawer-open' : 'drawer-close opacity-0',
          'hidden lg:flex lg:flex-col  lg:w-64 lg:fixed lg:inset-y-0 lg:border-r lg:border-gray-200 lg:pt-4 lg:pb-4 lg:bg-white'
        )}
      >
        <div className="flex items-center justify-between flex-shrink-0 px-4">
          <img className="h-6 w-auto" src={logo} alt="Workflow" />
          <div>
            {hasAdminAccess && (
              <button className="relative" onClick={handleNotification}>
                {notificationCount > 0 ? <div className="notification-bell">{notificationCount}</div> : null}
                <BellIcon className="h-5 w-5 mr-3" aria-hidden="true" />
              </button>
            )}
            <button onClick={handleDrawer}>
              <MenuIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
        <div className="mt-2 h-0 flex-1 noscrollbar flex flex-col overflow-y-auto">
          <div className="px-3 mt-2">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" aria-hidden="true">
                <SearchIcon className="mr-3 h-4 w-4 text-gray-400" aria-hidden="true" />
              </div>
              <input
                onChange={e => {
                  setSearch(e.target.value)
                }}
                value={search}
                onFocus={e => {
                  setToggleHold(toggles)
                  dispatch(setToggles(menu.map(item => item.name)))
                }}
                onBlur={() => {
                  dispatch(setToggles(toggleHold))
                }}
                type="text"
                name="search"
                id="search"
                className="block w-full pl-9 shadow-none sm:text-sm bg-gray-100 border-0 focus:outline-none rounded-sm"
                placeholder="Search options"
              />
            </div>
          </div>

          {notificationDrawer && <div onClick={() => setNotificationDrawer(false)} className="notification-drawer-overlay"></div>}
          <div className={classNames(notificationDrawer && 'notification-drawer-open', 'notification-drawer')}>
            <NotificationDrawer open={notificationDrawer} />
          </div>

          <Menu />

          <BottomMenu user={user} openChangePasswordModal={openChangePasswordModal} handleLogout={handleLogout} />
        </div>
      </div>
    </>
  )
}

export default withRouter(Sidebar)
