const PageFooter = ({ currentPage, totalPages, totalRecords }) => {
  if (!currentPage || !totalPages) return null

  return (
    <div className="fixed bottom-4 left-1/2 -translate-x-1/2">
      <div>
        <div className="flex justify-center">
          <span className="inline-flex items-center justify-center rounded-full bg-red-100 text-red-800 px-1.5 py-0.5 text-sm font-medium border border-red-300">
            <div className="flex items-center space-x-2">
              <span>Page {currentPage}</span>
              <span>of</span>
              <span>{totalPages}</span>
              <span>|</span>
              <span>Total Records: {totalRecords}</span>
            </div>
          </span>
        </div>
      </div>
    </div>
  )
}

export default PageFooter
