import { useEffect, useRef, useState } from 'react'

import classNames from 'classnames'
import moment from 'moment'

import { map, toUpper, toLower, startCase } from 'lodash'
import { ArrowTopRightOnSquareIcon } from '@heroiconsv2/react/24/outline'

const Booking = ({ loading, bookingData, isEdit, id, refetch, streamConnected }) => {
  const [orderStatus, setOrderStatus] = useState(bookingData?.status)

  const previousStatus = useRef(bookingData?.status)

  useEffect(() => {
    setOrderStatus(bookingData?.status)

    if (previousStatus.current && previousStatus.current !== bookingData?.status) {
      const statusElement = document.getElementById('booking-status')
      if (statusElement) {
        statusElement.classList.add('animate-pulse')
        setTimeout(() => {
          statusElement.classList.remove('animate-pulse')
        }, 2000)
      }
    }

    previousStatus.current = bookingData?.status
  }, [bookingData])

  const calculateAjarleeAmount = (totalPrice, percentage) => {
    if (!totalPrice || !percentage) return 0
    return (totalPrice * (percentage / 100)).toFixed(2)
  }

  const calculateCompanyAmount = (totalPrice, percentage) => {
    if (!totalPrice || !percentage) return totalPrice || 0
    return (totalPrice - totalPrice * (percentage / 100)).toFixed(2)
  }

  return (
    <div className="flex flex-col pb-52 max-md:pb-24 w-full">
      <div className="flex overflow-hidden flex-col justify-center px-20 py-5 w-full bg-white max-md:px-5 max-md:max-w-full">
        <div className="flex justify-start mt-4">
          {streamConnected && (
            <div className="mb-4 text-xs text-green-600 p-2 bg-green-50 rounded-md">
              <span className="inline-block w-2 h-2 bg-green-500 rounded-full mr-2"></span>
              Live updates connected
            </div>
          )}
        </div>

        <div className="flex flex-col justify-center w-full max-md:max-w-full">
          <div className="grid grid-cols-2 gap-10">
            <div className="flex flex-wrap gap-10 items-start p-10 w-full rounded-2xl bg-zinc-50 shadow-[0px_0px_4px_rgba(0,0,0,0.25)] max-md:px-5 max-md:max-w-full">
              <div className="flex flex-col grow shrink min-w-[240px]  w-full h-full">
                <div className="flex flex-col">
                  <div className="flex flex-col items-start w-full leading-relaxed max-md:max-w-full">
                    <div className="self-stretch text-xl font-semibold leading-snug text-black">Delivery Details</div>
                    <div className="flex flex-col mt-2 w-full rounded-none ">
                      <div className="flex gap-2">
                        <div className="mt-1.5 text-base tracking-normal text-zinc-600">PickUp Address:</div>
                        <div className="flex">
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${bookingData?.delivery?.pickupLocation?.coordinates?.[1] || bookingData?.branch?.lat},${
                              bookingData?.delivery?.pickupLocation?.coordinates?.[0] || bookingData?.branch?.lon
                            }`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center mt-1.5 text-base tracking-normal text-blue-400 max-md:ml-2.5 hover:underline cursor-pointer"
                          >
                            {bookingData?.delivery?.pickupLocationAddress || bookingData?.branch?.address}
                            <ArrowTopRightOnSquareIcon className="!w-[30px] h-4 text-base " />
                          </a>
                        </div>
                      </div>

                      <div className="flex gap-2">
                        <div className="mt-1.5 text-base tracking-normal text-zinc-600">DropOff Address:</div>
                        <div className="flex">
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${bookingData?.delivery?.dropOffLocation?.coordinates?.[1] || bookingData?.branch?.lat},${
                              bookingData?.delivery?.dropOffLocation?.coordinates?.[0] || bookingData?.branch?.lon
                            }`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center mt-1.5 text-base tracking-normal text-blue-400 max-md:ml-2.5 hover:underline cursor-pointer"
                          >
                            {bookingData?.delivery?.dropOffLocationAddress || bookingData?.branch?.address}
                            <ArrowTopRightOnSquareIcon className="!w-[30px] h-4 text-base" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-start w-full leading-relaxed max-md:max-w-full mt-4">
                    <div className="self-stretch text-xl font-semibold leading-snug text-black">Booking Details</div>
                    <div className="flex flex-col mt-2 w-full rounded-none ">
                      <div className="flex gap-2">
                        <div className="mt-1.5 text-base tracking-normal text-zinc-600">Booking Type: </div>
                        <div className="self-start mt-1.5 text-base tracking-normal text-zinc-600 max-md:ml-2.5">{startCase(bookingData?.delivery?.type || 'PICK UP MYSELF')}</div>
                      </div>

                      <div className="flex gap-2">
                        <div className="mt-1.5 text-base tracking-normal text-zinc-600">Payment Status: </div>
                        <div
                          className={`self-start mt-1.5 ml-4 text-sm tracking-normal px-1.5 py-0.5 rounded-md max-md:ml-2.5 ${
                            bookingData?.paymentStatus === 'paid'
                              ? 'bg-green-100 text-green-700'
                              : bookingData?.paymentStatus === 'failure'
                              ? 'bg-red-100 text-red-700'
                              : bookingData?.paymentStatus === 'unpaid'
                              ? 'bg-yellow-100 text-yellow-800'
                              : 'bg-gray-200 text-gray-700'
                          }`}
                        >
                          {toUpper(bookingData?.paymentStatus)}
                        </div>
                      </div>

                      <div className="flex gap-2">
                        <div className="mt-1.5 text-base tracking-normal text-zinc-600">Booking Status: </div>
                        <div
                          className={classNames('self-start mt-1.5 ml-4 text-sm tracking-normal px-1.5 py-0.5 rounded-md max-md:ml-2.5', {
                            'bg-gray-200 text-black': bookingData?.status === 'pending',
                            'bg-rose-400 text-white': bookingData?.status === 'cancelled' || bookingData?.status === 'rejected',
                            'bg-green-500 text-white': bookingData?.status === 'completed' || bookingData?.status === 'confirmed',
                          })}
                        >
                          {toUpper(orderStatus)}
                        </div>
                      </div>

                      <div className="flex gap-2">
                        <div className="mt-1.5 text-base tracking-normal text-zinc-600">Booking CreatedBy: </div>
                        <div
                          className={`self-start mt-1.5 ml-4 text-sm tracking-normal px-1.5 py-0.5 rounded-md max-md:ml-2.5 ${
                            toLower(bookingData?.bookingCreatedBy) === 'user'
                              ? 'bg-green-100 text-green-700'
                              : toLower(bookingData?.bookingCreatedBy) === 'office'
                              ? 'bg-yellow-100 text-yellow-800'
                              : toLower(bookingData?.bookingCreatedBy) === 'admin'
                              ? 'bg-gray-200 text-gray-700'
                              : 'bg-gray-200 text-gray-700'
                          }`}
                        >
                          {toUpper(bookingData?.bookingCreatedBy)}
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div className="mt-1.5 text-base tracking-normal text-zinc-600">Payment Method: </div>
                        <div
                          className={`self-start mt-1.5 ml-4 text-sm tracking-normal px-1.5 py-0.5 rounded-md max-md:ml-2.5 ${
                            toLower(bookingData?.paymentMethod) === 'card'
                              ? 'bg-green-100 text-green-700'
                              : toLower(bookingData?.paymentMethod) === 'cash'
                              ? 'bg-yellow-100 text-yellow-800'
                              : 'bg-gray-200 text-gray-700'
                          }`}
                        >
                          {toUpper(bookingData?.paymentMethod)}
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div className="mt-1.5 text-base tracking-normal text-zinc-600">Booking Date: </div>
                        <div className="self-start mt-1.5 text-base tracking-normal text-zinc-600 max-md:ml-2.5">
                          {bookingData?.bookingDate ? moment.parseZone(bookingData.bookingDate).format('MMMM Do YYYY, h:mm A') : ''}
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <div className="mt-1.5 text-base tracking-normal text-zinc-600">Rental Start Date: </div>
                      <div className="self-start mt-1.5 text-base tracking-normal text-zinc-600 max-md:ml-2.5">
                        {bookingData?.rentalStartDate ? moment.parseZone(bookingData.rentalStartDate).format('MMMM Do YYYY, h:mm A') : ''}
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <div className="mt-1.5 text-base tracking-normal text-zinc-600">Rental End Date: </div>
                      <div className="self-start mt-1.5 text-base tracking-normal text-zinc-600 max-md:ml-2.5">
                        {bookingData?.rentalEndDate ? moment.parseZone(bookingData.rentalEndDate).format('MMMM Do YYYY, h:mm A') : ''}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full mt-10">
                  <div className="flex flex-col items-start w-full leading-relaxed max-md:max-w-full">
                    <div className="self-stretch text-xl font-semibold mb-3 leading-snug text-black">Order Summary</div>
                    {bookingData?.orderItems?.map((item, index) => (
                      <orderItems
                        discount={item?.discount}
                        key={index}
                        title={item?.product?.name}
                        quantity={item?.quantity}
                        unitPrice={item?.product?.price}
                        totalPrice={item.priceAfterDiscount}
                      />
                    ))}

                    <div className="flex flex-wrap gap-5 border-t pt-7 justify-between mt-9 max-w-full text-base tracking-normal text-zinc-600 rounded-none w-full">
                      <div>Price without Tax</div>
                      <div className="text-right">
                        {bookingData?.priceWithoutTax !== bookingData?.rentalCarDiscountPrice ? (
                          <>
                            <span className="line-through text-gray-400">OMR {bookingData?.priceWithoutTax || 0}</span>
                            <div className="text-green-600">OMR {bookingData?.rentalCarDiscountPrice || 0}</div>
                          </>
                        ) : (
                          <span>OMR {bookingData?.priceWithoutTax || 0}</span>
                        )}
                      </div>
                    </div>

                    <div className="flex flex-wrap gap-5 justify-between max-w-full text-base tracking-normal text-zinc-600 rounded-none w-full">
                      <div>VAT</div>
                      <div className="text-right">OMR {bookingData?.vatTax || 0}</div>
                    </div>
                    <div className="flex flex-wrap gap-5 justify-between max-w-full text-base tracking-normal text-zinc-600 rounded-none w-full">
                      <div>Deposit Amount</div>
                      <div className="text-right">OMR {bookingData?.depositAmount || 0}</div>
                    </div>
                    <div className="flex flex-wrap gap-5 justify-between max-w-full text-base tracking-normal text-zinc-600 rounded-none w-full">
                      <div>Delivery Fee</div>
                      <div className="text-right">OMR {bookingData?.delivery?.deliveryCost || 0}</div>
                    </div>
                    <div className="flex flex-wrap gap-5 justify-between max-w-full text-base tracking-normal text-zinc-600 rounded-none w-full">
                      <div>Insurance Fee</div>
                      <div className="text-right">OMR {bookingData?.insuranceFee || 0}</div>
                    </div>
                    <div className="flex flex-col border-t self-stretch px-px pt-4 mt-9 w-full leading-snug max-md:max-w-full">
                      <div className="flex flex-wrap gap-5 justify-between max-w-full text-base tracking-normal text-zinc-600 rounded-none w-full">
                        <div>Total:</div>
                        <div> {bookingData?.totalPrice} OMR</div>
                      </div>
                      <div className="flex flex-wrap gap-5 justify-between max-w-full text-base tracking-normal text-zinc-600 rounded-none w-full pb-2">
                        <div>Ajarlee {bookingData?.ajarleePercentage}%:</div>
                        <div>{calculateAjarleeAmount(bookingData?.totalPrice, bookingData?.ajarleePercentage)} OMR</div>
                      </div>
                      <div className="flex flex-wrap gap-5 justify-between max-w-full text-base tracking-normal text-zinc-600 rounded-none w-full border-t pt-2 ">
                        <div>Company Amount:</div>
                        <div>{calculateCompanyAmount(bookingData?.totalPrice, bookingData?.ajarleePercentage)} OMR</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap ">
              <div className="w-full flex gap-3 flex-col">
                <div className="flex flex-col grow shrink min-w-[240px] max-md:px-5 max-md:max-w-full gap-10 items-start p-10  rounded-2xl max-md:px-5 max-md:max-w-full  w-full bg-zinc-50 shadow-[0px_0px_4px_rgba(0,0,0,0.25)]">
                  <div className="flex flex-col items-start w-full leading-relaxed max-md:max-w-full">
                    <div className="self-stretch text-xl font-semibold leading-snug text-black">Car Details</div>
                    <div className="flex flex-col mt-2 w-full rounded-none ">
                      <div className="flex justify-between flex-1">
                        <div className="mt-1.5 text-base tracking-normal text-zinc-600">Car Name: </div>
                        <div className="self-start mt-1.5 ml-4 text-base tracking-normal text-zinc-600 max-md:ml-2.5">
                          {bookingData?.rentalCar?.car?.make || ''} {bookingData?.rentalCar?.car?.model || ''} {bookingData?.rentalCar?.modelYear || ''}
                        </div>
                      </div>
                      <div className="flex justify-between flex-1">
                        <div className="mt-1.5 text-base tracking-normal text-zinc-600">Vin: </div>
                        <div className="self-start mt-1.5 ml-4 text-base tracking-normal text-zinc-600 max-md:ml-2.5">{bookingData?.rentalCar?.vin || '-'} </div>
                      </div>
                      <div className="flex justify-between flex-1">
                        <div className="mt-1.5 text-base tracking-normal text-zinc-600">Registration Number: </div>
                        <div className="self-start mt-1.5 ml-4 text-base tracking-normal text-zinc-600 max-md:ml-2.5">{bookingData?.rentalCar?.registrationNumber || '-'}</div>
                      </div>
                      <div className="flex justify-between flex-1">
                        <div className="mt-1.5 text-base tracking-normal text-zinc-600">Daily Rate: </div>
                        <div className="self-start mt-1.5 ml-4 text-base tracking-normal text-zinc-600 max-md:ml-2.5">{bookingData?.rentalCar?.dailyRate || 0} OMR</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col grow shrink min-w-[240px] max-md:px-5 max-md:max-w-full gap-10 items-start p-10  rounded-2xl max-md:px-5 max-md:max-w-full  w-full bg-zinc-50 shadow-[0px_0px_4px_rgba(0,0,0,0.25)]">
                  <div className="flex flex-col items-start w-full leading-relaxed max-md:max-w-full">
                    <div className="self-stretch text-xl font-semibold leading-snug text-black">User Details</div>
                    <div className="flex flex-col mt-2 w-full rounded-none ">
                      <div className="flex justify-between flex-1">
                        <div className="mt-1.5 text-base tracking-normal text-zinc-600">Name: </div>
                        <div className="self-start mt-1.5 ml-4 text-base tracking-normal text-zinc-600 max-md:ml-2.5">{bookingData?.user?.fullName || '-'}</div>
                      </div>
                      <div className="flex justify-between flex-1">
                        <div className="mt-1.5 text-base tracking-normal text-zinc-600">Email: </div>
                        <div className="self-start mt-1.5 ml-4 text-base tracking-normal text-zinc-600 max-md:ml-2.5">{bookingData?.user?.email || '-'}</div>
                      </div>
                      <div className="flex justify-between flex-1">
                        <div className="mt-1.5 text-base tracking-normal text-zinc-600">Phone: </div>
                        <div className="self-start mt-1.5 ml-4 text-base tracking-normal text-zinc-600 max-md:ml-2.5">{bookingData?.user?.phone || '-'}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col grow shrink min-w-[240px] max-md:px-5 max-md:max-w-full gap-10 items-start p-10  rounded-2xl max-md:px-5 max-md:max-w-full  w-full bg-zinc-50 shadow-[0px_0px_4px_rgba(0,0,0,0.25)]">
                  <div className="flex flex-col items-start w-full leading-relaxed max-md:max-w-full">
                    <div className="self-stretch text-xl font-semibold leading-snug text-black">Company Details</div>
                    <div className="flex flex-col mt-2 w-full rounded-none ">
                      <div className="flex justify-between flex-1">
                        <div className="mt-1.5 text-base tracking-normal text-zinc-600">Name: </div>
                        <div className="self-start mt-1.5 ml-4 text-base tracking-normal text-zinc-600 max-md:ml-2.5">{bookingData?.rentalCar?.company?.name || '-'}</div>
                      </div>
                      <div className="flex justify-between flex-1">
                        <div className="mt-1.5 text-base tracking-normal text-zinc-600">Email: </div>
                        <div className="self-start mt-1.5 ml-4 text-base tracking-normal text-zinc-600 max-md:ml-2.5">{bookingData?.rentalCar?.company?.email || '-'}</div>
                      </div>

                      <div>
                        <div className="flex justify-between flex-1">
                          <div className="mt-1.5 text-base tracking-normal text-zinc-600">
                            {bookingData?.rentalCar?.company?.phones?.length > 0
                              ? bookingData.rentalCar.company.phones.map((phoneData, index) => (
                                  <div key={index} className="mb-2">
                                    {phoneData.title}
                                  </div>
                                ))
                              : '-'}
                          </div>
                          <div className="self-start mt-1.5 ml-4 text-base tracking-normal text-zinc-600 max-md:ml-2.5">
                            {bookingData?.rentalCar?.company?.phones?.length > 0
                              ? bookingData.rentalCar.company.phones.map((phoneData, index) => (
                                  <div key={index} className="mb-2">
                                    {phoneData.phone}
                                  </div>
                                ))
                              : '-'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-start w-full leading-relaxed max-md:max-w-full">
                    <div className="self-stretch text-xl font-semibold leading-snug text-black">Branch Details</div>
                    <div className="flex flex-col mt-2 w-full rounded-none ">
                      <div className="flex justify-between flex-1">
                        <div className="mt-1.5 text-base tracking-normal text-zinc-600">Name: </div>
                        <div className="self-start mt-1.5 ml-4 text-base tracking-normal text-zinc-600 max-md:ml-2.5">{bookingData?.branch?.name || '-'}</div>
                      </div>
                      <div className="flex justify-between flex-1">
                        <div className="mt-1.5 text-base tracking-normal text-zinc-600">Email: </div>
                        <div className="self-start mt-1.5 ml-4 text-base tracking-normal text-zinc-600 max-md:ml-2.5">{bookingData?.branch?.email || '-'}</div>
                      </div>

                      <div>
                        <div className="flex justify-between flex-1">
                          <div className="mt-1.5 text-base tracking-normal text-zinc-600">
                            {bookingData?.branch?.phones?.length > 0
                              ? bookingData.branch.phones.map((phoneData, index) => (
                                  <div key={index} className="mb-2">
                                    {phoneData.title}
                                  </div>
                                ))
                              : '-'}
                          </div>
                          <div className="self-start mt-1.5 ml-4 text-base tracking-normal text-zinc-600 max-md:ml-2.5">
                            {bookingData?.branch?.phones?.length > 0
                              ? bookingData.branch.phones.map((phoneData, index) => (
                                  <div key={index} className="mb-2">
                                    {phoneData.phone}
                                  </div>
                                ))
                              : '-'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Booking
