import PageContent from 'components/templates/PageContent'
import { Form, Formik } from 'formik'
import logo from 'assets/images/ajarlee.png'
import Input from 'components/atoms/Input'
import apiPost from 'lib/network/apiPost'
import notify from 'components/atoms/Notify'
import ForgotPasswordSchema from 'validations/forgot.validation'
import { useHistory } from 'react-router'
import { useState } from 'react'
import * as Yup from 'yup'

const ResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Passwords must match')
    .required('Confirm password is required'),
})
const OTPVerificationSchema = Yup.object().shape({
  otp: Yup.string()
    .required('OTP is required')
    .matches(/^\d{4}$/, 'OTP must be a 4-digit number')
    .length(4, 'OTP must be exactly 4 digits'),
})

const ForgotPassword = () => {
  const history = useHistory()
  const [currentStep, setCurrentStep] = useState('email')
  const [userEmail, setUserEmail] = useState('')
  const [resetToken, setResetToken] = useState('')

  const sendPasswordForgetEmail = async data => {
    try {
      const response = await apiPost(`forget-password`, data)
      if (response?.success) {
        setUserEmail(data.email)
        setCurrentStep('otp')
        notify.success('Success', 'Password reset email has been sent successfully!')
      }
    } catch (error) {
      notify.error('Error', 'Failed to send reset email')
    }
  }

  const verifyOTP = async (values, { setSubmitting }) => {
    try {
      const response = await apiPost(`verify-otp`, {
        email: userEmail,
        otp: values.otp,
      })

      if (response?.success) {
        notify.success('Success', 'OTP verified successfully!')
        if (response.data) {
          setResetToken(response.data)
          setCurrentStep('reset')
        }
      }
    } catch (error) {
      notify.error('Error', error?.message || 'Failed to verify OTP')
    } finally {
      setSubmitting(false)
    }
  }

  const handleResetPassword = async (values, { setSubmitting }) => {
    try {
      const response = await apiPost('reset-password', {
        newPassword: values.newPassword,
        resetToken: resetToken,
      })

      if (response?.success) {
        notify.success('Success', 'Password has been reset successfully!')
        history.push('/login')
      }
    } catch (error) {
      notify.error('Error', error?.message || 'Failed to reset password')
    } finally {
      setSubmitting(false)
    }
  }

  const EmailForm = () => (
    <>
      <h2 className="mt-6 mb-6 text-center text-3xl font-extrabold text-gray-900">Forget Your Password</h2>
      <p className="mt-11 text-center text-md text-lg-600">Please enter the email address associated with your account.</p>
      <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <Formik
            validationSchema={ForgotPasswordSchema}
            enableReinitialize
            initialValues={{
              email: '',
            }}
            onSubmit={sendPasswordForgetEmail}
          >
            {({ isSubmitting }) => (
              <Form className="mb-3">
                <div className="mb-3">
                  <label htmlFor="email" className="block text-sm text-normal text-gray-700">
                    Email address
                  </label>
                  <div className="mt-1 text-gray-700">
                    <Input id="email" name="email" placeholder={'Your Email'} type="email" autoComplete="email" required className="" />
                  </div>
                </div>
                <div>
                  <button
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm text-normal text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Sending...' : 'Reset Password'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )

  const OTPForm = () => (
    <>
      <h2 className="mt-6 mb-6 text-center text-3xl font-extrabold text-gray-900">Verify OTP</h2>
      <p className="mt-11 text-center text-md text-lg-600">Please enter the OTP sent to your email address.</p>
      <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <Formik
            validationSchema={OTPVerificationSchema}
            enableReinitialize
            initialValues={{
              otp: '',
            }}
            onSubmit={verifyOTP}
          >
            {({ isSubmitting }) => (
              <Form className="mb-3">
                <div className="mb-3">
                  <label htmlFor="otp" className="block text-sm text-normal text-gray-700">
                    Enter OTP
                  </label>
                  <div className="mt-1 text-gray-700">
                    <Input id="otp" name="otp" placeholder={'Enter OTP'} type="text" className="" />
                  </div>
                </div>
                <div>
                  <button
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm text-normal text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Verifying...' : 'Verify OTP'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )

  const ResetPasswordForm = () => (
    <>
      <h2 className="mt-6 mb-6 text-center text-3xl font-extrabold text-gray-900">Reset Password</h2>
      <p className="mt-11 text-center text-md text-lg-600">Please enter your new password.</p>
      <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <Formik
            validationSchema={ResetPasswordSchema}
            initialValues={{
              newPassword: '',
              confirmPassword: '',
            }}
            onSubmit={handleResetPassword}
          >
            {({ isSubmitting }) => (
              <Form className="mb-3">
                <div className="mb-3">
                  <label htmlFor="newPassword" className="block text-sm text-normal text-gray-700">
                    New Password
                  </label>
                  <div className="mt-1">
                    <Input id="newPassword" name="newPassword" type="password" placeholder="Enter new password" />
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="confirmPassword" className="block text-sm text-normal text-gray-700">
                    Confirm Password
                  </label>
                  <div className="mt-1">
                    <Input id="confirmPassword" name="confirmPassword" type="password" placeholder="Confirm new password" />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm text-normal text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
                  >
                    {isSubmitting ? 'Resetting Password...' : 'Reset Password'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )

  const getCurrentForm = () => {
    switch (currentStep) {
      case 'email':
        return <EmailForm />
      case 'otp':
        return <OTPForm />
      case 'reset':
        return <ResetPasswordForm />
      default:
        return <EmailForm />
    }
  }

  return (
    <PageContent center>
      <div className="bg-gray-50 flex flex-col min-h-screen justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-12 w-auto" src={logo} alt="Workflow" />
          {getCurrentForm()}
          <div className="text-sm text-center mt-4">
            Go back to{' '}
            <span onClick={() => history.push('/login')} className="cursor-pointer font-medium mt-3 text-primary-600 hover:text-primary-500">
              Login
            </span>
          </div>
        </div>
      </div>
    </PageContent>
  )
}

export default ForgotPassword
