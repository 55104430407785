import { Formik, Form, Field } from 'formik'
import classNames from 'classnames'
import Input from 'components/atoms/Input'
import LoadingIcon from 'components/atoms/LoadingIcon'
import notify from 'components/atoms/Notify'
import Selection from 'components/molecules/Selection'
import { CameraIcon } from '@heroicons/react/solid'
import { useEffect, useState, useRef } from 'react'
import apiGet from 'lib/network/apiGet'
import apiPost from 'lib/network/apiPost'
import apiPut from 'lib/network/apiPut'
import apiDelete from 'lib/network/apiDelete'
import { useHistory } from 'react-router-dom'
import validations from 'validations'
import { forEach, get, keys } from 'lodash'
import TextArea from 'antd/lib/input/TextArea'
import { useSelector } from 'react-redux'
import Switch from 'components/atoms/Switch'
import ViewCompanyCar from './modals/CompanyCar'
import { Dialog } from '@headlessui/react'
import EmptyState from 'components/pageComponents/EmptyState'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const tabs = [
  { id: 1, name: 'Rental Car Detail', href: '#', current: false },
  { id: 2, name: 'Car Details', href: '#', current: false },
  { id: 3, name: 'Car Images', href: '#', current: false },
  { id: 4, name: 'Company Requested Images', href: '#', current: false },
]

const ItemTypes = {
  CAR_IMAGE: 'carImage',
}

const DraggableCarImage = ({ image, index, moveImage, deleteImage }) => {
  const ref = useRef(null)

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CAR_IMAGE,
    item: { index, id: image._id },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const [, drop] = useDrop({
    accept: ItemTypes.CAR_IMAGE,
    hover: (item, monitor) => {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      moveImage(dragIndex, hoverIndex)

      item.index = hoverIndex
    },
  })

  drag(drop(ref))

  return (
    <div ref={ref} className={`relative h-[200px] group w-[150px] border border-dashed border-gray-300 rounded-md overflow-hidden ${isDragging ? 'opacity-50' : 'opacity-100'}`}>
      <img src={image.url} alt={`Car`} className="cursor-move bg-cover h-full w-[150px] rounded" />
      <button
        onClick={() => deleteImage(image._id)}
        type="button"
        className="absolute hidden group-hover:block top-1 right-1 bg-white rounded-full p-1 shadow-md hover:bg-gray-200"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
  )
}

const CarProfile = ({ readOnly, refetch, loading, carData, carImages, isEdit, id, setCarImages }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [companyNames, setCompanyNames] = useState([])
  const [companyData, setCompanyData] = useState({})
  const [params, setParams] = useState({ t: tabs[0].id })
  const [files, setFiles] = useState({})
  const [previews, setPreviews] = useState({})
  const [profile, setProfile] = useState({})
  const [branches, setBranches] = useState([])
  const history = useHistory()
  const profileRef = useRef(null)
  const userFromStore = useSelector(state => get(state, 'user.user'))
  // eslint-disable-next-line
  const isCompanyRole = userFromStore?.roles?.some(role => role.type === 'company')
  // eslint-disable-next-line
  const hasAdminAccess = userFromStore?.roles?.some(role => role.type === 'admin')
  const isSuperCompanyUser = userFromStore?.superCompanyUser

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const [orderedImages, setOrderedImages] = useState([])

  useEffect(() => {
    if (carImages && carImages.length > 0) {
      setOrderedImages(carImages.filter(image => image.status !== 'pending' && image.status !== 'rejected'))
    }
  }, [carImages])

  useEffect(() => {
    if (isEdit && carData?.company) {
      fetchBranches(carData.company)
    }
  }, [carData])
  useEffect(() => {
    if (hasAdminAccess) {
      fetchCompanies()
    }
  }, [hasAdminAccess])

  const fetchCompanies = async () => {
    const response = await apiGet('companies')
    if (response?.data) {
      const companies = response.data.company.map(company => ({
        id: company._id,
        name: company.name,
      }))
      setCompanyNames(companies)
    } else {
      setCompanyNames([])
    }
  }
  const fetchBranches = async companyId => {
    try {
      const response = await apiGet(`company/${companyId}/branches`)
      if (response?.data) {
        const branchList = response.data.map(branch => ({
          id: branch._id,
          name: branch.name,
        }))
        setBranches(branchList)
      } else {
        setBranches([])
      }
    } catch (error) {
      notify.error('Failed to fetch branches')
      setBranches([])
    }
  }

  const setCurrentTab = currentTab => setParams({ t: parseInt(currentTab, 10) })

  const getInitialValues = () => {
    if (isEdit) {
      return carData
    }
    return {
      modelYear: '',
      insurance: '',
      fuelPolicy: '',
      description: '',
      status: '',
      color: '',
      dailyRate: '',
      yearlyRate: '',
      weeklyRate: '',
      monthlyRate: '',
      allowedKMPerDay: '',
      deliveryCost: '',
      depositAmount: '',
      company: '',
      branch: '',
      registrationNumber: '',
      vin: '',
      insuranceFee: '',
      car: {
        model: '',
        make: '',
        marketSegment: '',
        shifter: '',
        trim: '',
        numberOfDoors: '',
        numberOfSeats: '',
        engineSize: '',
        generation: '',
        series: '',
        introduced: '',
        ended: '',
        bodyType: '',
        driveWheel: '',
        fuel: '',
      },
    }
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setIsSubmitting(true)
    const filteredValues = { ...values }

    if (isEdit) {
      delete filteredValues._id
      delete filteredValues.ac
      delete filteredValues.carImages
      delete filteredValues.createdAt
      delete filteredValues.review
      delete filteredValues.statusUpdatedAt
      delete filteredValues.updatedAt
      delete filteredValues.__v
      delete filteredValues.car.createdAt
      delete filteredValues.car.updatedAt
      delete filteredValues.car.ac
      delete filteredValues.car._id
      delete filteredValues.car.__v
      delete filteredValues.index
      delete filteredValues.OfferDriver
      delete filteredValues.car.index
    }

    try {
      if (isEdit) {
        const response = await apiPut(`car/edit/${id}`, filteredValues)
        if (response.success) {
          notify.success('Car updated successfully!')
          history.push('/cars')
        }
      } else {
        const response = await apiPost('add/car', values)
        if (response.success) {
          notify.success('Car added successfully!')
          history.push('/cars')
        }
      }
    } catch (error) {
      notify.error('An error occurred')
    }
    setIsSubmitting(false)
    setSubmitting(false)
  }

  const showPreview = files => {
    const previewUrls = []
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const preview = URL.createObjectURL(file)
      previewUrls.push(preview)
    }
    setPreviews(previewUrls)
  }

  const postImagesToServer = async files => {
    const formData = new FormData()

    forEach(keys(files), function (value, key) {
      formData.append(`images`, files[key])
    })

    try {
      const response = await apiPost(`car-images/${id}`, formData)

      if (response.success) {
        setCarImages([...carImages, ...response.data])
        notify.success('Images uploaded successfully!')
      }
    } catch (error) {
      notify.error('An error occurred while uploading images')
    }
  }

  const deleteCar = () => {
    notify.deleteConfirm({
      title: 'Delete car',
      message: 'Are you sure you want to delete this car?. It will delete all the related data',
      onConfirm: async () => {
        const response = await apiDelete(`car/delete/${id}`)
        if (response?.success) {
          history.push('/cars')
          notify.success('Car deleted successfully')
        }
      },
    })
  }

  const deleteImage = imageId => {
    notify.deleteConfirm({
      title: 'Delete car',
      message: 'Are you sure you want to delete this Image? It will delete all the related data.',
      onConfirm: async () => {
        const response = await apiDelete(`car/${id}/car-image/${imageId}`)
        if (response?.success) {
          setCarImages(carImages?.filter(image => image._id !== imageId))
          notify.success('Car Images deleted successfully')
        }
      },
    })
  }

  const currentYear = new Date().getFullYear()
  const startYear = 1995

  const years = Array.from({ length: currentYear - startYear + 1 }, (v, k) => {
    const year = startYear + k
    return { id: year, name: year }
  })
  const handleImageClick = (imageUrl, imageId) => {
    setSelectedImage({ url: imageUrl, id: imageId })
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setSelectedImage(null)
  }

  const moveImage = (dragIndex, hoverIndex) => {
    const draggedImage = orderedImages[dragIndex]
    const newOrderedImages = [...orderedImages]
    newOrderedImages.splice(dragIndex, 1)
    newOrderedImages.splice(hoverIndex, 0, draggedImage)
    setOrderedImages(newOrderedImages)
  }

  const saveImageOrder = async () => {
    try {
      const imageIds = orderedImages.map(image => image._id)

      const formData = new FormData()
      formData.append('imageIds', JSON.stringify(imageIds))

      const response = await apiPost(`car-images/${id}`, formData)

      if (response.success) {
        notify.success('Image order updated successfully!')
        setCarImages([...orderedImages, ...carImages.filter(img => img.status === 'pending' || img.status === 'rejected')])
      }
    } catch (error) {
      notify.error('Failed to update image order')
    }
  }

  const visibleTabs = isCompanyRole && !hasAdminAccess ? tabs.filter(tab => tab.id !== 4) : tabs

  return (
    <>
      <Formik initialValues={getInitialValues()} validationSchema={isEdit ? null : validations.AddUserSchema} onSubmit={handleSubmit} enableReinitialize={false}>
        {({ isValid, setFieldValue, values }) =>
          !loading ? (
            <div className="w-full mb-[60px] flex flex-col">
              <div className="bg-white flex w-full">
                <Form className="space-y-8 py-8 w-full mx-auto px-8">
                  <div>
                    <div className="border-b border-gray-200 pb-5 sm:pb-0">
                      <h3 className="text-xl font-medium leading-6 text-gray-900">Car Overview {readOnly ? '(Read-only)' : ''}</h3>
                      <div className="mt-3 sm:mt-4">
                        <div className="hidden sm:block">
                          <nav className="-mb-px flex space-x-8">
                            {visibleTabs.map(tab => (
                              <span
                                key={tab.name}
                                onClick={() => setCurrentTab(tab.id)}
                                className={classNames(
                                  tab.id === params?.t ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                  'whitespace-nowrap pb-4 border-b-2 font-medium text-sm cursor-pointer'
                                )}
                                aria-current={params?.t === tab.id ? 'page' : undefined}
                              >
                                {tab.name}
                              </span>
                            ))}
                          </nav>
                        </div>
                      </div>
                    </div>

                    {params?.t === 1 && (
                      <div className="space-y-6 sm:space-y-5 pt-8">
                        <div className="grid grid-cols-2 gap-6">
                          <div>
                            <label htmlFor="modelYear" className="block text-sm font-normal text-gray-700 mb-1">
                              Model Year
                            </label>
                            <Selection
                              onChange={value => {
                                if (value && value.id) {
                                  setFieldValue('modelYear', value.id)
                                }
                              }}
                              title="Select Model Year"
                              list={years}
                              value={values?.modelYear || ''}
                              className="w-full"
                            />
                          </div>
                          <div>
                            <label htmlFor="car.model" className="block text-sm font-normal text-gray-700 mb-1">
                              Model
                            </label>
                            <Field name="car.model" as={Input} type="text" className="w-full" />
                          </div>
                          <div>
                            <label htmlFor="email" className="block text-sm font-normal text-gray-700 mb-1">
                              Make
                            </label>
                            <Field type="text" name="car.make" as={Input} id="insurance" className="w-full" />
                          </div>
                          <div>
                            <label htmlFor="color" className="block text-sm font-normal text-gray-700 mb-1">
                              Color
                            </label>
                            <Field type="text" name="color" as={Input} id="color" className="w-full" />
                          </div>
                          <div>
                            <label htmlFor="insurance" className="block text-sm font-normal text-gray-700 mb-1">
                              Insurance
                            </label>
                            <Selection
                              onChange={value => {
                                if (value && value.id && values.insurance === value.id) {
                                  setFieldValue('insurance', '')
                                } else if (value && value.id) {
                                  setFieldValue('insurance', value.id)
                                }
                              }}
                              title="Select Insurance"
                              list={[
                                { id: '3rd Party Insurance', name: '3rd Party Insurance' },
                                { id: 'Full Insurance', name: 'Full Insurance' },
                              ]}
                              value={values?.insurance || ''}
                              className="w-full"
                            />
                          </div>
                          <div>
                            <label htmlFor="insuranceFee" className="block text-sm font-normal text-gray-700 mb-1">
                              Insurance Fee
                            </label>
                            <Field type="number" name="insuranceFee" as={Input} id="insuranceFee" className="w-full" />
                          </div>
                          <div>
                            <label htmlFor="fuelPolicy" className="block text-sm font-normal text-gray-700 mb-1">
                              Fuel Policy
                            </label>
                            <Selection
                              onChange={value => {
                                if (value && value.id && values.fuelPolicy === value.id) {
                                  setFieldValue('fuelPolicy', '')
                                } else if (value && value.id) {
                                  setFieldValue('fuelPolicy', value.id)
                                }
                              }}
                              title="Select Fuel Policy"
                              list={[
                                { id: 'Free Tank', name: 'Free Tank' },
                                { id: 'Like For Like', name: 'Like For Like' },
                              ]}
                              value={values?.fuelPolicy || ''}
                              className="w-full"
                            />
                          </div>
                          <div>
                            <label htmlFor="allowedKMPerDay" className="block text-sm font-normal text-gray-700 mb-1">
                              Allowed KM Per Day
                            </label>
                            <Field type="number" name="allowedKMPerDay" as={Input} id="allowedKMPerDay" className="w-full" />
                          </div>
                          <div>
                            <label htmlFor="nationality" className="block text-sm font-normal text-gray-700 mb-1">
                              Daily Rate
                            </label>
                            <Field type="number" name="dailyRate" as={Input} id="nationality" className="w-full" />
                          </div>
                          <div>
                            <label htmlFor="status" className="block text-sm font-normal text-gray-700 mb-1">
                              Status
                            </label>
                            <Selection
                              onChange={value => {
                                if (value && value.id && values.status === value.id) {
                                  setFieldValue('status', '')
                                  if (value.id === 'active' || value.id === 'booked' || value.id === 'inProgress') {
                                    setFieldValue('outOfServiceDays', null)
                                  }
                                } else if (value && value.id) {
                                  setFieldValue('status', value.id)
                                  if (value.id === 'active' || value.id === 'booked' || value.id === 'inProgress') {
                                    setFieldValue('outOfServiceDays', null)
                                  }
                                }
                              }}
                              title="Select Status"
                              list={[
                                { id: 'active', name: 'active' },
                                { id: 'booked', name: 'booked' },
                                { id: 'out of service', name: 'out of service' },
                                { id: 'inProgress', name: 'inProgress' },
                              ]}
                              value={values?.status || ''}
                              className="w-full"
                            />
                          </div>
                          <div>
                            <label htmlFor="phone" className="block text-sm font-normal text-gray-700 mb-1">
                              Market Segment
                            </label>
                            <Selection
                              onChange={value => {
                                if (value && value.id && values.car.marketSegment === value.id) {
                                  setFieldValue('car.marketSegment', '')
                                } else if (value && value.id) {
                                  setFieldValue('car.marketSegment', value.id)
                                }
                              }}
                              title="Select Market Segment"
                              list={[
                                { id: 'Economical', name: 'Economical' },
                                { id: '4x4', name: '4x4' },
                                { id: 'SUV', name: 'SUV' },
                                { id: 'Sport', name: 'Sport' },
                                { id: 'Sedan', name: 'Sedan' },
                                { id: 'Luxury', name: 'Luxury' },
                                { id: 'Van', name: 'Van' },
                              ]}
                              value={values.car?.marketSegment || ''}
                              className="w-full"
                            />
                          </div>
                          <div>
                            <label htmlFor="numberOfDoors" className="block text-sm font-normal text-gray-700 mb-1">
                              Number of Doors
                            </label>
                            <Field type="number" name="car.numberOfDoors" as={Input} id="numberOfDoors" className="w-full" />
                          </div>
                          <div>
                            <label htmlFor="numberOfSeats" className="block text-sm font-normal text-gray-700 mb-1">
                              Number of Seats
                            </label>
                            <Field type="number" name="car.numberOfSeats" as={Input} id="numberOfSeats" className="w-full" />
                          </div>
                          <div>
                            <label htmlFor="driveWheel" className="block text-sm font-normal text-gray-700 mb-1">
                              Drive Wheel
                            </label>
                            <Field type="text" name="car.driveWheel" as={Input} id="driveWheel" className="w-full" />
                          </div>

                          <div>
                            <label htmlFor="phone" className="block text-sm font-normal text-gray-700 mb-1">
                              Shifter
                            </label>
                            <Selection
                              onChange={value => {
                                if (value && value.id && values.car?.shifter === value.id) {
                                  setFieldValue('car.shifter', '')
                                } else if (value && value.id) {
                                  setFieldValue('car.shifter', value.id)
                                }
                              }}
                              title="Select Shifter"
                              list={[
                                { id: 'Manual', name: 'Manual' },
                                { id: 'Automatic', name: 'Automatic' },
                              ]}
                              value={values.car?.shifter || ''}
                              className="w-full"
                            />
                          </div>
                          {values?.status === 'out of service' && (
                            <div>
                              <label htmlFor="outOfServiceDays" className="block text-sm font-normal text-gray-700 mb-1">
                                Out Of Service Days
                              </label>
                              <Field type="text" name="outOfServiceDays" as={Input} id="outOfServiceDays" className="w-full" />
                            </div>
                          )}
                          {values?.status === 'booked' && (
                            <>
                              <div>
                                <label htmlFor="rentalStartDate" className="block text-sm font-normal text-gray-700 mb-1">
                                  Booking From
                                </label>
                                <Field type="date" name="rentalStartDate" as={Input} id="rentalStartDate" className="w-full" />
                              </div>
                              <div>
                                <label htmlFor="rentalEndDate" className="block text-sm font-normal text-gray-700 mb-1">
                                  Booking To
                                </label>
                                <Field type="date" name="rentalEndDate" as={Input} id="rentalEndDate" className="w-full" />
                              </div>
                            </>
                          )}

                          {(!isCompanyRole || hasAdminAccess) && (
                            <div>
                              <label htmlFor="company" className="block text-sm font-normal text-gray-700 mb-1">
                                Company
                              </label>
                              <Selection
                                onChange={value => {
                                  if (value && value.id && values.company === value.id) {
                                    setFieldValue('company', '')
                                  } else if (value && value.id) {
                                    setFieldValue('company', value.id)
                                    fetchBranches(value.id)
                                  }
                                }}
                                title="Select Companies"
                                list={companyNames}
                                value={values?.company || ''}
                                className="w-full"
                              />
                            </div>
                          )}
                          {(isSuperCompanyUser || !isCompanyRole || hasAdminAccess) && (
                            <div>
                              <label htmlFor="branch" className="block text-sm font-normal text-gray-700 mb-1">
                                Branch
                              </label>
                              <Selection
                                onChange={value => {
                                  if (value?.id === values?.branch) {
                                    setFieldValue('branch', '')
                                  } else if (value?.id) {
                                    setFieldValue('branch', value.id)
                                  }
                                }}
                                title="Select Branch"
                                list={branches}
                                value={values?.branch || ''}
                                className="w-full"
                              />
                            </div>
                          )}

                          {(!isCompanyRole || hasAdminAccess) && (
                            <div className="grid grid-cols-4 ">
                              <p className="whitespace-nowrap">Real Images</p>{' '}
                              <Switch
                                id="isRealImages"
                                value={values.isRealImages}
                                disabled={true}
                                onChange={checked => {
                                  setFieldValue('isRealImages', checked)
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {params.t === 2 && (
                      <div className="space-y-6 sm:space-y-5 pt-8">
                        <div className="grid grid-cols-2 gap-6">
                          <div>
                            <label htmlFor="vin" className="block text-sm font-normal text-gray-700 mb-1">
                              Vin
                            </label>
                            <Field type="text" name="vin" as={Input} id="color" className="w-full" />
                          </div>
                          <div>
                            <label htmlFor="registrationNumber" className="block text-sm font-normal text-gray-700 mb-1">
                              Registration Number
                            </label>
                            <Field type="text" name="registrationNumber" as={Input} id="registrationNumber" className="w-full" />
                          </div>
                          <div>
                            <label htmlFor="nationality" className="block text-sm font-normal text-gray-700 mb-1">
                              Weekly Rate
                            </label>
                            <Field type="number" name="weeklyRate" as={Input} id="nationality" className="w-full" />
                          </div>
                          <div>
                            <label htmlFor="nationality" className="block text-sm font-normal text-gray-700 mb-1">
                              Yearly Rate
                            </label>
                            <Field type="number" name="yearlyRate" as={Input} id="yearlyRate" className="w-full" />
                          </div>
                          <div>
                            <label htmlFor="nationality" className="block text-sm font-normal text-gray-700 mb-1">
                              Monthly Rate
                            </label>
                            <Field type="number" name="monthlyRate" as={Input} id="nationality" className="w-full" />
                          </div>
                          <div>
                            <label htmlFor="color" className="block text-sm font-normal text-gray-700 mb-1">
                              Delivery Cost
                            </label>
                            <Field type="number" name="deliveryCost" as={Input} id="color" className="w-full" />
                          </div>
                          <div>
                            <label htmlFor="color" className="block text-sm font-normal text-gray-700 mb-1">
                              Deposit Amount
                            </label>
                            <Field type="number" name="depositAmount" as={Input} id="color" className="w-full" />
                          </div>
                          <div>
                            <label htmlFor="generation" className="block text-sm font-normal text-gray-700 mb-1">
                              Generation
                            </label>
                            <Field type="text" name="car.generation" as={Input} id="generation" className="w-full" />
                          </div>
                          <div>
                            <label htmlFor="series" className="block text-sm font-normal text-gray-700 mb-1">
                              Series
                            </label>
                            <Field type="text" name="car.series" as={Input} id="series" className="w-full" />
                          </div>

                          <div>
                            <label htmlFor="introduced" className="block text-sm font-normal text-gray-700 mb-1">
                              Introduced
                            </label>
                            <Field type="number" name="car.introduced" as={Input} id="introduced" className="w-full" />
                          </div>
                          <div>
                            <label htmlFor="ended" className="block text-sm font-normal text-gray-700 mb-1">
                              Ended
                            </label>
                            <Field type="number" name="car.ended" as={Input} id="ended" className="w-full" />
                          </div>
                          <div>
                            <label htmlFor="bodyType" className="block text-sm font-normal text-gray-700 mb-1">
                              Body Type
                            </label>
                            <Field type="text" name="car.bodyType" as={Input} id="bodyType" className="w-full" />
                          </div>
                          <div>
                            <label htmlFor="fuel" className="block text-sm font-normal text-gray-700 mb-1">
                              fuel
                            </label>
                            <Field type="text" name="car.fuel" as={Input} id="fuel" className="w-full" />
                          </div>

                          <div>
                            <label htmlFor="trim" className="block text-sm font-normal text-gray-700 mb-1">
                              Trim
                            </label>
                            <Field type="text" name="car.trim" as={Input} id="trim" className="w-full" />
                          </div>
                          <div>
                            <label htmlFor="engineSize" className="block text-sm font-normal text-gray-700 mb-1">
                              Engine Size
                            </label>

                            <Selection
                              onChange={value => {
                                if (value && value.id && values.car?.engineSize === value.id) {
                                  setFieldValue('car.engineSize', '')
                                } else if (value && value.id) {
                                  setFieldValue('car.engineSize', value.id)
                                }
                              }}
                              title="Select Engine Size"
                              list={[
                                { id: 3, name: '3' },
                                { id: 4, name: '4' },
                                { id: 5, name: '5' },
                                { id: 6, name: '6' },
                                { id: 8, name: '8' },
                              ]}
                              value={values.car?.engineSize || ''}
                              className="w-full"
                            />
                          </div>
                          <div>
                            <label htmlFor="description" className="block text-sm font-normal text-gray-700 mb-1">
                              Description
                            </label>
                            <Field name="description" as={TextArea} type="text" rows="5" className="w-full" />
                          </div>
                        </div>
                      </div>
                    )}
                    {params.t === 3 && (
                      <DndProvider backend={HTML5Backend}>
                        <div className="mt-1 sm:mt-0 sm:col-span-2 pt-8">
                          {/* {orderedImages.length > 0 && (
                            <div className="flex justify-end items-center mb-4">
                              <button
                                onClick={saveImageOrder}
                                type="button"
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              >
                                Save Order
                              </button>
                            </div>
                          )} */}
                          <div className="flex flex-wrap gap-4 pb-4 items-center">
                            <input
                              id="url"
                              ref={profileRef}
                              name="url"
                              className="hidden"
                              accept=".jpg,.jpeg,.png"
                              type="file"
                              multiple
                              onChange={event => {
                                const files = event.target.files
                                if (files && files.length > 0) {
                                  showPreview(Array.from(files))
                                  postImagesToServer(files)
                                }
                              }}
                            />

                            {orderedImages.length > 0 &&
                              orderedImages.map((image, index) => (
                                <DraggableCarImage key={image._id} image={image} index={index} moveImage={moveImage} deleteImage={deleteImage} />
                              ))}

                            {previews.length > 0 &&
                              previews.map((previewUrl, index) => (
                                <div key={index} className="relative h-[200px] w-[150px] border border-dashed border-gray-300 rounded-md overflow-hidden">
                                  <div className="absolute inset-0 bg-gray-700 opacity-70" />
                                  <img src={previewUrl} alt={`Preview ${index}`} className="cursor-pointer h-full bg-cover w-[150px] rounded" />
                                  <div className="loader">
                                    <svg className="circular">
                                      <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="5" strokeMiterlimit="10"></circle>
                                    </svg>
                                  </div>
                                </div>
                              ))}
                            {(!isCompanyRole || hasAdminAccess) && (
                              <div
                                onClick={() => profileRef.current.click()}
                                className="relative h-[200px] w-[150px] border border-dashed border-gray-300 rounded-md overflow-hidden cursor-pointer flex items-center justify-center"
                              >
                                <CameraIcon className="text-gray-400 h-8 w-8" />
                              </div>
                            )}
                          </div>
                          {orderedImages.length > 0 && (
                            <div className="flex justify-end items-center mb-4">
                              <button
                                onClick={saveImageOrder}
                                type="button"
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              >
                                Save Order
                              </button>
                            </div>
                          )}
                        </div>
                      </DndProvider>
                    )}

                    {params.t === 4 && (!isCompanyRole || hasAdminAccess) && (
                      <div className="mt-1 sm:mt-0 sm:col-span-2 pt-8">
                        <div className="flex flex-wrap gap-4 pb-4 items-center">
                          <input
                            id="url"
                            ref={profileRef}
                            name="url"
                            className="hidden"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            multiple
                            onChange={event => {
                              const files = event.target.files
                              if (files && files.length > 0) {
                                showPreview(Array.from(files))
                                postImagesToServer(files)
                              }
                            }}
                          />

                          {carImages.filter(image => image.status === 'pending' || image.status === 'rejected').length > 0 ? (
                            carImages
                              .filter(image => image.status === 'pending' || image.status === 'rejected')
                              .map((image, index) => (
                                <div
                                  key={index}
                                  className="relative h-[200px] group w-[150px] border border-dashed border-gray-300 rounded-md overflow-hidden"
                                  onClick={() => handleImageClick(image.url, image._id)}
                                >
                                  <img src={image.url} alt={`Car`} className="cursor-pointer bg-cover h-full w-[150px] rounded" />
                                </div>
                              ))
                          ) : (
                            <EmptyState v2 description="No Company Requested Car Images  found" />
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  {params.t !== 3 && params.t !== 4 && !readOnly && (
                    <div className="pt-2">
                      <div className="flex justify-between">
                        <button
                          type="submit"
                          disabled={!isValid || isSubmitting}
                          className={classNames(
                            (!isValid || isSubmitting) && 'cursor-not-allowed opacity-50',
                            'mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500'
                          )}
                        >
                          {isSubmitting ? (
                            <span className="flex items-start">
                              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                              <span>Please wait...</span>
                            </span>
                          ) : (
                            <span>{!isEdit ? 'Add' : 'Update'}</span>
                          )}
                        </button>
                        {(!isCompanyRole || hasAdminAccess) && (
                          <div>
                            <button
                              type="button"
                              onClick={deleteCar}
                              className="mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
                            >
                              Delete Car
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            </div>
          ) : (
            <div className="w-full h-[500px] flex items-center justify-center">
              <LoadingIcon />
            </div>
          )
        }
      </Formik>
      <Dialog open={isModalOpen} onClose={closeModal} className="relative z-50">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-4xl rounded bg-white p-6">
            <Dialog.Title className="text-lg font-medium mb-4">Requested Company Car</Dialog.Title>

            <ViewCompanyCar imageUrl={selectedImage?.url} imageId={selectedImage?.id} refetch={refetch} onClose={closeModal} carImages={carImages} />
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  )
}

export default CarProfile
